import { useContext } from 'react';

import { DrawerContext } from './index';

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useDrawerContext must be used within its Provider');
  }

  return context;
};
