import { makeStyles } from '@material-ui/core/styles';
import { distances } from '@setvi/shared/styles';

export const useSelectedStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700
  },
  gray: {
    color: '#717480'
  },
  title: {
    fontWeight: 500
  },
  grid: {
    margin: `${0} ${distances.px.medium}`,
    boxShadow: 'inset 0px -0.5px 1px #D5DDEA'
  },
  gridOrange: {
    marginLeft: distances.px.xsmall,
    borderLeft: '4px solid #FF974A',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  resourcesList: {
    paddingLeft: distances.px.xsmall,
    maxHeight: 400,
    overflow: 'scroll'
  },
  gridBox: {
    margin: `${0} ${0} ${0} ${distances.px.medium}`
  },
  gridNoPadding: {
    width: '100%',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    '&:last-child > div': {
      boxShadow: 'none'
    },
    '& > div': {
      boxShadow: 'inset 0px -0.5px 1px #D5DDEA'
    }
  },
  bottomContainer: {
    padding: distances.px.medium
  },
  add: {
    borderRadius: '12px',
    width: '38px',
    height: '25px'
  }
}));
