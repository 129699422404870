import { FC } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

interface HeaderProps {
  setIsExpanded: (value: boolean) => void;
  length: number;
  isExpanded?: boolean;
}

const SelectedActionText = withStyles({
  root: {
    fontWeight: 600
  }
})(Typography);

const ExpandIconButton = withStyles(({ spacing, palette }) => ({
  root: {
    padding: 0,
    marginLeft: spacing(1),
    color: palette.primary.main
  }
}))(IconButton);

export const TitleContainer = withStyles(({ spacing, palette }) => ({
  root: {
    backgroundColor: '#FAFAFB',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${palette.divider}`,
    borderTop: `1px solid ${palette.primary.main}`,
    padding: `${spacing(2)}px ${spacing(3)}px`
  }
}))(Box);

export const Header: FC<HeaderProps> = ({
  setIsExpanded,
  length,
  isExpanded = false
}) => (
  <TitleContainer>
    <SelectedActionText variant="subtitle2" color="primary">
      {length} Selected
    </SelectedActionText>
    <ExpandIconButton onClick={() => setIsExpanded(!isExpanded)}>
      {isExpanded ? <ArrowDropDown /> : <ArrowDropUp />}
    </ExpandIconButton>
  </TitleContainer>
);
