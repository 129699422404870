import { useMemo } from 'react';
import { Box, IconButton } from '@material-ui/core';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { SText } from '@setvi/shared/components';

import { HeaderContainer, useHeaderStyles } from './Style';

interface HeaderProps {
  setIsMinimized: (isMinimized: boolean) => void;
  closeComposeEmail: () => void;
  isMinimized: boolean;
  subject?: string;
}

const Header = ({
  subject,
  isMinimized,
  setIsMinimized,
  closeComposeEmail
}: HeaderProps) => {
  const classes = useHeaderStyles();

  const generateSubject = useMemo(() => {
    if (!subject.trim()) {
      if (isMinimized) return 'No Subject';
      return 'Write New Email';
    }
    return subject;
  }, [subject, isMinimized]);

  return (
    <Box
      className={isMinimized ? classes.container : classes.maximizedContainer}>
      <HeaderContainer>
        <SText
          variant="body1"
          weight="semibold"
          title={generateSubject}
          fontColor={isMinimized ? '#fff' : '#000'}>
          {generateSubject}
        </SText>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <IconButton
              size="small"
              onClick={() => setIsMinimized(!isMinimized)}>
              {isMinimized ? (
                <ExpandLessIcon htmlColor="#fff" />
              ) : (
                <RemoveIcon />
              )}
            </IconButton>
          </Box>
          <Box>
            <IconButton size="small" onClick={closeComposeEmail}>
              <CloseIcon htmlColor={isMinimized ? '#fff' : 'unset'} />
            </IconButton>
          </Box>
        </Box>
      </HeaderContainer>
    </Box>
  );
};

export default Header;
