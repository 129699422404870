import { AxiosMethods, EmailCampaignsApi } from '@setvi/shared/enums';
import { queryVariablesType } from '@setvi/shared/interfaces';
import { axiosHelper } from '@setvi/shared/services';
import { UseQueryOptions } from '@tanstack/react-query';
import { CampaignStatus } from 'Enums/CampaignStatus';
import { EmailCampaignRecipients } from './Types';

export enum CampaignsQueryKey {
  RECIPIENTS = 'recipients',
  STATUS = 'status',
  CAMPAIGN = 'campaign',
  CAMPAIGNS = 'campaigns',
  ANALYTICS = 'analytics',
  CAMPAIGN_LINKS = 'campaign_links',
  CAMPAIGN_RECIPIENT_VIEW = 'campaign_recipient_view',
  CAMPAIGN_RECIPIENT_VIEWS = 'campaign_recipient_views',
  CAMPAIGN_RECIPIENT_TIMELINE = 'campaign_recipient_timeline',
  CAMPAIGN_RECIPIENT_MAIL = 'campaign_recipient_mail',
  CAMPAIGN_RECIPIENT_REPLY = 'campaign_recipient_reply',
  CAMPAIGN_RECIPIENT_MAILS = 'campaign_recipient_mails',
  CAMPAIGN_RECIPIENT_DETAILS = 'campaign_recipient_details'
}

const dynamicId = ':campaignId';
const recipientId = ':recipientId';

interface EmailCampaignRequest extends queryVariablesType {
  statuses: CampaignStatus[];
}

interface EmailCampaignDetails extends queryVariablesType {
  campaignId: number;
  filterByStatus?: number[];
}

export const campaignQuery = (Id: string | number) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: EmailCampaignsApi.Campaign.replace(dynamicId, Id.toString()),
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  select: (res: any) => ({
    ...res?.Data,
    Links: res?.Data.Links.map((link: any) => ({
      ...link,
      Placeholder: link.Placeholder || link.PlaceHolder,
      PlaceHolder: link.PlaceHolder || link.Placeholder
    }))
  }),
  refetchOnWindowFocus: false
});

export const campaignsQuery = (input: EmailCampaignRequest) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGNS, input],
  queryFn: ({ queryKey }: any) => {
    const { page, size, search, statuses, ...rest }: EmailCampaignRequest =
      queryKey?.[1];
    const params = {
      pageNumber: page,
      pageSize: size,
      searchString: search,
      statuses: statuses?.join(','),
      ...rest
    };
    return axiosHelper({
      endpoint: EmailCampaignsApi.Campaigns,
      method: AxiosMethods.GET,
      params
    });
  },
  select: (res: any) => res?.Data
});

export const campaignStatusesQuery = () => ({
  queryKey: [CampaignsQueryKey.STATUS],
  queryFn: () =>
    axiosHelper({
      endpoint: `${EmailCampaignsApi.CampaignStatuses}`,
      method: AxiosMethods.GET
    }),
  select: (res: any) => res?.Data?.CampaignsStatuses
});

export const campaignAnalyticsQuery = (Id: number) => ({
  queryKey: [CampaignsQueryKey.ANALYTICS],
  queryFn: () =>
    axiosHelper({
      endpoint: `${EmailCampaignsApi.CampaignAnalytics.replace(
        dynamicId,
        Id.toString()
      )}`,
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  select: (res: any) => res?.Data
});

export const campaignLinksQuery = (Id: string) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN_LINKS, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: `${EmailCampaignsApi.CampaignLinkData.replace(dynamicId, Id)}`,
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  select: (res: any) =>
    res?.Data?.Links.map((link: any) => ({
      ...link,
      Placeholder: link.Placeholder || link.PlaceHolder,
      PlaceHolder: link.PlaceHolder || link.Placeholder
    })) || []
});

export const getCampaignRecipientViewsQuery = (
  CampaignId: number | string,
  RecipientId: number | string
) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN_RECIPIENT_VIEWS, CampaignId],
  queryFn: () =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignDetailsRecipientViews.replace(
        dynamicId,
        CampaignId.toString()
      ).replace(recipientId, RecipientId.toString()),
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  select: (res: any) => res?.Data
});

export const getCampaignRecipientViewQuery = (
  CampaignId: number | string,
  RecipientId: number | string,
  params?: any
) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN_RECIPIENT_VIEW, CampaignId],
  queryFn: () =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignDetailsRecipientView.replace(
        dynamicId,
        CampaignId.toString()
      ).replace(recipientId, RecipientId.toString()),
      method: AxiosMethods.GET,
      params
    }),
  select: (res: any) => res?.Data
});

export const getCampaignRecipientTimelineQuery = (
  CampaignId: number | string,
  RecipientId: number | string
) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN_RECIPIENT_TIMELINE, CampaignId],
  queryFn: () =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignDetailsRecipientTimeline.replace(
        dynamicId,
        CampaignId.toString()
      ).replace(recipientId, RecipientId.toString()),
      method: AxiosMethods.GET
    }),
  select: (res: any) => {
    const data = res?.Data;

    const response: any = [];
    Object.keys(data.Lines).forEach(key => {
      const items = data.Lines[key]?.map((item: any) => ({
        ...item,
        Key: key
      }));
      response.push(...items);
    });

    return response;
  }
});

export const getCampaignRecipientReplyQuery = (
  CampaignId: number | string,
  RecipientId: number | string,
  params?: any
) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN_RECIPIENT_REPLY, CampaignId],
  queryFn: () =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignDetailsRecipientReply.replace(
        dynamicId,
        CampaignId.toString()
      ).replace(recipientId, RecipientId.toString()),
      method: AxiosMethods.GET,
      params
    }),
  select: (res: any) => res?.Data
});

export const getCampaignRecipientMailQuery = (
  CampaignId: number | string,
  RecipientId: number | string,
  params?: any
) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN_RECIPIENT_MAIL, CampaignId],
  queryFn: () =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignDetailsRecipientMail.replace(
        dynamicId,
        CampaignId.toString()
      ).replace(recipientId, RecipientId.toString()),
      method: AxiosMethods.GET,
      params
    }),
  select: (res: any) => res?.Data
});

export const getCampaignRecipientMailsQuery = (
  CampaignId: number | string,
  RecipientId: number | string
) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN_RECIPIENT_MAILS, CampaignId],
  queryFn: () =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignDetailsRecipientMails.replace(
        dynamicId,
        CampaignId.toString()
      ).replace(recipientId, RecipientId.toString()),
      method: AxiosMethods.GET
    }),
  select: (res: any) => res?.Data?.Items
});

export const getCampaignRecipientDetailsQuery = (
  CampaignId: number | string,
  RecipientId: number | string
) => ({
  queryKey: [CampaignsQueryKey.CAMPAIGN_RECIPIENT_DETAILS, CampaignId],
  queryFn: () =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignDetailsRecipientDetails.replace(
        dynamicId,
        CampaignId.toString()
      ).replace(recipientId, RecipientId.toString()),
      method: AxiosMethods.GET
    }),
  select: (res: any) => res?.Data
});

export const campaignRecipientsQuery = (
  input: EmailCampaignDetails
): UseQueryOptions<EmailCampaignRecipients> => ({
  queryKey: [CampaignsQueryKey.RECIPIENTS, input],
  queryFn: (data: any) => {
    const { page, size, ...rest }: EmailCampaignDetails = data.queryKey[1];
    const params = {
      pageNumber: page,
      pageSize: size,
      ...rest
    };
    return axiosHelper({
      endpoint: `${EmailCampaignsApi.CampaignRecipients.replace(
        dynamicId,
        input.campaignId?.toString()
      )}`,
      method: AxiosMethods.GET,
      params
    });
  },
  select: (res: any) => res?.Data
});
