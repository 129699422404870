import {
  dateValidation,
  nameValidation,
  bodyValidation,
  switchValidation,
  subjectValidation,
  descriptionValidation,
  checkboxValidation,
  requiredReadingValidation
} from '../validators';

export const generalSchema = [
  {
    name: 'Name',
    schema: nameValidation
  },
  {
    name: 'Description',
    schema: descriptionValidation
  },
  {
    name: 'Required',
    schema: switchValidation
  },
  {
    name: 'Date',
    schema: dateValidation
  },
  {
    name: 'Checkbox',
    schema: checkboxValidation
  }
];

export const contentSchema = [
  {
    name: 'Subject',
    schema: subjectValidation
  },
  {
    name: 'HtmlBody',
    schema: bodyValidation
  }
];

export const requiredReadingSchema = [
  {
    name: 'Description',
    schema: requiredReadingValidation
  },
  {
    name: 'DocumentName',
    schema: requiredReadingValidation
  }
];
