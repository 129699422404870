import { Content as ContentContainer } from '@setvi/shared/components/sdrawer/base';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { Link } from '@setvi/shared/interfaces';

import { List } from '../list';
import { EmptyStateTemplate } from '../empty-states';
import { useContent } from './useContent';

interface ContentProps {
  handleBack(): void;
  handleInsert(link: Link): void;
  handleClose(): void;
}

export const Content = ({
  handleBack,
  handleInsert,
  handleClose
}: ContentProps) => {
  const { data, loading } = useContent();

  return (
    <ContentContainer
      header={
        <Header title="Add from SETVI" hasClose handleClose={handleClose} />
      }
      backContent={<BaseSubheader handleBack={handleBack} text="Templates" />}>
      <>
        {data?.length > 0 ? (
          <List
            templates={data}
            isLoading={loading}
            handleInsert={handleInsert}
          />
        ) : (
          <EmptyStateTemplate />
        )}
      </>
    </ContentContainer>
  );
};
