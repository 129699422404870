import { Box, Divider } from '@material-ui/core';
import { Container, Title } from './style';
import SNavigateButton from '../../../snavigate-button';

interface BackProps {
  handleBack?(): void;
  text?: string;
  customTitle?: JSX.Element;
  customBackButton?: JSX.Element;
  bottomDivider?: boolean;
  disabled?: boolean;
}

export const BaseSubheader = ({
  handleBack,
  text,
  customTitle,
  bottomDivider = true,
  disabled = false,
  customBackButton
}: BackProps) => (
  <>
    <Container>
      {!!handleBack &&
        (customBackButton || (
          <SNavigateButton size="sm" onClick={handleBack} disabled={disabled} />
        ))}

      <Box width="100%">{customTitle || <Title title={text} />}</Box>
    </Container>
    {bottomDivider && <Divider />}
  </>
);
