import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const ProgressContainer = withStyles({
  root: {
    width: '100%',
    height: 12
  }
})(Box);

export const ItemContainer = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: spacing(0, 2)
  }
}))(Box);

export const useStyles = makeStyles({
  uploadFileIcon: {
    width: 20
  },
  listItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 50,
    padding: 0
  }
});
