import { useState, useEffect, useMemo, useCallback, Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import { client } from 'filestack-react';
import { useMutation } from '@tanstack/react-query';

import { createResourceMutation } from '@setvi/shared/services';

import { UploadError } from './index';
import {
  UploadCounterActionTypes,
  UploadCounterAction,
  UploadStatus
} from '../usePanel';

interface UseItemProps {
  file: File;
  dispatch: Dispatch<UploadCounterAction>;
  setUploading?: (value: boolean) => void;
  categoryId?: number | null;
}

export interface FileStackToken {
  cancel: () => void | null;
}

interface TotalPercent {
  totalPercent: number;
}

export const useItem = ({
  file,
  dispatch,
  categoryId,
  setUploading
}: UseItemProps) => {
  const [status, setStatus] = useState(UploadStatus.PROCESSING);
  const [percent, setPercent] = useState(0);
  const [resource, setResource] = useState(null);
  const [uploadError, setUploadError] = useState<UploadError>(null);
  const fileId = useMemo(() => uuidv4(), []);

  const { mutateAsync: createResource, isLoading: loading } = useMutation(
    createResourceMutation()
  );

  const filestackToken: FileStackToken = useMemo(() => ({ cancel: null }), []);

  const onCancel = useCallback(() => {
    filestackToken.cancel();
    dispatch({
      type: UploadCounterActionTypes.UPDATE_COUNTER_STATUS,
      payload: {
        fileId,
        status: UploadStatus.CANCELED
      }
    });
    setStatus(UploadStatus.CANCELED);
    setUploading(false);
  }, [dispatch, fileId, filestackToken, setUploading]);

  const uploadFile = useCallback(
    async (newFile: File) => {
      setStatus(UploadStatus.UPLOADING);

      dispatch({
        type: UploadCounterActionTypes.ADD_CANCEL_FUNCTION,
        payload: {
          fileId,
          cancel: onCancel
        }
      });

      let result = null;

      try {
        const { url, _file } = await client
          .init(process.env.FILESTACK_KEY)
          .upload(
            newFile,
            {
              onProgress: ({ totalPercent }: TotalPercent) => {
                setPercent(totalPercent);
                dispatch({
                  type: UploadCounterActionTypes.UPDATE_PERCENTAGE,
                  payload: {
                    fileId,
                    percent: totalPercent
                  }
                });
              }
            },
            {},
            filestackToken
          );

        dispatch({
          type: UploadCounterActionTypes.UPDATE_COUNTER_STATUS,
          payload: {
            fileId,
            status: UploadStatus.PROCESSING
          }
        });
        setStatus(UploadStatus.PROCESSING);

        const { Data } = await createResource({
          body: {
            name: _file.name,
            filesize: _file.size,
            mimetype: _file.type,
            filepickerurl: url,
            parentcategoryid: categoryId || null
          }
        });
        result = Data?.[0];
      } catch (e) {
        setUploadError({
          message: e.message || e.status || 'No',
          code: e.networkError?.statusCode
        });
      }
      if (result) {
        setResource(result);
        setStatus(UploadStatus.COMPLETE);
        dispatch({
          type: UploadCounterActionTypes.UPDATE_COUNTER_STATUS,
          payload: {
            fileId,
            status: UploadStatus.COMPLETE,
            createdResource: result
          }
        });
      }
      return result;
    },
    [dispatch, createResource, filestackToken, fileId, categoryId, onCancel]
  );

  const onRetry = () => {
    dispatch({
      type: UploadCounterActionTypes.UPDATE_COUNTER_STATUS,
      payload: {
        fileId,
        status: UploadStatus.UPLOADING
      }
    });
    uploadFile(file);
    setUploading(true);
  };

  useEffect(() => {
    if (uploadError && status !== UploadStatus.CANCELED) {
      setStatus(UploadStatus.ERROR);
      dispatch({
        type: UploadCounterActionTypes.UPDATE_COUNTER_STATUS,
        payload: {
          fileId,
          status: UploadStatus.ERROR
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  useEffect(() => {
    dispatch({
      type: UploadCounterActionTypes.ADD_TO_COUNTER,
      payload: {
        fileId
      }
    });
    uploadFile(file);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    uploadFile,
    createResource,
    loading,
    resource,
    status,
    percent,
    uploadError,
    onCancel,
    onRetry
  };
};
