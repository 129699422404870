import { useMemo } from 'react';

import Drawer from '@setvi/shared/components/sdrawer';
import { AuxProps } from '@setvi/shared/interfaces';

import { ComposeEmail } from 'Components/Shared/ComposeEmailV2';
import { useAppContext } from 'Providers/AppProvider/AppContext';

import { ComposeEmailContext } from './Context';
import { useCompose } from './Hooks';

export const ComposeEmailProvider = ({ children }: AuxProps) => {
  const { sasToken, algoliaKey, user } = useAppContext();

  const {
    links,
    chips,
    values,
    isLoading,
    openLinkId,
    isMinimized,
    isDrawerOpen,
    initLinkOptions,
    openComposeEmail,
    currentDrawerContent,

    open,
    close,
    onSubmit,
    setValues,
    onChipAdd,
    insertLink,
    removeLink,
    onChipDelete,
    toggleDrawer,
    setOpenLinkId,
    setEditorNode,
    handleSnipset,
    setIsMinimized,
    closeComposeEmail,
    modifyRecipientList,
    setCurrentDrawerContent,
    onSuggestedContactSelect,
    modifyRecipientListWithListOfContacts
  } = useCompose();

  const contextValue = useMemo(
    () => ({
      values,
      links,
      open,
      close
    }),
    [values, links, open, close]
  );

  return (
    <ComposeEmailContext.Provider value={contextValue}>
      {children}
      {openComposeEmail && (
        <>
          <ComposeEmail
            drawerType={currentDrawerContent}
            initLinkOptions={initLinkOptions}
            openDrawer={isDrawerOpen}
            isMinimized={isMinimized}
            loading={isLoading}
            values={values}
            links={links}
            chips={chips}
            setIsMinimized={setIsMinimized}
            onSubmit={onSubmit}
            onChipAdd={onChipAdd}
            setValues={setValues}
            onChipDelete={onChipDelete}
            toggleDrawer={toggleDrawer}
            setEditorNode={setEditorNode}
            closeComposeEmail={closeComposeEmail}
            onSuggestedContactSelect={onSuggestedContactSelect}
          />

          {isDrawerOpen && (
            <Drawer
              links={links}
              sasToken={sasToken}
              algoliaKey={algoliaKey}
              openLinkId={openLinkId}
              showContent={currentDrawerContent}
              selectedContacts={contextValue.values.sendTo}
              modifyRecipientList={modifyRecipientList}
              modifyRecipientListWithListOfContacts={
                modifyRecipientListWithListOfContacts
              }
              salesforceUser={{
                SalesforceEmail: user.SalesforceEmail,
                SalesforceUserID: user.SalesforceUserID
              }}
              handleClose={() => {
                toggleDrawer();
                setOpenLinkId(null);
              }}
              handleInsert={insertLink}
              handleRemoveLink={removeLink}
              setShowContent={setCurrentDrawerContent}
              insertMessage={handleSnipset}
            />
          )}
        </>
      )}
    </ComposeEmailContext.Provider>
  );
};
