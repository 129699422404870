import { useState, useCallback, useMemo, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { TContact } from '@setvi/shared/components/sdrawer/contacts/contact';
import { DrawerContent, LinkObjectType } from '@setvi/shared/enums';
import { Link, Recipient } from '@setvi/shared/interfaces';
import { parseHtmlFEtoBC } from '@setvi/shared/utils';

import {
  LinkActions,
  useEditorLinksHook
} from '@setvi/shared/components/seditor/hooks';
import { GetLinkActionProps } from '@setvi/shared/components/seditor/interfaces';
import { DRAWER_WIDTH } from '@setvi/shared/components/sdrawer/base';

import { Contact } from 'Services/Query/Contacts/Types';
import { sendEMailMutation, userSignatureQuery } from 'Services';
import {
  emailChipErrorHandler,
  removeDuplicateRecipients,
  getRecipientListFromExcelList
} from 'Components/Shared/ComposeEmailV2/Utils';
import { Chip } from 'Components/Shared/ComposeEmailV2/Components/Chip';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useNylas } from 'Hooks/useNylas';
import { addFreeSpaceToEmailContent } from 'Utils/addFreeSpaceToEmailContent';
import { useUploadResourcePanel } from 'providersV2/upload-resource-panel/context';

import { openComposeProps } from '../Context';

const initialValues = {
  sendTo: [] as Recipient[],
  subject: '',
  body: '',
  textBody: ''
};

export enum EmailDimensions {
  MAX_WIDTH_MINIMIZED = 490,
  MAX_WIDTH_MAXIMIZED = 730,
  MAX_HEIGHT_MINIMIZED = 50,
  MAX_HEIGHT_MAXIMIZED = 650
}

export const useCompose = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setOffset } = useUploadResourcePanel();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: sendMail, isLoading } = useMutation(sendEMailMutation());
  const [values, setValues] = useState(initialValues);
  const [openComposeEmail, setOpenComposeEmail] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentDrawerContent, setCurrentDrawerContent] =
    useState<DrawerContent | null>(null);
  const [openLinkId, setOpenLinkId] = useState('');
  const [editorNode, setEditorNode] = useState(null);
  const [importedLinks, setImportedLinks] = useState<Link[]>([]);
  const [isMinimized, setIsMinimized] = useState(false);

  const { data: signature, isFetching: isSignatureFetching } = useQuery(
    userSignatureQuery()
  );
  const { nylasToken } = useAppContext();
  const { getStatus: getNylasStatus } = useNylas(nylasToken);

  const getLinkAction = useCallback(({ action, id }: GetLinkActionProps) => {
    setIsDrawerOpen(true);
    setCurrentDrawerContent(DrawerContent.reviewLinks);

    if (action === LinkActions.EDIT) setOpenLinkId(id);
    else setOpenLinkId(null);
  }, []);

  const { handleLinks, handleSnippet, setLinks, links, initLinkOptions } =
    useEditorLinksHook({
      editorRef: editorNode,
      importedLinks,
      getLinkAction
    });

  const removeLink = (deleteLink: Link) => {
    handleLinks(LinkActions.REMOVE, { link: deleteLink });
  };

  const clearValues = () => setValues(initialValues);

  const closeComposeEmail = () => {
    setLinks([]);
    clearValues();
    setImportedLinks([]);
    setEditorNode(null);
    setOpenComposeEmail(false);
    setCurrentDrawerContent(null);
  };

  const open = useCallback(({ linksData, drawerContent }: openComposeProps) => {
    setOpenComposeEmail(true);
    if (linksData) setImportedLinks([linksData]);
    if (drawerContent) setCurrentDrawerContent(drawerContent);
  }, []);

  const close = () => {
    setOpenComposeEmail(false);
    setCurrentDrawerContent(null);
  };

  const insertLink = useCallback(
    (newLink: Link, oldLink?: Link) => {
      if (!oldLink) handleLinks(LinkActions.INSERT, { link: newLink });
      else handleLinks(LinkActions.UPDATE, { link: newLink, oldLink });
    },
    [handleLinks]
  );

  const toggleDrawer = (content?: DrawerContent | null) => {
    setCurrentDrawerContent(content || null);
    setIsDrawerOpen(Boolean(content));
  };

  // API FUNCTIONS

  const addContactToRecipients = (contact: TContact) =>
    setValues({
      ...values,
      sendTo: [
        ...values.sendTo,
        {
          Email: contact.Email,
          CRMWhatId: contact.CRMWhatId || '',
          CRMWhoId: contact.CRMWhoId || ''
        }
      ]
    });

  const removeContactFromRecipients = (contact: TContact) =>
    setValues({
      ...values,
      sendTo: values.sendTo.filter(
        (recipient: any) => recipient.Email !== contact.Email
      )
    });

  const modifyRecipientList = (contact: TContact) => {
    const isContactInLRecipientList: boolean = values.sendTo.some(
      (recipient: any) => recipient.Email === contact.Email
    );

    if (isContactInLRecipientList) return removeContactFromRecipients(contact);
    addContactToRecipients(contact);
  };

  const onSuggestedContactSelect = useCallback(
    (contact: Contact) => {
      const isEmailInRecipientList: boolean = values.sendTo.some(
        (email: any) =>
          email.Email.toLowerCase() === contact.Email.toLowerCase()
      );

      if (isEmailInRecipientList) return;

      setValues({
        ...values,
        sendTo: [
          ...values.sendTo,
          {
            Email: contact.Email
          }
        ]
      });
    },
    [values]
  );

  const onSubmit = async () => {
    setLoading(true);
    if (nylasToken) {
      const nylasResponse = await getNylasStatus();

      if (nylasResponse.message || nylasResponse.sync_state === 'invalid') {
        enqueueSnackbar(
          nylasResponse.message ||
            'Something went wrong, please try again later',
          {
            variant: 'error'
          }
        );
        setLoading(false);
        return;
      }
    }

    const { body, textBody, sendTo, subject } = values;

    const parsedBody = {
      body: {
        Subject: subject,
        HtmlBody: parseHtmlFEtoBC(body),
        Recipients: sendTo,
        PlainBody: textBody,
        Links: links.map(link => ({
          Objects: link.ParentID
            ? [
                {
                  ObjectId: link.ParentID,
                  ObjectType: LinkObjectType.Presentation
                }
              ]
            : link.Item.Items.map(item => ({
                ObjectId: item.ID,
                ObjectType: item.ObjectType
              })),
          LinkName: link.Name,
          Placeholder: link.Placeholder
        }))
      }
    };

    const { Data } = await sendMail(parsedBody);

    setLoading(false);

    if (Data) closeComposeEmail();

    enqueueSnackbar(
      Data
        ? 'Email is added to a Queue and It will be scheduled to send soon'
        : 'Something went wrong, please try again later',
      {
        variant: Data ? 'success' : 'error'
      }
    );
  };

  // CHIP FUNCTIONS

  const chips: Chip[] = useMemo(() => {
    const chipsList: Chip[] = values.sendTo.map((recipient: any) => ({
      label: recipient.Email,
      errors: emailChipErrorHandler(recipient.Email)
    }));

    return chipsList;
  }, [values.sendTo]);

  const onChipAdd = useCallback(
    (chip: string) =>
      setValues({
        ...values,
        sendTo: removeDuplicateRecipients([
          ...values.sendTo,
          ...getRecipientListFromExcelList(chip)
        ])
      }),
    [values]
  );

  const onChipDelete = useCallback(
    (chip: Chip) => {
      setValues({
        ...values,
        sendTo: values.sendTo.filter(
          (recipient: any) => recipient.Email !== chip.label
        )
      });
    },
    [values]
  );

  const addListOfContactsToRecipients = (contacts: TContact[]) => {
    const filteredRecipients: Recipient[] = [];

    contacts.forEach(contact => {
      const isDuplicate: boolean = values.sendTo.some(
        c => contact.Email.toLowerCase() === c.Email.toLowerCase()
      );

      if (!isDuplicate) filteredRecipients.push({ Email: contact.Email });
    });

    setValues({
      ...values,
      sendTo: [...values.sendTo, ...filteredRecipients]
    });
  };

  const removeListOfContactsFromRecipients = (contacts: TContact[]) => {
    const filteredRecipients: Recipient[] = values.sendTo.filter(
      recipient =>
        !contacts.some(
          contact =>
            contact.Email.toLowerCase() === recipient.Email.toLowerCase()
        )
    );

    setValues({
      ...values,
      sendTo: filteredRecipients
    });
  };

  const modifyRecipientListWithListOfContacts = (
    contacts: TContact[],
    add: boolean
  ) => {
    if (add) return addListOfContactsToRecipients(contacts);
    removeListOfContactsFromRecipients(contacts);
  };

  const handleSnipset = (value: string) => {
    handleSnippet(value);
  };

  useEffect(() => {
    if (!signature || !editorNode) return;

    editorNode?.setProgressState(isSignatureFetching);
    setValues((prev: any) => ({
      ...prev,
      body: `${prev.body}${addFreeSpaceToEmailContent(signature)}`
    }));
  }, [editorNode, isSignatureFetching, setValues, signature]);

  useEffect(() => {
    if (openComposeEmail) {
      let offset = 0;
      if (isMinimized) offset += EmailDimensions.MAX_WIDTH_MINIMIZED;
      else offset += EmailDimensions.MAX_WIDTH_MAXIMIZED;

      if (isDrawerOpen) offset += DRAWER_WIDTH;

      setOffset(offset);
    } else setOffset(20);
  }, [isDrawerOpen, openComposeEmail, isMinimized, setOffset]);

  return {
    links,
    chips,
    values,
    isLoading: isLoading || loading,
    openLinkId,
    editorNode,
    isDrawerOpen,
    openComposeEmail,
    currentDrawerContent,
    initLinkOptions,
    isMinimized,

    handleSnipset,
    setIsMinimized,
    open,
    close,
    onSubmit,
    setValues,
    onChipAdd,
    insertLink,
    removeLink,
    toggleDrawer,
    onChipDelete,
    setEditorNode,
    setOpenLinkId,
    closeComposeEmail,
    modifyRecipientList,
    setCurrentDrawerContent,
    onSuggestedContactSelect,
    modifyRecipientListWithListOfContacts
  };
};
