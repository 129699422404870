export enum GroupsApi {
  Groups = '/api/v1.0/users/contacts/groups',
  GroupContacts = 'api/v1.0/users/contacts/groups/:groupId/groupcontacts',
  CreateGroup = '/api/v1.0/users/contacts/groups',
  DeleteGroups = '/api/v1.0/users/contacts/groups',
  EditGroup = '/api/v1.0/users/contacts/groups/group/:groupId',
  AddContactsToGroup = '/api/v1.0/users/contacts/groups/:groupId',
  RemoveContactsFromGroup = '/api/v1.0/users/contacts/groups/:groupId'
}

export enum AdminGroupsApi {
  Groups = '/api/v1.0/groups',
  Group = '/api/v1.0/groups/:groupId',
  GroupUsers = '/api/v1.0/groups/:groupId/users'
}

export enum AdminV2GroupsApi {
  Groups = '/api/v2/groups'
}
