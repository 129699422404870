import { checkboxValidation } from 'utilsV2';

import {
  tagsValidation,
  nameValidation,
  namesValidation,
  statusValidation,
  commentValidation,
  thumbnailValidation,
  categoriesValidation,
  uploadTypeValidation,
  expireOnDateValidation,
  validFromDateValidation
} from '../../validations';

/* FROMIK VALIDATION SCHEMAS */

export const detailsSchema = [
  {
    name: 'Name',
    schema: nameValidation
  },
  {
    name: 'Status',
    schema: statusValidation
  },
  {
    name: 'Thumbnail',
    schema: thumbnailValidation
  }
];

export const categoriesSchema = [
  {
    name: 'Categories',
    schema: categoriesValidation
  }
];

export const zipDetailsSchema = [
  {
    name: 'UploadType',
    schema: uploadTypeValidation
  },
  {
    name: 'CategoryOption',
    schema: checkboxValidation
  }
];

export const bulkDetailsSchema = [
  {
    name: 'Names',
    schema: namesValidation
  }
];

export const commentTagsSchema = [
  {
    name: 'Comment',
    schema: commentValidation
  },
  {
    name: 'Tags',
    schema: tagsValidation
  }
];

export const permissionSchema = [
  {
    name: 'ValidFromDate',
    schema: validFromDateValidation
  },
  {
    name: 'ExpireOnDate',
    schema: expireOnDateValidation
  },
  {
    name: 'WebApp',
    schema: checkboxValidation
  },
  {
    name: 'Download',
    schema: checkboxValidation
  },
  {
    name: 'Shareable',
    schema: checkboxValidation
  },
  {
    name: 'ShareTrack',
    schema: checkboxValidation
  }
];

export const notificationSchema = [
  {
    name: 'EmailNotification',
    schema: checkboxValidation
  },
  {
    name: 'PushNotification',
    schema: checkboxValidation
  },
  {
    name: 'ExcludeNotification',
    schema: checkboxValidation
  }
];
