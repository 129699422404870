import { List as MuiList } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const StyledList = withStyles({
  root: {
    height: '100%'
  }
})(MuiList);

export const useListStyles = makeStyles({
  clip: {
    width: '12px',
    height: '12px'
  },
  bold: {
    fontWeight: 'bold'
  },
  itemBorder: {
    borderBottom: '1px solid #D5DDEA'
  }
});

export const useStyles = makeStyles({
  radioGroup: {
    height: '100%'
  }
});
