import moment from 'moment';
import { countries } from 'country-data';

import { RolesEnum } from '../../enums';
import {
  FillBgBriefcaseIcon,
  FillBgStarIcon,
  FillBgUserIcon
} from '../../components/sicons';

export { getInitials } from './getInitials';

export const calculatePrecentage = (base: number, of: number) => {
  const calculation = Math.round((base / of) * 100);

  if (!calculation || calculation === Infinity) return 0;

  if (calculation < 10) return `0${calculation}`;

  return calculation;
};

export const daysRemaining = (date: string) => {
  const eventdate = moment(date);
  const todaysdate = moment();

  return eventdate.diff(todaysdate, 'days');
};

export const cleanSearchString = (str: string) => {
  if (!str) return '';

  const tempstring = str.replace(/\+/g, ' ');

  return tempstring;
};

export const generateRole = (role: number) => {
  if (!role) return '-';

  return Object.values(RolesEnum)?.[role - 1] || '-';
};

export const generateRoleIcon = (role: number) => {
  if (!role) return '-';

  const icons = [
    <FillBgBriefcaseIcon />,
    <FillBgUserIcon />,
    <FillBgStarIcon htmlColor="#572BD3" />
  ];

  return icons?.[role - 1] || '-';
};

export const generateCountryCode = (fullName: string) => {
  if (!fullName) return '-';
  const countryCode = countries.all.find(code => code.name === fullName);

  if (countryCode) return countryCode.alpha2;

  return '-';
};
