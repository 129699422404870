import { Dispatch, useMemo } from 'react';
import { ListItem, Divider, LinearProgress, Box } from '@material-ui/core';
import { ResourceType } from '@setvi/shared/enums/resources';
import {
  GetResourceTypeIdFromMime,
  GetResourceTypeIcon
} from '@setvi/shared/utils/resources';
import SETVIImage from '@setvi/shared/components/setvi-image';
import { useItem } from './useItem';
import { Details } from './details';
import { UploadCounterAction, UploadStatus } from '../usePanel';
import { ItemContainer, ProgressContainer, useStyles } from './style';
import SText from '../../sui/stext';

export interface UploadError {
  message: string;
  code: string;
}

interface ItemProps {
  file: File;
  drawer?: boolean;
  dispatch: Dispatch<UploadCounterAction>;
  setUploading?: (value: boolean) => void;
  categoryId?: number | null;
}

export const Item = ({
  file,
  drawer,
  dispatch,
  setUploading,
  categoryId
}: ItemProps) => {
  const classes = useStyles();

  const { status, percent, uploadError, onCancel, onRetry } = useItem({
    file,
    dispatch,
    categoryId,
    setUploading
  });

  const fileIcon = useMemo(() => {
    const resourceTypeID = GetResourceTypeIdFromMime(file.type);

    return GetResourceTypeIcon(resourceTypeID as unknown as ResourceType);
  }, [file.type]);

  return (
    <>
      <ListItem className={classes.listItem}>
        <ItemContainer>
          <Box display="flex" alignItems="center">
            <SETVIImage
              src={fileIcon || '/icons/App.svg'}
              className={classes.uploadFileIcon}
              alt={`${file.type} Icon`}
            />
            <Box ml={1}>
              <SText title={file.name} />
            </Box>
          </Box>
          <Box display="flex" ml={2}>
            <Details
              status={status}
              percent={percent}
              drawer={drawer}
              errorDetails={uploadError}
              retry={onRetry}
              cancel={onCancel}
            />
          </Box>
        </ItemContainer>
        {status === UploadStatus.UPLOADING && (
          <ProgressContainer>
            <LinearProgress variant="determinate" value={percent} />
          </ProgressContainer>
        )}
      </ListItem>

      <Divider component="li" />
    </>
  );
};
