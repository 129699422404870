import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Drawer, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useComposeEmail } from 'Hooks/useComposeEmail';
import { SETVIImage } from 'Components/Shared/SETVIImage';
import { ComposeEmail } from 'Components/Shared/Icons';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { CustomMenuVariant, MenuItems } from './MenuItems';
import { MenuButton } from './MenuItems/Button';

export const drawerWidth = 250;
export const collapsedDrawerWidth = 90;

const useStyles = makeStyles(theme => ({
  CompanyNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(3)}px 0`
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawer: {
    position: 'relative',
    border: 'none',
    whiteSpace: 'nowrap',
    background: '#F7F9FA',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerCollapsed: {
    width: collapsedDrawerWidth
  },
  logo: {
    maxHeight: '50px',
    maxWidth: '75px',
    margin: '0 auto',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const SidebarContent = withStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX: 'hidden'
  }
})(Box);

interface ISidebar {
  collapsedDrawer: boolean;
  expandedSidebarMenuItem: any;
  setExpandedSidebarMenuItem: (expandedSidebarMenuItem: any) => void;
}

export const Sidebar = ({
  collapsedDrawer,
  expandedSidebarMenuItem,
  setExpandedSidebarMenuItem
}: ISidebar) => {
  const classes = useStyles();
  const { open } = useComposeEmail();
  const { companyData } = useAppContext();

  return (
    <Drawer
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerCollapsed]: collapsedDrawer
        })
      }}
      variant="permanent">
      <SidebarContent>
        <Box>
          <Box className={classes.CompanyNameContainer}>
            <SETVIImage
              src={
                companyData.webAppLogoUrl ||
                companyData.iphonelogourl ||
                'https://setvi.azureedge.net/public/Setvi/Logos/PNG/SETVI-Horizontal-Logo-RGB-FullColor_Transparent1x.png'
              }
              alt="Company Logo"
              width="80%"
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <MenuButton
              onClick={() => open({})}
              icon={<ComposeEmail color="primary" />}
              label="Compose Email"
              customVariant={
                collapsedDrawer
                  ? CustomMenuVariant.Collapsed
                  : CustomMenuVariant.Extended
              }
            />
          </Box>
          <MenuItems
            expandedSidebarMenuItem={expandedSidebarMenuItem}
            setExpandedSidebarMenuItem={setExpandedSidebarMenuItem}
            collapsedDrawer={collapsedDrawer}
          />
        </Box>
        <SETVIImage
          className={classes.logo}
          src={
            collapsedDrawer
              ? 'https://setvi.azureedge.net/public/Setvi/Logos/PNG/SETVI-Horizontal-icon-RGB-Reversed-FullColor-Transparent2x.png'
              : 'https://setvi.azureedge.net/public/Setvi/Logos/PNG/SETVI-Horizontal-Logo-RGB-FullColor_Transparent3x.png'
          }
          alt="Setvi Logo"
        />
      </SidebarContent>
    </Drawer>
  );
};
