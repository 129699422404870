import { RESOURCE_TYPE } from '@setvi/shared/enums/resources';
import { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ResourceType } from '@setvi/shared/enums';
import { getResource } from '@setvi/shared/services';
import {
  myResourceFilter,
  resourceFilter,
  useAlgolia
} from '../hooks/useAlgolia';
import { Resource } from '../../../interfaces';

interface CustomResource extends Resource {
  IsChecked?: boolean;
}
interface UseSearchResultsProps {
  searchTerm?: string;
  sasToken?: string;
  algoliaKey?: string;
  resourceType: RESOURCE_TYPE;

  onSelect: (isChecked: boolean, resources: any) => void;
}

const ResourcesWithChildren = [
  ResourceType.Presentation,
  ResourceType.KeyNote,
  ResourceType.PhotoAlbum,
  ResourceType.PdfDocument
];

export const useSearchResults = ({
  searchTerm,
  sasToken,
  algoliaKey,
  resourceType,
  onSelect
}: UseSearchResultsProps) => {
  const [resource, setResource] = useState<CustomResource>(null);
  const {
    isLoading: isAlgoliaLoading,
    resources: algoliaResultResources,
    searchAlgoliaResources,
    loadNextAlgoliaResult,
    totalCount
  } = useAlgolia({
    sasToken,
    algoliaKey,
    filters:
      resourceType === RESOURCE_TYPE.ALL_RESOURCES ||
      resourceType === RESOURCE_TYPE.REQURIED_READING
        ? resourceFilter
        : myResourceFilter
  });

  const { data, isFetching } = useQuery({
    ...getResource(resource?.ID),
    enabled: !!resource?.ID
  });

  const handleSearchQueryChange = useCallback(
    (query: string) => {
      searchAlgoliaResources(query);
    },
    [searchAlgoliaResources]
  );

  useEffect(() => {
    handleSearchQueryChange(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (data && resource) {
      const allResrouces = resource?.IsChecked
        ? // This condition is added to handle case when we add full pdf/ppt file
          [data]
        : // This condition is added to handle case when we add some pages pdf/ppt file
          [data, ...data?.ChildResources];

      onSelect(resource?.IsChecked, allResrouces);
      setResource(null);
    }
  }, [data, onSelect, resource]);

  const loadNextPage = useCallback(
    () => loadNextAlgoliaResult(searchTerm),
    [loadNextAlgoliaResult, searchTerm]
  );

  const handleSelect = useCallback(
    async (isChecked: boolean, resources: any) => {
      // Skip api call if resource type doesn't have child resources
      if (ResourcesWithChildren.indexOf(resources[0].ResourceTypeID) === -1) {
        onSelect(isChecked, [resources[0]]);
        return;
      }

      setResource({ ...resources[0], IsChecked: isChecked });
    },
    [onSelect]
  );

  const handleCheckboxLoading = useCallback(
    (currResrouce: Resource) => {
      if (resource?.ID === currResrouce?.ID) return isFetching;
      return false;
    },
    [isFetching, resource?.ID]
  );

  return {
    algoliaResultResources,
    handleCheckboxLoading,
    isAlgoliaLoading,
    loadNextPage,
    totalCount,
    handleSelect
  };
};
