import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { useAuth0 } from '@auth0/auth0-react';
import * as LogRocket from 'logrocket';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { pdfjs } from 'react-pdf';

import {
  algoliaTokenQuery,
  axiosHelper,
  sasTokenQuery,
  userInfoQuery,
  companyDataQuery,
  getCompanyModulesQuery
} from '@setvi/shared/services';
import { Analytics, AxiosMethods, UsersApi } from '@setvi/shared/enums';
import {
  User,
  CompanyData,
  CompanyModule
} from '@setvi/shared/services/react-query/query/user/types';
import { PageLoader } from 'Components/Shared/UI/Loaders/PageLoader';
import { useIsInIframe } from 'Hooks/useIsInIframe';
import { ROUTES } from 'enumsV2';
import { useReactQuery } from 'Hooks/React-Query';
import { AppContext } from './AppContext';

interface AppProviderProps {
  children: ReactNode;
}

let fetchMetadataCalled = false;

// Configure the worker source URL
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

moment.updateLocale('en', {
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    LL: 'MMM D, YYYY • HH:mmA',
    LLL: 'MM/DD/YYYY hh:mm A',
    LLLL: 'MM/DD/YYYY • h:mm a'
  }
});

// FIXME: This is a temporary solution for the iframe implementation and needs to be refactored
export const AppProvider = ({ children }: AppProviderProps) => {
  const { queryClient } = useReactQuery();
  const isIframe = useIsInIframe();
  const { logout } = useAuth0();
  const [token, setToken] = useState<string | null>(localStorage.token || null);
  const [layout, setLayout] = useState<boolean>(
    !(sessionStorage.getItem('layout') === 'false')
  );
  const [algoliaKey, setAlgoliaKey] = useState<string | null>(
    localStorage.algoliaKey || null
  );
  const [sasToken, setSasToken] = useState<string | null>(
    localStorage.sasToken || null
  );
  const [user, setUser] = useState<User | null>(
    JSON.parse(localStorage.user || null) || null
  );
  const [companyData, setCompanyData] = useState<CompanyData | null>(
    JSON.parse(localStorage.companyData || null) || null
  );
  const [companyModules, setCompanyModules] = useState<CompanyModule[]>([]);
  const [nylasToken, setNylasToken] = useState<string | null>(
    localStorage.nylasToken || null
  );
  const [initialLoader, setInitialLoader] = useState(true);

  const fetchMetadata = useCallback(
    async (_token: string) => {
      if (fetchMetadataCalled) return;

      fetchMetadataCalled = true;
      try {
        const responses = await Promise.allSettled([
          queryClient.fetchQuery({
            ...userInfoQuery()
          }),
          queryClient.fetchQuery({ ...sasTokenQuery() }),
          await queryClient.fetchQuery({
            ...algoliaTokenQuery()
          }),
          queryClient.fetchQuery({ ...companyDataQuery(_token) })
        ]);

        responses.forEach(response => {
          if (response.status === 'rejected')
            throw new Error('Something went wrong, please try again later.');
        });

        /* @ts-ignore */
        setUser(responses[0]?.value?.Data?.[0]);
        /* @ts-ignore */
        setSasToken(responses[1]?.value?.Data?.[0].Token);
        /* @ts-ignore */
        setAlgoliaKey(responses[2]?.value?.SearchApiKey);
        // @ts-ignore
        setCompanyData(responses[3]?.value?.Data[0]);
        // @ts-ignore
        setNylasToken(responses[0]?.value?.Data?.[0].EmailSyncAccessToken);
        localStorage.setItem('metadataFetchedAt', String(Date.now()));
        setInitialLoader(false);
      } catch (error) {
        localStorage.clear();
        // FIXME: This is a temporary solution for the iframe implementation
        isIframe
          ? // eslint-disable-next-line no-alert
            alert(error)
          : logout({
              logoutParams: { returnTo: window.location.origin + ROUTES.LOGIN }
            });
      }

      fetchMetadataCalled = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // FIXME: This is a temporary solution for the iframe implementation
  useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParams = params.get('accessToken');
    const layoutParams = params.get('layout');

    if (layoutParams) {
      const layoutVal = layoutParams !== 'false';
      sessionStorage.setItem('layout', String(layoutParams));
      setLayout(isIframe ? false : layoutVal);
      params.delete('layout');
    }

    if (tokenParams && tokenParams !== token) {
      setInitialLoader(true);
      localStorage.clear();
      setToken(tokenParams);
      params.delete('accessToken');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      queryClient
        .fetchQuery({
          ...getCompanyModulesQuery(token)
        })
        .then(response => setCompanyModules(response.Data));
    } else {
      setInitialLoader(false);
    }
  }, [token, queryClient]);

  useEffect(() => {
    if (algoliaKey) localStorage.setItem('algoliaKey', algoliaKey);
  }, [algoliaKey]);

  useEffect(() => {
    if (sasToken) localStorage.setItem('sasToken', sasToken);
  }, [sasToken]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      LogRocket.identify(user?.ID?.toString(), {
        name: user.FullName,
        email: user.Email
      });
    }
  }, [user]);

  useEffect(() => {
    if (companyData)
      localStorage.setItem('companyData', JSON.stringify(companyData));
  }, [companyData]);

  useEffect(() => {
    if (typeof nylasToken === 'string')
      localStorage.setItem('nylasToken', nylasToken);
    else localStorage.removeItem('nylasToken');
  }, [nylasToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      const { metadataFetchedAt } = localStorage;
      const hoursPassed = moment().diff(
        moment(Number(metadataFetchedAt)),
        'hours'
      );

      // Refech tokens after 24 hours
      if (hoursPassed >= 23) fetchMetadata(token);
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchMetadata, token]);

  useEffect(() => {
    if (token && (!algoliaKey || !sasToken || !user || !companyData)) {
      setInitialLoader(true);
      fetchMetadata(token);
    } else setInitialLoader(false);
  }, [fetchMetadata, algoliaKey, companyData, sasToken, token, user]);

  useEffect(() => {
    if (token && user) {
      // Send analytics every time application is loaded

      axiosHelper({
        endpoint: UsersApi.UserActivity,
        method: AxiosMethods.POST,
        body: {
          ResourceID: '',
          TypeID: Analytics.IdleToActive,
          starttime: Date.now(),
          endtime: Date.now(),
          children: [],
          token,
          email: user.Email
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  useEffect(() => {
    LogRocket.init(process.env.LOGROCKET_KEY, {
      mergeIframes: true,
      parentDomain: process.env.LOGROCKET_PARENT || '*'
    });
  }, []);

  if (initialLoader) return <PageLoader />;

  return (
    <AppContext.Provider
      value={{
        token,
        algoliaKey,
        user,
        companyData,
        companyModules,
        setCompanyModules,
        sasToken,
        layout,
        nylasToken,
        setNylasToken,
        setToken,
        setAlgoliaKey,
        setUser,
        setSasToken,
        setLayout
      }}>
      <LDProvider
        deferInitialization={!!user}
        clientSideID={process.env.LAUNCH_DARKLY_ID}
        context={{
          kind: 'user',
          key: String(user?.ID),
          name: user?.FullName,
          email: user?.Email,
          custom: {
            CompanyID: user?.CompanyID,
            CompanyName: user?.CompanyName
          }
        }}>
        {children}
      </LDProvider>
    </AppContext.Provider>
  );
};
