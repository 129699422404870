import { memo, useState } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useSQuadrantStyles } from './style';
import { BarIcon } from '../sicons';
import SText from '../sui/stext';

interface TopContent {
  title?: string;
  content?: JSX.Element;
}

interface SQuadrantLayoutProps {
  defaultCollapsed?: boolean;
  showCollapseButton?: boolean;
  topLeft: TopContent;
  topRight: TopContent;
  bottomLeft: JSX.Element;
  bottomRight: JSX.Element;
}

const SQuadrantLayout = ({
  defaultCollapsed = false,
  showCollapseButton = true,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight
}: SQuadrantLayoutProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const classes = useSQuadrantStyles();

  return (
    <Box className={classes.container}>
      <Grid container className={classes.grid}>
        {!isCollapsed && (
          <Grid item xs={3} className={clsx(classes.left, classes.gridItem)}>
            <Box className={classes.topConatiner}>
              {topLeft?.title && <SText weight="bold" title={topLeft.title} />}
              {topLeft?.content || null}
            </Box>
            <Box
              px={2}
              my={2}
              flexGrow={1}
              overflow="auto"
              height={100}
              minHeight={0}>
              {bottomLeft}
            </Box>
          </Grid>
        )}
        <Grid item className={classes.gridItem} xs={isCollapsed ? 12 : 9}>
          <Box className={classes.topConatiner}>
            {showCollapseButton && (
              <IconButton
                onClick={() => setIsCollapsed(collapsed => !collapsed)}>
                <BarIcon />
              </IconButton>
            )}
            {topRight?.title && <SText weight="bold" title={topRight.title} />}
            {topRight?.content || null}
          </Box>
          <Box
            px={2}
            my={2}
            flexGrow={1}
            overflow="auto"
            height={100}
            minHeight={0}>
            {bottomRight}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(SQuadrantLayout);
