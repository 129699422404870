import { withStyles } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';

export const StyledTreeItem = withStyles(({ spacing }) => ({
  root: {
    color: '#44444F',

    '& .MuiTreeItem-content': {
      borderRadius: 4
    },

    '& .MuiTreeItem-label': {
      padding: spacing(1),
      borderRadius: 4
    },

    '&.Mui-selected > .MuiTreeItem-content': {
      background: '#1E96FC !important',
      color: '#fff !important',

      '& svg': {
        color: '#fff !important'
      }
    }
  }
}))(TreeItem);
