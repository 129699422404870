import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, InputAdornment } from '@material-ui/core';

import { distances } from '@setvi/shared/styles';
import clsx from 'clsx';
import { Label } from '../UI/Label/Label';

const useStyles = makeStyles(theme => ({
  paperBox: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: distances.px.small,
    backgroundColor: 'transparent'
  },
  title: {
    marginBottom: distances.px.small
  },
  required: {
    color: '#d72638'
  },
  inputWrapper: {
    boxShadow: 'none',
    position: 'relative'
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '14px',
    marginTop: distances.px.xsmall,
    textAlign: 'right'
  },
  multiline: {
    '& .MuiInputBase-input': {
      alignSelf: 'flex-start'
    }
  }
}));

interface FormikTextfieldProps {
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
  type?: string;
  labelClass?: any;
  endIcon?: JSX.Element;
  disabled?: boolean;
  multiline?: boolean;
  rowsMax?: number | string;
  minRows?: number;
  onChange?: (value: string) => void;
  customErrors?: any;
}

export const FormikTextfield: FC<FormikTextfieldProps> = ({
  name,
  label,
  placeholder,
  required,
  type,
  labelClass,
  disabled,
  endIcon,
  multiline = false,
  rowsMax,
  minRows = 6,
  onChange,
  customErrors
}) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Box className={classes.paperBox}>
          <Label
            title={label}
            className={clsx(labelClass, classes.title)}
            variant="body2"
            required={required}
          />

          <Box className={classes.inputWrapper}>
            <TextField
              id="standard-multiline-flexible"
              name={field.name}
              type={type || 'text'}
              placeholder={placeholder}
              value={meta.value}
              multiline={multiline}
              minRows={minRows}
              maxRows={rowsMax}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                form.setFieldValue(name, event.target.value);
                onChange?.(event.target.value);
              }}
              autoComplete="off"
              disabled={disabled}
              error={
                meta.touched &&
                (Boolean(meta.error) || Boolean(customErrors?.[name]))
              }
              helperText={meta.touched && (meta.error || customErrors?.[name])}
              InputProps={{
                endAdornment: endIcon && (
                  <InputAdornment position="start">{endIcon}</InputAdornment>
                )
              }}
              variant="outlined"
            />
          </Box>
        </Box>
      )}
    </Field>
  );
};
