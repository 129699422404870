import { Box, Typography, makeStyles } from '@material-ui/core';
import { SButton, SText } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';
import { useDialog } from '@setvi/shared/providers';
import { useEmailServer } from 'Components/Settings/Integration/Hooks/useEmailServer';

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    color: TextColor.Grey,
    margin: spacing(3, 0, 4, 0)
  },
  support: {
    color: TextColor.Grey,
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: 14,
    '& a:not(:hover)': {
      color: TextColor.Grey,
      textDecoration: 'none'
    }
  }
}));

const NylasDialog = () => {
  const { closeDialog } = useDialog();
  const { connect } = useEmailServer();
  const classes = useStyles();
  return (
    <Box p={4} width={460}>
      <SText title="Sign in to email" weight="bold" />
      <Typography className={classes.description}>
        {`You email account has been disconnected from SETVI. Hit "Reconnect" and sign into your email account to continue sending from SETVI.`}
      </Typography>
      <Typography className={classes.support}>
        If you are having trouble, please reach out to{' '}
        <a href="mailto:support@setvi.com">support@setvi.com</a>
      </Typography>
      <Box display="flex" gridGap={16} mt={5}>
        <SButton variant="outlined" fullWidth onClick={() => closeDialog()}>
          Cancel
        </SButton>
        <SButton fullWidth onClick={connect}>
          Reconnect
        </SButton>
      </Box>
    </Box>
  );
};
export default NylasDialog;
