import { useMemo } from 'react';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { ROUTES } from 'enumsV2';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import {
  Home,
  Resources,
  Workspaces,
  CampaignIcon,
  Outbox
} from 'Components/Shared/Icons';
import { useQrveyActiveLinks } from 'Hooks/useQrvey/useQrveyActiveLinks';
import { CompanyModuleId } from '@setvi/shared/services/react-query/query/user/types';

import { MenuItemProps } from '../..';

enum FLAGS {
  HOME = 'home',
  CAMPAIGNS = 'campaigns',
  EMAIL_CAMPAIGNS = 'emailCampaigns',
  COMPANY_CAMPAIGNS = 'companyCampaigns',
  RESOURCES = 'resources',
  WORKSPACES = 'workspaces',
  OUTBOX = 'outbox',
  COUNTACTSGROUPS = 'contactsGroups',
  MYFILES = 'myFiles',
  MYPRESENTATIONS = 'myPresentations',
  SNIPPETS = 'snippets',
  PRODUCTS = 'revopsProducts'
}

export const useUserMenuItems = () => {
  const { activeLinks } = useQrveyActiveLinks();

  const userMenuItems: MenuItemProps[] = useMemo(
    () => [
      {
        title: 'Home',
        flag: FLAGS.HOME,
        route: ROUTES.HOME,
        icon: <Home />
      },
      {
        title: 'Resources',
        moduleId: CompanyModuleId.Resources,
        flag: FLAGS.RESOURCES,
        route: ROUTES.RESOURCES,
        baseRoute: ROUTES.RESOURCES,
        icon: <Resources />
      },
      {
        title: 'Workspaces',
        icon: <Workspaces />,
        flag: FLAGS.WORKSPACES,
        baseRoute: ROUTES.WORKSPACES,
        list: [
          {
            title: 'Contacts & Groups',
            flag: FLAGS.COUNTACTSGROUPS,
            route: ROUTES.WORKSPACES_CONTACTS_GROUPS
          },
          {
            title: 'My Files',
            flag: FLAGS.MYFILES,
            route: ROUTES.WORKSPACES_FILES
          },
          {
            title: 'My Presentations',
            moduleId: CompanyModuleId.Presentations,
            flag: FLAGS.MYPRESENTATIONS,
            route: ROUTES.WORKSPACES_PRESENTATIONS
          },
          {
            title: 'Snippets',
            flag: FLAGS.SNIPPETS,
            route: ROUTES.WORKSPACES_SNIPPETS
          }
        ]
      },
      {
        title: 'Campaigns',
        icon: <CampaignIcon />,
        baseRoute: ROUTES.CAMPAIGNS,
        list: [
          {
            title: 'Email Campaigns',
            flag: FLAGS.EMAIL_CAMPAIGNS,
            route: ROUTES.MY_CAMPAIGNS
          },
          {
            title: 'Company Campaigns',
            flag: FLAGS.COMPANY_CAMPAIGNS,
            route: ROUTES.COMPANY_CAMPAIGNS
          }
        ]
      },
      {
        title: 'Products',
        moduleId: CompanyModuleId.Products,
        route: ROUTES.PRODUCTS,
        baseRoute: ROUTES.PRODUCTS,
        flag: FLAGS.PRODUCTS,
        icon: <LocalMallOutlinedIcon />
      },
      {
        title: 'Outbox',
        flag: FLAGS.OUTBOX,
        route: ROUTES.OUTBOX,
        baseRoute: ROUTES.OUTBOX,
        icon: <Outbox />
      },
      ...(activeLinks.length > 0
        ? [
            {
              title: 'Reporting',
              baseRoute: ROUTES.REPORTING,
              icon: <AssessmentOutlinedIcon />,
              list: activeLinks.map(link => ({
                title: link.LinkName,
                route: ROUTES.REPORTING_DETAILS.replace(
                  ':id',
                  link.LinkId.toString()
                )
              }))
            }
          ]
        : [])
    ],
    [activeLinks]
  );

  return { userMenuItems };
};
