import { makeStyles, Box, Typography } from '@material-ui/core';
import NoFilesFoundIcon from '../../resources/list/empty-states/icons/NoFilesFoundIcon';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
});

export const EmptyStateTemplate = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <NoFilesFoundIcon />

      <Typography variant="subtitle1" color="textSecondary">
        No templates available
      </Typography>
    </Box>
  );
};
