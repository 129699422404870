import { Divider, LinearProgress } from '@material-ui/core';

import { RESOURCE_UPLOAD_STATUS } from '@setvi/shared/enums';

import { ItemWrapper } from './style';

export interface UploadItemProps {
  body: JSX.Element;
  header: JSX.Element;
  status: RESOURCE_UPLOAD_STATUS;
  progress?: number;
  children?: JSX.Element;
}

const UploadItem = ({
  body,
  header,
  status,
  children,
  progress
}: UploadItemProps) => (
  <>
    <ItemWrapper>
      {header}

      {body}
    </ItemWrapper>
    {status === RESOURCE_UPLOAD_STATUS.FILESTACK_UPLOADING && (
      <LinearProgress variant="determinate" value={progress || 100} />
    )}

    <Divider />
    {children}
  </>
);

export default UploadItem;
