import { useContext } from 'react';
import { ComposeEmailContext } from 'Providers/ComposeEmail/Context';

export const useComposeEmail = () => {
  const context = useContext(ComposeEmailContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useComposeEmail was used outside of its Provider');
  }

  return context;
};
