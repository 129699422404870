import { FC, ReactNode } from 'react';
import { Box, Grow, Backdrop } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link, Resource } from '@setvi/shared/interfaces';
import { Item } from '@setvi/shared/components/sdrawer/review-links/edit/item';
import { useSelected } from './useSelected';
import { Header } from './Header';

export interface SelectedProps {
  resources: Resource[];
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  handleOnInsert?: (link: Link, currentLink?: Link) => void;
  handleRemoveLink?: (link?: Link) => void;
  setLinkName?: (value: string) => void;
  setSelectedResources?: (isChecked: boolean, resources: Resource[]) => void;
  setLink?: (link: Link) => void;
  link?: Link;
  popupActionButton?: ReactNode;
  disableDeleteButton?: boolean;
  isResources?: boolean;
}

export const SelectedBox = withStyles({
  root: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    backgroundColor: '#fff',
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto) 1fr',
    maxHeight: 'calc(100vh - 100px)'
  }
})(Box);

const ListContainer = withStyles({
  root: {
    overflowY: 'scroll'
  }
})(Box);

export const ContainedBackdrop = withStyles({
  root: {
    position: 'absolute',
    display: 'block',
    zIndex: 9999
  }
})(Backdrop);

export const Selected: FC<SelectedProps> = ({
  resources,
  isExpanded,
  setIsExpanded,
  link,
  setLink,
  setLinkName,
  setSelectedResources,
  handleOnInsert,
  handleRemoveLink,
  popupActionButton,
  isResources: _isResources,
  disableDeleteButton: _disableDeleteButton
}) => {
  const { items, removeItem } = useSelected({
    resources,
    setIsExpanded,
    setLink,
    setSelectedResources,
    link,
    setLinkName,
    handleOnInsert,
    handleRemoveLink
  });

  return (
    <ContainedBackdrop open={isExpanded}>
      <Grow in={isExpanded}>
        <SelectedBox>
          <Header
            setIsExpanded={setIsExpanded}
            length={items?.length}
            isExpanded
          />

          <ListContainer>
            {items.map(resource => (
              <Item
                resource={resource}
                key={resource.ID}
                removeItem={removeResource => removeItem(removeResource.ID)}
              />
            ))}
          </ListContainer>
          {popupActionButton}
        </SelectedBox>
      </Grow>
    </ContainedBackdrop>
  );
};
