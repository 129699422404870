import { ReactNode } from 'react';
import { Loader } from './loader';
import { EmptyState } from './empty';

interface TableStateProps {
  loading: boolean;
  isSearch: boolean;
  dataLength: number;
  customEmptyState?: ReactNode;
}

export const TableState = ({
  loading,
  isSearch,
  dataLength,
  customEmptyState
}: TableStateProps) => {
  if (loading) return <Loader />;

  if (customEmptyState && !isSearch && dataLength === 0)
    return <>{customEmptyState}</>;

  if (dataLength === 0) return <EmptyState search={isSearch} />;

  return <></>;
};
