import { useCallback, useEffect, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getCompanyCategoryQuery,
  getUserCategoryQuery
} from '@setvi/shared/services';
import { CATEGORY_TYPE, RESOURCE_PAGINATION } from '../../../enums';

interface UseCategoryListProps {
  categoryId?: number;
  categoryType?: CATEGORY_TYPE;
  onLastItemLoaded?: () => void;
  onEmpty?: () => void;
}

export const useCategoryList = ({
  categoryId,
  categoryType,
  onLastItemLoaded,
  onEmpty
}: UseCategoryListProps) => {
  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: RESOURCE_PAGINATION.LIMIT,
      categoryId
    }),
    [categoryId]
  );

  const queryInput = useMemo(() => {
    if (categoryType === CATEGORY_TYPE.USER) {
      return getUserCategoryQuery(queryVariables);
    }

    return getCompanyCategoryQuery(queryVariables);
  }, [categoryType, queryVariables]);

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteQuery(queryInput);

  const loading = isLoading || isFetching;

  const fetchMore = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  const categories = useMemo(
    () => data?.pages.map(page => page.Data.Result).flat() || [],
    [data]
  );

  useEffect(() => {
    const lastElementLoaded =
      !loading && categories.length === data?.pages?.[0].Data?.Count;
    const emptyList = !loading && !categories.length;

    if (lastElementLoaded) {
      onLastItemLoaded();
    }
    if (emptyList) {
      onEmpty();
    }
  }, [categories, data, hasNextPage, loading, onEmpty, onLastItemLoaded]);

  return {
    categories,
    loading,
    fetchMore
  };
};
