import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Box, Typography, withStyles } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';

import { getShareableLinkQuery } from 'Services';

import { useCustomErrors } from 'Hooks/useCustomErrors';
import { ShareableLinkInput } from 'Types/api/globalTypes';

import { FormikTextfield } from '../../../../FormikTextfield';
import { CreateLinkButtons } from './Buttons';

interface FormProps {
  onLinkGenerated: (link: string) => void;
  onClose: () => void;
  resourceId: number;
}
const Description = withStyles(({ spacing }) => ({
  root: {
    size: 14,
    color: '#696974',
    margin: spacing(2, 0)
  }
}))(Typography);

const initialValues = {
  LinkName: ''
};

const validationSchema = Yup.object({
  LinkName: Yup.string()
    .required('Link Name is required')
    .max(100, 'Link Name should have fewer than 100 characters.')
});

export const CreateLinkForm = ({
  onLinkGenerated,
  onClose,
  resourceId
}: FormProps) => {
  const [disabled, setDisabled] = useState(true);
  const [link, setLink] = useState<ShareableLinkInput | null>();

  const { data, isFetching } = useQuery({
    ...getShareableLinkQuery(link),
    enabled: Boolean(link)
  });

  const { customErrors, customValidate } = useCustomErrors({
    schemas: [
      {
        name: 'LinkName',
        schema: validationSchema
      }
    ],
    values: initialValues
  });

  const handleSubmit = (linkName: string) => {
    setLink({
      id: resourceId,
      trackname: linkName,
      ispreso: false
    });
  };

  useEffect(() => {
    if (data) onLinkGenerated(data);
  }, [data, onLinkGenerated]);

  return (
    <>
      <Description>
        Please say a tracking name to track if this content has been viewed in
        outbox.
      </Description>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => handleSubmit(values.LinkName)}>
        <Form>
          <Box>
            <Box mt={3} mb={4}>
              <FormikTextfield
                label="Link Name"
                name="LinkName"
                placeholder="Enter Link Name"
                onChange={(value: string) => {
                  setDisabled(!value.length);

                  return customValidate({
                    schema: validationSchema,
                    value,
                    name: 'LinkName'
                  });
                }}
                customErrors={customErrors}
              />
            </Box>
            <CreateLinkButtons
              disabled={disabled}
              loading={isFetching}
              confirmButtonText="Create"
              onClose={onClose}
            />
          </Box>
        </Form>
      </Formik>
    </>
  );
};
