import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';

type RoundedButtonProps = {
  [P in keyof ButtonProps]: ButtonProps[P];
} & { bold?: boolean };

const useStyles = makeStyles(theme => ({
  rounded: {
    textTransform: 'none',
    borderRadius: 10,
    boxShadow: 'none',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem'
    }
  },
  bold: {
    fontWeight: 600
  }
}));

export const RoundedButton: FC<RoundedButtonProps> = ({
  bold = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.rounded, bold && classes.bold)}
      {...props}
    />
  );
};
