import { Box, withStyles } from '@material-ui/core';

import { distances } from '@setvi/shared/styles';

export const ContentContainer = withStyles(({ spacing }) => ({
  root: {
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '450px',
    width: '100%',
    height: '100%',
    marginTop: spacing(2),
    padding: distances.px.large
  }
}))(Box);
