import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={176}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M87.78 111.371c29.701 0 53.901-24.1 53.901-53.7-.1-29.7-24.2-53.8-53.9-53.8-29.8 0-53.9 24.1-53.9 53.7 0 29.7 24.1 53.8 53.9 53.8Z"
      fill="#EAEEF9"
    />
    <g filter="url(#a)">
      <path
        d="M145.28 64.471c0 11.5-9.3 20.8-20.9 20.8h-69.8c-13.9.3-25-10.8-25-24.2 0-13.5 11.2-24.7 25.3-24 12.1-37.8 67.5-32.5 72.2 6.6 10.4 1.3 18.2 10.1 18.2 20.8Z"
        fill="url(#b)"
      />
    </g>
    <path
      d="M124.38 85.271c11.5 0 20.9-9.3 20.9-20.8s-9.4-20.8-20.9-20.8c-11.5 0-20.9 9.3-20.9 20.8s9.4 20.8 20.9 20.8Z"
      fill="url(#c)"
    />
    <path
      d="M90.28 85.471c20.5 0 37.2-16.6 37.2-37.1s-16.7-37.1-37.2-37.1c-20.5 0-37.2 16.6-37.2 37.1s16.6 37.1 37.2 37.1Z"
      fill="url(#d)"
    />
    <path
      d="M76.521 57.891c1.575 0 2.907-1.34 2.907-2.91 0-1.58-1.332-2.91-2.907-2.91s-2.907 1.33-2.907 2.91c0 1.57 1.332 2.91 2.907 2.91Zm22.287 0c1.575 0 2.907-1.34 2.907-2.91 0-1.58-1.332-2.91-2.907-2.91-1.574 0-2.907 1.33-2.907 2.91 0 1.7 1.333 2.91 2.907 2.91Zm-22.4-12.53-6.394 3.45.863 1.6 6.395-3.45-.864-1.6Zm22.006-.04-.863 1.59 6.396 3.46.863-1.6-6.396-3.45Zm-10.749 19.35c1.873 0 3.391-1.14 3.391-2.54 0-1.41-1.517-2.55-3.39-2.55-1.873 0-3.393 1.14-3.393 2.55 0 1.4 1.52 2.54 3.392 2.54Z"
      fill="#989FB0"
    />
    <path
      d="M18.034 18.221s-13.978 3.55-9.742 6.39c4.236 2.7 14.26 7.38 23.72 4.26 9.46-3.12 0-7.52.142-7.52.14 0-14.12-3.13-14.12-3.13Z"
      fill="#CED7E2"
    />
    <path
      d="M.95 17.091c5.083 1.56 20.755-1 30.78-7.53 7.908 9.8 10.873 12.35 13.697 12.64-4.377 4.4-18.92 11.5-27.11 2.27C8.715 30.571.95 17.091.95 17.091Zm130.07 35.49c5.365.29 19.908-6.24 27.815-15.04 10.167 7.52 13.555 9.22 16.521 8.8-3.248 5.39-13.132 17.17-27.11 18.03-10.167-5.97-17.226-11.79-17.226-11.79Z"
      fill="#E9EDF9"
    />
    <path
      d="M88.189 119.171c-1.062-5.26-11.01-17.72-21.503-23.19 4.754-11.72 5.56-15.42 4.409-18.19 6.034 1.8 19.908 8.44 24.215 21.77-3.243 11.33-7.121 19.61-7.121 19.61Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="b"
        x1={87.43}
        y1={41.901}
        x2={87.392}
        y2={86.071}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={101.256}
        y1={39.191}
        x2={119.783}
        y2={59.451}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#BCCBE1" />
        <stop offset={0.994} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={49.596}
        y1={31.341}
        x2={78.798}
        y2={42.771}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#E2E8F0" />
        <stop offset={0.994} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <filter
        id="a"
        x={7.58}
        y={0.362}
        width={159.7}
        height={117.916}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6312_105822"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_6312_105822"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const NoFilesFoundIcon = memo(SvgComponent);
export default NoFilesFoundIcon;
