import { Button } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { SText } from '@setvi/shared/components';
import { useButtonStyles } from './style';

export interface LocationButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  active: boolean;
  title: string;
  titleHighlighted?: boolean;
}

export const LocationButon = ({
  onClick,
  active,
  title,
  titleHighlighted
}: LocationButtonProps) => {
  const styles = useButtonStyles();

  return (
    <Button
      onClick={e => onClick(e)}
      className={clsx({
        [styles.button]: true,
        [styles.buttonActive]: active
      })}
      endIcon={
        active ? (
          <KeyboardArrowUp color="primary" />
        ) : (
          <KeyboardArrowDown htmlColor="#92929D" />
        )
      }>
      <SText
        className={clsx({ [styles.titleHighlght]: titleHighlighted })}
        title={title}
      />
    </Button>
  );
};
