import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Content as ContentContainer } from '@setvi/shared/components/sdrawer/base';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { linkAddress } from '@setvi/shared/utils/regex';
import { distances } from '@setvi/shared/styles';
import { Link } from '@setvi/shared/interfaces';
import {
  LinkTextIcon,
  LinkUrlIcon,
  WarningIcon
} from '@setvi/shared/components/sicons';

import SLabel from '../../sui/slabel';
import { CenterBox, useStyles } from './style';
import { useContent } from './hooks/useContent';

interface ContentProps {
  showTitle?: boolean;
  handleInsert?: (link: Link) => void;
  handleBack(): void;
  handleClose(): void;
}

const MAX_LINK_NAME = 100;
const MAX_LINK_ADDRESS = 2048;

export const Content = ({
  showTitle,
  handleInsert,
  handleBack,
  handleClose
}: ContentProps) => {
  const classes = useStyles();

  const { onInsertWebLink, loading } = useContent({ handleInsert });

  const linkNameForm = useFormik({
    initialValues: {
      webText: '',
      url: ''
    },
    validationSchema: Yup.object({
      webText: Yup.string()
        .max(MAX_LINK_NAME, `Limit is ${MAX_LINK_NAME} characters`)
        .required('Display text is required'),
      url: Yup.string()
        .max(MAX_LINK_ADDRESS, `Limit is ${MAX_LINK_ADDRESS} characters`)
        .matches(linkAddress, 'Enter correct link address!')
        .required('Link address is required')
    }),
    onSubmit: ({ webText, url }) => onInsertWebLink(webText, url)
  });

  return (
    <ContentContainer
      header={
        <Header title="Add from SETVI" hasClose handleClose={handleClose} />
      }
      backContent={
        <BaseSubheader handleBack={handleBack} text="Add web URL" />
      }>
      <Box
        margin={`${distances.px.small} ${distances.px.medium} ${distances.px.medium}`}>
        {showTitle && (
          <Box display="flex" flexDirection="row">
            <Typography variant="h6" className={classes.bold}>
              Add web URL
            </Typography>
            <CenterBox pl={distances.px.small}>
              <WarningIcon viewBox="0 0 18 18" htmlColor="#171725" />
            </CenterBox>
          </Box>
        )}
        <form autoComplete="off" onSubmit={linkNameForm.handleSubmit}>
          <Grid container direction="column" spacing={6}>
            <Grid item>
              <Typography variant="body2" className={classes.topTxt}>
                Enter a web address and display text to adjust how the link is
                going to be displayed in the email
              </Typography>
            </Grid>
            <Grid item>
              <SLabel title="Link Address" required />
              <TextField
                autoComplete="off"
                placeholder="Link Address"
                variant="outlined"
                fullWidth
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkUrlIcon />
                    </InputAdornment>
                  )
                }}
                name="url"
                value={linkNameForm.values.url}
                onChange={linkNameForm.handleChange}
                error={
                  linkNameForm.touched.url && Boolean(linkNameForm.errors.url)
                }
                helperText={linkNameForm.touched.url && linkNameForm.errors.url}
              />
            </Grid>

            <Grid item>
              <SLabel title="Display Text" required />
              <TextField
                placeholder="Display Text"
                fullWidth
                type="text"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkTextIcon />
                    </InputAdornment>
                  )
                }}
                name="webText"
                value={linkNameForm.values.webText}
                onChange={linkNameForm.handleChange}
                error={
                  linkNameForm.touched.webText &&
                  Boolean(linkNameForm.errors.webText)
                }
                helperText={
                  linkNameForm.touched.webText && linkNameForm.errors.webText
                }
              />
            </Grid>

            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <CenterBox>
                    <Button
                      disabled={
                        loading ||
                        linkNameForm.values.url.length === 0 ||
                        linkNameForm.values.webText.length === 0
                      }
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      type="submit">
                      Insert link
                      {loading && (
                        <CircularProgress
                          className={classes.spinner}
                          size={14}
                        />
                      )}
                    </Button>
                  </CenterBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ContentContainer>
  );
};
