import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

export const CustomCheckbox = withStyles(({ palette }) => ({
  root: {
    borderRadius: 4,
    color: palette.text.secondary,
    '&$checked, &$indeterminate': {
      color: palette.primary.main
    }
  },
  colorPrimary: {},
  checked: {},
  indeterminate: {}
}))(Checkbox);
