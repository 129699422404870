import { Accept } from 'react-dropzone';

export const AcceptedFileTypes: Accept = {
  'image/jpeg': [],
  'image/png': [],
  'image/gif': [],
  'application/pdf': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    []
};
