import { makeStyles, Typography } from '@material-ui/core';
import {
  Contact,
  TContact,
  ContactProps
} from '@setvi/shared/components/sdrawer/contacts/contact';
import { distances } from '@setvi/shared/styles';
import SInfiniteScroll from '../../../sinfinite-scroll';
import { SortableContactProperties } from '../contact-filter';
import { Recipient } from '../../../../interfaces';

interface AddressBookProps {
  contacts: TContact[];
  selectedContacts?: Recipient[];
  children?: JSX.Element;
  onContactClick?: ContactProps['onClick'];
  fetchMore?: () => void;
  sortingParameter?: SortableContactProperties;
}

const useAddressBookStyles = makeStyles(() => ({
  letter: {
    margin: `${distances.small}px 0px 0px ${distances.small}px`,
    fontWeight: 700
  }
}));

export const AddressBook = ({
  contacts,
  selectedContacts,
  children,
  onContactClick,
  fetchMore,
  sortingParameter = 'FirstName'
}: AddressBookProps) => {
  const styles = useAddressBookStyles();
  let selectedLetter = '';

  return (
    <SInfiniteScroll
      list={contacts}
      fetchMore={fetchMore}
      keyGetter={element => element.Id}
      render={contact => {
        let renderLetter = false;
        if (
          contact[sortingParameter] &&
          contact[sortingParameter]?.charAt(0).toUpperCase() !== selectedLetter
        ) {
          renderLetter = true;
          selectedLetter = contact[sortingParameter]?.charAt(0).toUpperCase();
        }
        return (
          <>
            {renderLetter && (
              <Typography className={styles.letter}>
                {selectedLetter}
              </Typography>
            )}
            <Contact
              contact={contact}
              checked={selectedContacts?.some(
                con => con.Email.toLowerCase() === contact.Email.toLowerCase()
              )}
              onClick={onContactClick}
            />
          </>
        );
      }}>
      {children}
    </SInfiniteScroll>
  );
};
