// https://github.com/quilljs/quill/issues/163
// quill has empty html tags even when its empty
// need to check for this
export const isQuillEmpty = (value: string) => {
  if (
    !value ||
    (typeof value === 'string' &&
      value.replace(/<(?!img)(.|\n)*?>/g, '').trim().length === 0)
  )
    return true;
  return false;
};

// custom yup method
export function isQuillValid(message: string) {
  // eslint-disable-next-line func-names
  return this.test('isQuillValid', message, function (value: string) {
    const { path, createError } = this;

    if (isQuillEmpty(value)) {
      return createError({ path, message: message ?? 'Required field' });
    }

    return true;
  });
}

export const isOverAllowedLength = (value: string, limit: number) =>
  value.replace(/<(.|\n)*?>/g, '').trim()?.length > limit;

export function notOverAllowedLength(message: string, limit: number) {
  // eslint-disable-next-line func-names
  return this.test('isOverAllowedLength', message, function (value: string) {
    const { path, createError } = this;

    if (value && isOverAllowedLength(value, limit)) {
      return createError({
        path,
        message: message ?? `Exceeded character limit`
      });
    }

    return true;
  });
}
