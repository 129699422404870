import { useMemo, useState, useEffect, useCallback } from 'react';
import { Link } from '@setvi/shared/interfaces';

interface UseReviewLinksProps {
  links: Array<Link>;
  openLinkId?: string;
  cancelLinkId?: () => void;
  handleClose: () => void;
  handleRemoveLink(link?: Link): void;
}

export const useReviewLinks = ({
  links,
  openLinkId,
  cancelLinkId,
  handleClose,
  handleRemoveLink
}: UseReviewLinksProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [link, setLink] = useState<Link>(null);
  const [selectedResourcesIds, setSelectedResourcesIds] = useState([]);

  const selectedResources = useMemo(() => link?.Item?.Items, [link]);

  const handleEdit = (item: Link) => {
    setLink(item);
    setIsExpanded(true);
  };

  const handleBack = () => setIsExpanded(false);

  const handleRemove = useCallback(
    (removeLink?: Link) => {
      // If this is the last link
      if (links.length < 2) {
        handleRemoveLink(removeLink);
        handleClose();
      }
      handleRemoveLink(removeLink);
    },
    [handleRemoveLink, handleClose, links]
  );

  useEffect(() => {
    if (openLinkId && links) {
      setLink(links.find(addedLink => addedLink?.Placeholder === openLinkId));
      setIsExpanded(true);
      cancelLinkId?.();
    } else {
      setLink(null);
      setIsExpanded(false);
    }
  }, [openLinkId, cancelLinkId, links]);

  // Close edit when all links are deleted from editor
  useEffect(() => {
    if (isExpanded && !links.length) handleClose();
  }, [links, isExpanded, handleClose]);

  return {
    isExpanded,
    setIsExpanded,
    link,
    setLink,
    selectedResourcesIds,
    setSelectedResourcesIds,
    selectedResources,
    handleEdit,
    handleBack,
    handleRemove
  };
};
