import { STabs } from '@setvi/shared/components/stabs';
import { SNIPPETS_TAB } from '../content';

interface TabsProps {
  currentTab: number;
  changeTab(newValue: number): void;
}

export const Tabs = ({ currentTab, changeTab }: TabsProps) => {
  const tabsList = [
    {
      label: 'My snippets',
      value: SNIPPETS_TAB.mySnippets
    },
    {
      label: 'Company snippets',
      value: SNIPPETS_TAB.sharedSnippets
    }
  ];

  return (
    <STabs
      variant="fullWidth"
      tabsList={tabsList}
      currentTab={currentTab}
      handleTabChange={changeTab}
    />
  );
};
