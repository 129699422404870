import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { distances } from '@setvi/shared/styles';

const useStyles = makeStyles({
  container: {
    padding: distances.px.small
  },
  btns: {
    display: 'flex',
    borderTop: '1px solid #F1F1F5',
    borderBottom: '1px solid #F1F1F5',
    margin: `${distances.px.medium} 0px`
  },
  btn: {
    margin: `0px ${distances.px.medium}`
  },
  title: {
    margin: `${distances.px.medium} 0px`
  }
});

export const SkeletonSnippets = () => {
  const classes = useStyles();
  return (
    <>
      {Array.from(new Array(5)).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} className={classes.container}>
          <Skeleton className={classes.title} variant="rect" height={20} />
          <Skeleton className={classes.title} variant="rect" height={56} />
          <Box className={classes.btns}>
            <Skeleton
              className={classes.btn}
              variant="rect"
              height={28}
              width={72}
            />
            <Skeleton
              className={classes.btn}
              variant="rect"
              height={28}
              width={72}
            />
          </Box>
        </Box>
      ))}
    </>
  );
};
