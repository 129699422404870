import { useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CrmTypes, getCrmDataQuery } from '@setvi/shared/services';
import {
  GetCrmDataQuery,
  State,
  City
} from '@setvi/shared/services/react-query/query/user/types';
import { useCrmProps } from '../../leads/useLeads';

const STATES_LIMIT = 60;
const CITIES_LIMIT = 30;
export const useLocation = ({ salesforceUser }: useCrmProps) => {
  const [stateSearchTerm, setStateSearchTerm] = useState<string>('');
  const [citySearchTerm, setCitySearchTerm] = useState<string>('');
  const [selectedStateCodes, setSelectedStateCodes] = useState<
    State['BillingStateCode'][]
  >([]);
  const [selectedCities, setSelectedCities] = useState<City[]>([]);

  const stateQueryVariables = useMemo(
    () => ({
      offset: 0,
      pageSize: STATES_LIMIT,
      ownerId: salesforceUser.SalesforceUserID,
      type: CrmTypes.State
    }),
    [salesforceUser.SalesforceUserID]
  );

  const {
    data: states,
    isLoading: statesLoading,
    isFetching: statesFetching
  } = useInfiniteQuery(getCrmDataQuery(stateQueryVariables));

  const cityQueryVariables = useMemo(
    () => ({
      offset: 0,
      pageSize: CITIES_LIMIT,
      ownerId: salesforceUser.SalesforceUserID,
      type: CrmTypes.City,
      state: selectedStateCodes,
      searchTerm: citySearchTerm
    }),
    [selectedStateCodes, citySearchTerm, salesforceUser.SalesforceUserID]
  );

  const {
    data: cities,
    isLoading: citiesLoading,
    isFetching: citiesFetching
  } = useInfiniteQuery(getCrmDataQuery(cityQueryVariables));

  return {
    states: states?.pages
      .map((page: GetCrmDataQuery<State>) => page?.Data?.records)
      .flat(1)
      .filter(
        state =>
          state.BillingStateCode.toLowerCase().includes(
            stateSearchTerm.toLowerCase()
          ) ||
          state.BillingState.toLowerCase().includes(
            stateSearchTerm.toLowerCase()
          )
      ) as State[],
    statesLoading: statesLoading || statesFetching,
    stateSearchTerm,
    setStateSearchTerm,
    selectedStateCodes,
    setSelectedStateCodes,
    cities: (cities?.pages
      .map((page: GetCrmDataQuery<City>) => page?.Data?.records)
      .flat(1) || []) as City[],
    citiesLoading: citiesLoading && citiesFetching,
    citySearchTerm,
    setCitySearchTerm,
    selectedCities,
    setSelectedCities
  };
};
