import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SBase, SText } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { SETVIImage } from 'Components/Shared/SETVIImage';
import ResourceBreadcrumbs from 'Components/Shared/ResourceBreadcrumbs';

export type Informations = {
  label: string;
  value: string;
}[];

interface MoreInfoProps {
  title?: string;
  thumbURL: string;
  comment?: string;
  informations: Informations;
  breadcrumbs?: any;
  disableBreadcrumbs?: boolean;
}

export const useStyles = makeStyles(({ spacing }) => ({
  moreInfo: {
    padding: spacing(4),
    width: 600
  },
  container: {
    marginTop: spacing(3)
  },
  resourceImage: {
    width: '90%',
    objectFit: 'cover',
    borderRadius: 4
  },
  details: {
    display: 'flex',
    alignItems: 'center'
  },
  font: {
    fontSize: 14
  },
  commentSection: {
    wordBreak: 'break-all',
    marginTop: spacing(4),
    maxHeight: 150,
    fontSize: 14,
    overflowY: 'auto'
  },
  breadcrumbs: {
    fontSize: 12,
    color: '#92929D'
  }
}));

export const MoreInfo = ({
  title = 'More Info',
  informations,
  breadcrumbs,
  thumbURL,
  comment,
  disableBreadcrumbs = false
}: MoreInfoProps) => {
  const classes = useStyles();

  const { closeDialog } = useDialog();

  return (
    <SBase
      onClick={e => e.stopPropagation()}
      width={600}
      header={{
        title,
        description: !disableBreadcrumbs && breadcrumbs && (
          <ResourceBreadcrumbs
            additionalOnClick={closeDialog}
            className={classes.breadcrumbs}
            breadcrumbs={breadcrumbs}
          />
        ),
        onClose: closeDialog
      }}>
      <Grid container className={classes.container}>
        <Grid item xs={5}>
          <SETVIImage
            src={thumbURL}
            className={classes.resourceImage}
            alt={title}
          />
        </Grid>
        <Grid item xs={7} className={classes.details}>
          <Grid container direction="column">
            {informations.map(information => (
              <Box key={information.label} height={36} display="flex">
                <Grid item xs={5}>
                  <SText
                    disableTooltip
                    className={classes.font}
                    title={information.label}
                    fontColor="rgba(21, 25, 32, 0.5)"
                  />
                </Grid>
                <Grid item xs={7}>
                  <SText
                    className={classes.font}
                    title={information.value}
                    weight="bold"
                  />
                </Grid>
              </Box>
            ))}
          </Grid>
        </Grid>
        {comment && (
          <>
            <Grid item xs={2} className={classes.commentSection}>
              <Typography className={classes.font} color="textSecondary">
                Comments
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography className={classes.commentSection}>
                {comment}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </SBase>
  );
};
