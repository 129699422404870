import React from 'react';
import { Box, Typography, Divider, IconButton, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

import SETVIImage from '@setvi/shared/components/setvi-image';
import { distances } from '@setvi/shared/styles';

const CenterBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box);

const useStyles = makeStyles({
  container: {
    margin: distances.px.medium
  },
  title: {
    fontWeight: 'bold'
  },
  icon: {
    width: '20px',
    height: '20px'
  },
  closeIcon: {
    width: '25px',
    height: '25px'
  }
});

interface HeaderProps {
  title?: string;
  titleIcon?: JSX.Element;
  hasClose?: boolean;
  handleClose?(): void;
  customCloseIcon?: JSX.Element;
  hideDivider?: boolean;
  useSetviLogo?: boolean;
  disableClose?: boolean;
}

export const Header = ({
  title,
  titleIcon,
  hasClose,
  handleClose,
  customCloseIcon,
  hideDivider,
  useSetviLogo,
  disableClose = false
}: HeaderProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <CenterBox className={classes.container}>
          <Box flexGrow={1}>
            <Grid container spacing={2}>
              <Grid item>
                <CenterBox height="100%">
                  {useSetviLogo && (
                    <SETVIImage src="/images/setvi-logo.png" alt="setvi" />
                  )}

                  {!useSetviLogo && title && (
                    <Typography
                      align="center"
                      variant="body1"
                      className={classes.title}>
                      {title}
                    </Typography>
                  )}
                </CenterBox>
              </Grid>
              {titleIcon && (
                <Grid item>
                  <CenterBox height="100%">{titleIcon}</CenterBox>
                </Grid>
              )}
            </Grid>
          </Box>
          {hasClose && (
            <Box pl={distances.px.small}>
              <IconButton
                onClick={handleClose}
                size="small"
                disabled={disableClose}>
                {customCloseIcon ? (
                  React.cloneElement(customCloseIcon, {
                    className: classes.closeIcon
                  })
                ) : (
                  <Close viewBox="0 0 24 24" className={classes.closeIcon} />
                )}
              </IconButton>
            </Box>
          )}
        </CenterBox>
      </Grid>
      {!hideDivider && (
        <Grid item>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};
