import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';

import { AdminV2GroupsApi, AxiosMethods } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

import { GetAdminGroupsV2Query, getAdminGroupsV2QueryParams } from './Types';

export enum AdminGroupQueryKey {
  ADMIN_GROUPS = 'admin_groups'
}

export const getAdminV2GroupsQuery = (
  params: getAdminGroupsV2QueryParams
): UseInfiniteQueryOptions<GetAdminGroupsV2Query> => ({
  queryKey: [AdminGroupQueryKey.ADMIN_GROUPS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: `${AdminV2GroupsApi.Groups}`,
      method: AxiosMethods.GET,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (
    lastPage: GetAdminGroupsV2Query,
    allPages: GetAdminGroupsV2Query[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.data?.items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.data?.items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});
