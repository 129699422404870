import { Box } from '@material-ui/core';
import { Snippet } from '@setvi/shared/interfaces';
import { EmptySnippets } from '../content/EmptySnippets';
import { Item } from '../item';

interface ListProps {
  setPreviewSnippet(snippet: Snippet): void;
  snippets: Snippet[];
}

export const List = ({ setPreviewSnippet, snippets = [] }: ListProps) => {
  if (snippets.length === 0)
    return (
      <Box height="100%" overflow="hidden">
        <EmptySnippets />
      </Box>
    );

  return snippets.map(i => (
    <Item
      item={i}
      key={i.id}
      handlePreviewClick={(snippet: Snippet) => {
        setPreviewSnippet(snippet);
      }}
    />
  ));
};
