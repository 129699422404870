import { AxiosMethods, ResourceApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';

export enum ResourcesMutationsKey {
  CREATE = 'create_resource',
  CREATE_WEB_LINK = 'create_web_link_resource'
}

export const createResourceMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: ResourceApi.CreateResource,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const createWebLinkResourceMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: ResourceApi.CreateWebLinkResource,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const saveResourceBookmarks = () => ({
  mutationFn: ({ body, params }: any) => {
    const formData = new FormData();
    formData.append('resourceID', body.resourceID);
    formData.append('bookmarks', JSON.stringify(body.bookmarks));
    return axiosHelper({
      endpoint: ResourceApi.SaveResourceBookmarks,
      method: AxiosMethods.POST,
      params,
      body: formData
    });
  },
  select: (res: any) => res
});
