import { FC } from 'react';
import { Box, ListItem, Typography } from '@material-ui/core';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';

export const SkeletonItem: FC = () => (
  <ListItem>
    <Box display="flex" width="100%">
      <MuiSkeleton width={100} height={70} />
      <Box width="100%" ml={2} margin="auto">
        <Typography variant="body1">
          <MuiSkeleton width={80} />
        </Typography>
        <Typography variant="subtitle2">
          <MuiSkeleton width={100} />
        </Typography>
      </Box>
    </Box>
  </ListItem>
);
