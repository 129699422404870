import {
  ResourceType,
  ResourceTypeMime,
  CompanySettingType
} from '@setvi/shared/enums';

import { AdminResourceStatus } from 'Services';
import { CompanySetting } from 'Services/Query/Company/Types';

import { ResourceValuesType } from '../../interfaces';

export const generateResourceBody = (values: ResourceValuesType) => ({
  FileName: values?.Resource?.name || values?.Resource?.filename,
  CategoryId: Number(values?.CategoryId),
  ResourceStatus: values?.Status || AdminResourceStatus.Active,
  ResourceClass: 1,
  ResourceName: values?.Name || values?.Resource?.name,
  Comment: values?.Comment,
  ValidFrom: values?.ValidFromDate,
  ExpireOn: values?.ExpireOnDate,
  IsDownloadable: values?.IsDownloadable,
  IsShareable: values?.IsShareable,
  IsInternallyDownloadable: values?.IsInternallyDownloadable,
  IsRequiredDownload: values?.IsRequiredDownload,
  ResourceTags: values?.Tags?.map(tag => tag?.name),
  ...(values?.ThumbnailUploadId && {
    UploadId: values?.ThumbnailUploadId
  }),
  ...(values?.UploadType && {
    ResourceType: values?.UploadType
  }),
  ResourceCategories: [
    ...(values?.Categories?.length
      ? values?.Categories?.map(category => ({
          ResourceCategoryId: Number(category?.id),
          IsPublic: false
        }))
      : [
          {
            ResourceCategoryId: Number(values?.CategoryId),
            IsPublic: false
          }
        ])
  ]
});

export const getFileExtension = (type: string) => {
  let extension = type;

  if (
    [
      ResourceTypeMime.Video,
      ResourceTypeMime.Audio,
      ResourceTypeMime.Images
    ].some(value => type.includes(value))
  )
    extension = type?.split('/')[0];

  const mimeType =
    Object.keys(ResourceTypeMime).find(
      key =>
        ResourceTypeMime[key as keyof typeof ResourceTypeMime] === extension
    ) || null;

  return ResourceType[mimeType as keyof typeof ResourceType];
};

export const getSettingValue = (
  settings: CompanySetting[],
  type: CompanySettingType
) => {
  const setting = settings?.find(item => item?.companySettingsTypeID === type);

  return !!Number(setting?.value);
};
