import { CSSProperties } from 'react';
import { Grid, Box, Typography, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import { Presentation } from '@setvi/shared/interfaces';
import { ResourceItem as Item } from '@setvi/shared/components/sdrawer/review-links/edit/item/ResourceItem';
import { SkeletonItem } from '@setvi/shared/components/sdrawer/resources/list/skeleton';
import { ClipIcon } from '../../../sicons';
import { Radio } from '../components';

interface IDataProps {
  presentations: Presentation[];
  setSelected: (item: Presentation) => void;
  setIsExpanded: (value: boolean) => void;
  classes: ClassNameMap<string>;
}

interface RowProps {
  data: IDataProps;
  index: number;
  style: CSSProperties;
}

const CenterBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box);

export const Row = ({ data, index, style }: RowProps) => {
  const { presentations, classes, setSelected, setIsExpanded } = data;

  const presentation = presentations[index];
  if (!presentation)
    return (
      <div style={style}>
        <SkeletonItem />
      </div>
    );

  return (
    <div className={classes.itemBorder} style={style} key={presentation.ID}>
      <Box display="flex" height="100%">
        <Item
          disableIcon
          item={{
            Name: presentation.Name,
            ID: presentation.ID,
            ThumbURL: presentation.ThumbURLWithSas
          }}
          itemType={
            <Grid container spacing={1}>
              <Grid item>
                <CenterBox height="100%">
                  <ClipIcon viewBox="0 0 11 11" className={classes.clip} />
                </CenterBox>
              </Grid>
              <Grid item>
                <CenterBox height="100%">
                  <Typography variant="body1" className={classes.bold}>
                    {presentation.PresentationItems.length}
                  </Typography>
                </CenterBox>
              </Grid>
            </Grid>
          }
          itemAction={
            <Radio
              presentation={presentation}
              onChange={() => {
                setSelected(presentation);
                setIsExpanded(true);
              }}
            />
          }
          itemDate={moment.utc(presentation.LastChanged).format('MM/DD/YYYY')}
        />
      </Box>
    </div>
  );
};
