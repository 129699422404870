import { Box, makeStyles } from '@material-ui/core';

import { Snippet } from '@setvi/shared/interfaces';

import SText from '../../../sui/stext';
import { SPreviewEditor } from '../../../seditor/components';
import SButton from '../../../sbutton';

const useStyles = makeStyles({
  editor: {
    height: '100%'
  }
});

interface PreviewProps {
  snippet: Snippet;
  insertMessage(message: string): void;
}

export const Preview = ({ snippet, insertMessage }: PreviewProps) => {
  const classes = useStyles();

  return (
    <Box width="100%" height="100%" position="relative">
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="calc(100% - 84px)">
        <Box p={4} width="100%">
          <SText fontColor="#92929D" title="Subject" weight="bold" size="sm" />
          <SText
            style={{
              marginTop: 6
            }}
            noLineClamp
            fontColor="#44444F"
            weight="bold"
            size="xl">
            {snippet.title}
          </SText>
        </Box>

        <Box paddingX={4} pb={1}>
          <SText fontColor="#92929D" title="Body" weight="bold" size="sm" />
        </Box>

        <Box p={3} paddingTop={0} flex={1}>
          <SPreviewEditor
            height="100%"
            content={snippet.message}
            customClass={classes.editor}
          />
        </Box>
      </Box>

      <Box
        p={4}
        bottom={0}
        width="100%"
        display="flex"
        alignItems="end"
        position="absolute"
        justifyContent="center">
        <SButton
          fullWidth
          onClick={() => insertMessage(snippet.message)}
          style={{
            background: '#23A566',
            borderRadius: 50
          }}>
          Use this
        </SButton>
      </Box>
    </Box>
  );
};
