import { Box, Divider, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const ErrorBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minWidth: 80
  }
})(Box);

export const StyledVerticalDivider = withStyles(({ spacing }) => ({
  root: {
    margin: spacing(0, 1)
  }
}))(Divider);

export const RetryButton = withStyles(({ spacing, palette }) => ({
  root: {
    padding: `0 ${spacing(1)}px`,
    background: 'transparent',
    color: palette.text.secondary
  }
}))(Button);

export const useStyles = makeStyles(theme => ({
  uploadFileIcon: {
    width: 20
  },
  completeIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.success.main
  },
  retryIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  processingIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.warning.dark
  },
  cancelIcon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.text.secondary
  },
  processingStatus: {
    color: theme.palette.warning.dark
  },
  errorStatus: {
    color: theme.palette.error.main
  },
  panelLinearProgress: {
    position: 'fixed',
    bottom: 4,
    right: 16,
    width: 500,
    zIndex: 3
  }
}));

export const useTooltipsStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: 10,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
  },
  arrow: {
    color: theme.palette.common.white,
    '&::before': {
      border: `1px solid ${theme.palette.error.main}`
    }
  }
}));
