import * as Yup from 'yup';
import { ResourceValuesMax } from '../../enum';

export const nameValidation = Yup.object({
  Name: Yup.string()
    .trim()
    .required('Resource Name is required.')
    .max(ResourceValuesMax.Name)
});

export const categoriesValidation = Yup.object({
  Categories: Yup.array(Yup.object()).min(1)
});

export const namesValidation = Yup.object({
  Names: Yup.array()
    .of(
      Yup.string()
        .trim()
        .required('Resource Name is required.')
        .max(ResourceValuesMax.Name)
    )
    .required('Resource Names are required.')
});

export const dynamicNameValidation = (name: string) =>
  Yup.object({
    [name]: Yup.string()
      .trim()
      .required('Resource Name is required.')
      .max(ResourceValuesMax.Name, 'Name must be at most 100 characters.')
  });

export const statusValidation = Yup.object({
  Status: Yup.number().required('Status is required.')
});

export const thumbnailValidation = Yup.object({
  Thumbnail: Yup.string()
});

export const commentValidation = Yup.object({
  Comment: Yup.string().trim().max(ResourceValuesMax.Comment)
});

export const validFromDateValidation = Yup.object({
  ValidFromDate: Yup.date().nullable().min(new Date(), 'Date is too early')
});

export const expireOnDateValidation = Yup.object({
  ExpireOnDate: Yup.date().nullable().min(new Date(), 'Date is too early')
});

export const tagsValidation = Yup.object({
  Tags: Yup.array().of(Yup.object())
});

export const uploadTypeValidation = Yup.object({
  UploadType: Yup.number().required('Upload type is required.')
});
