import { CSSProperties } from 'react';
import { Grid, Box, Typography, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import { Template } from '@setvi/shared/interfaces';
import { ResourceItem as Item } from '@setvi/shared/components/sdrawer/review-links/edit/item/ResourceItem';
import { SkeletonItem } from '@setvi/shared/components/sdrawer/resources/list/skeleton';
import { ClipIcon } from '../../../sicons';
import { Radio } from '../../presentations/components';

interface IDataProps {
  templates: Template[];
  setSelected: (item: Template) => void;
  classes: ClassNameMap<string>;
}

interface RowProps {
  data: IDataProps;
  index: number;
  style: CSSProperties;
}

const CenterBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box);

export const Row = ({ data, index, style }: RowProps) => {
  const { templates, setSelected, classes } = data;

  const template = templates[index];
  if (!template)
    return (
      <div style={style}>
        <SkeletonItem />
      </div>
    );

  return (
    <div className={classes.itemBorder} style={style} key={template.ID}>
      <Box>
        <Item
          disableIcon
          item={{
            Name: template.Name,
            ID: template.ID,
            ThumbURL: template.ThumbURL
          }}
          itemType={
            <Grid container spacing={1}>
              <Grid item>
                <CenterBox height="100%">
                  <ClipIcon viewBox="0 0 11 11" className={classes.clip} />
                </CenterBox>
              </Grid>
              <Grid item>
                <CenterBox height="100%">
                  <Typography variant="body1" className={classes.bold}>
                    {template.Items.length}
                  </Typography>
                </CenterBox>
              </Grid>
            </Grid>
          }
          itemAction={
            <Radio
              presentation={template}
              onChange={() => {
                setSelected(template);
              }}
            />
          }
          itemDate={moment.utc(template.LastChanged).format('MM/DD/YYYY')}
        />
      </Box>
    </div>
  );
};
