import * as Yup from 'yup';

import { CampaignFormLimit } from '@setvi/shared/enums';

export const nameValidation = Yup.object({
  Name: Yup.string()
    .trim()
    .required('Company Campaign Name is required.')
    .max(CampaignFormLimit.Name)
});

export const descriptionValidation = Yup.object({
  Description: Yup.string().max(CampaignFormLimit.Description)
});

export const requiredReadingValidation = Yup.object({
  Description: Yup.string().max(CampaignFormLimit.RequiredReadingForm),
  DocumentName: Yup.string().max(CampaignFormLimit.RequiredReadingForm)
});

export const switchValidation = Yup.object({
  Required: Yup.boolean()
});

export const validUntilDateValidation = Yup.object({
  ValidUntilDate: Yup.date()
    .min(new Date(), 'Date is too early')
    .when('Required', Required => {
      if (Required) return Yup.date().required('Date is required');
      return Yup.date();
    })
});

export const checkboxValidation = Yup.object({
  Checkbox: Yup.boolean()
});

export const subjectValidation = Yup.object({
  Subject: Yup.string()
    // @ts-ignore
    .isQuillValid('Email Subject is required')
    .notOverAllowedLength(
      'Exceeded character limit for Email Subject',
      CampaignFormLimit.Subject
    )
    .required('Email Subject is required')
});

export const bodyValidation = Yup.object({
  HtmlBody: Yup.string()
    // @ts-ignore
    .isQuillValid('Email Body is required')
    .notOverAllowedLength(
      'Exceeded character limit for Email Body',
      CampaignFormLimit.Body
    )
    .required('Email Body is required')
});

export const dateValidation = Yup.object({
  Date: Yup.date()
    .min(new Date(), 'Date is too early')
    .max(new Date(2100, 0, 1), 'Date is too late')
    .when('Required', Required => {
      if (Required) return Yup.date().required('Date is required');
      return Yup.date();
    })
});
