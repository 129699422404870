import { TContact } from '@setvi/shared/components/sdrawer/contacts/contact';
import { Box, Typography } from '@material-ui/core';
import { DrawerContent } from '../../../enums';
import { useRecentContacts } from './useRecentContacts';
import { Header } from '../header';
import { BaseSubheader } from '../base/subheader';
import { SSearchInput } from '../../sforms';
import { AddressBook } from '../contacts/address-book';
import { SEmptyState } from '../..';
import { ContactFilter } from '../contacts/contact-filter';
import { Recipient } from '../../../interfaces';

interface RecentContactsProps {
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

export const RecentContacts = ({
  selectedContacts,
  modifyRecipientList,
  setShowContent,
  handleClose
}: RecentContactsProps) => {
  const {
    setSearchString,
    sortBy,
    setSortBy,
    recentContacts,
    isLoading,
    fetchMoreRecentContacts
  } = useRecentContacts();

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Recipients" hasClose handleClose={handleClose} />
      <BaseSubheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="Recent Contacts"
        bottomDivider
      />
      <Box p={2} mb={1} display="flex">
        <SSearchInput onChange={value => setSearchString(value)} />
        <ContactFilter sortBy={sortBy} setSortBy={setSortBy} />
      </Box>
      <Box overflow="auto">
        <AddressBook
          contacts={recentContacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreRecentContacts}
          onContactClick={modifyRecipientList}
          sortingParameter={sortBy}>
          <>
            {isLoading && (
              <Box textAlign="center">
                <Typography variant="h6">Loading...</Typography>
              </Box>
            )}
            {!isLoading && recentContacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-recent-contacts.png"
                subTitle="No Recent Contacts"
              />
            )}
          </>
        </AddressBook>
      </Box>
    </Box>
  );
};
