import { useMemo } from 'react';
import { Box } from '@material-ui/core';

import { AuxProps } from '@setvi/shared/interfaces';

import { useUpload } from './hooks';
import { UploadResourcePanelContext } from './context';
import { ExpandedWrapper, UploadContainer } from './style';
import { Minimazed, Header, FilestackUploadItem } from './components';

enum PanelDimensions {
  MAX_HEIGHT_EXPANDED = 400,
  MAX_HEIGHT_MINIMIZED = 50,
  MAX_WIDTH = 400
}

export const UploadResourcePanelProvider = ({ children }: AuxProps) => {
  const {
    title,
    offset,
    expanded,
    showPanel,
    headerElements,
    minmizedElements,
    uploadedResources,
    open,
    close,
    setOffset,
    onUploadCancel,
    onUploadFinished
  } = useUpload();

  const contextValue = useMemo(
    () => ({ setOffset, open, close }),
    [setOffset, open, close]
  );

  return (
    <UploadResourcePanelContext.Provider value={contextValue}>
      {children}

      {showPanel && (
        <UploadContainer
          maxHeight={
            expanded
              ? PanelDimensions.MAX_HEIGHT_EXPANDED
              : PanelDimensions.MAX_HEIGHT_MINIMIZED
          }
          maxWidth={PanelDimensions.MAX_WIDTH}
          right={offset}>
          <Minimazed
            title={title}
            elements={minmizedElements}
            display={!expanded ? 'flex' : 'none'}
          />

          <ExpandedWrapper display={expanded ? 'flex' : 'none'}>
            <Header title={title} elements={headerElements} />
            <Box flex={1} mt={2} overflow="auto">
              {uploadedResources.map(resource => (
                <FilestackUploadItem
                  key={resource.id}
                  id={resource?.id}
                  file={resource.file}
                  error={resource.error}
                  status={resource.status}
                  unzipedTotal={resource.unzipedTotal}
                  unzipedProcesed={resource.unzipedProcesed}
                  unzippedResources={resource.unzippedResources}
                  onUploadCancel={onUploadCancel}
                  onUploadFinished={onUploadFinished}
                />
              ))}
            </Box>
          </ExpandedWrapper>
        </UploadContainer>
      )}
    </UploadResourcePanelContext.Provider>
  );
};
