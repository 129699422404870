import { ReactNode } from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
  Tooltip,
  TooltipProps,
  withStyles
} from '@material-ui/core';

const Button = withStyles({
  root: {
    minWidth: 'max-content',
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  }
})(MuiButton);

interface SButtonProps extends ButtonProps {
  children: ReactNode;
  loading?: boolean;
  className?: string;
  tooltipTitle?: string;
  tooltipPlacment?: TooltipProps['placement'];
}

const SButton = ({
  loading,
  onClick,
  disabled,
  children,
  className,
  color = 'primary',
  variant = 'contained',
  tooltipTitle = '',
  tooltipPlacment = 'top',
  fullWidth,
  ...rest
}: SButtonProps) => {
  const disabledConfiguration = {
    disabled: disabled || loading,
    component: disabled ? 'div' : undefined,
    onClick: disabled ? undefined : onClick
  };

  return (
    <Tooltip arrow title={tooltipTitle} placement={tooltipPlacment}>
      <Button
        startIcon={
          loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            rest.startIcon
          )
        }
        color={color}
        variant={variant}
        className={className}
        fullWidth={fullWidth}
        {...rest}
        {...disabledConfiguration}>
        {children}
      </Button>
    </Tooltip>
  );
};
export default SButton;
