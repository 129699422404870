import { useCallback } from 'react';
import { Resource } from '@setvi/shared/interfaces';
import { Box } from '@material-ui/core';
import { useStyles } from '../../list/style';
import SInfiniteScroll from '../../../sinfinite-scroll';
import SemptyState from '../../../sempty-state';
import { Item } from '../item';
import SText from '../../../sui/stext';
import { ResourceType } from '../../../../enums';

interface ListProps {
  isStatic?: boolean;
  resources: Resource[];
  fetchMore: () => void;
  rowKeyGetter: (data: any) => number | string;
  selectedResources?: Resource[];
  onSelect: (checked: boolean, resources: any) => void;
  onSelectPages: (resource: Resource) => void;
  isCheckboxLoading?: (resource: Resource) => boolean;
  loading: boolean;
  isEmpty: boolean;
  emptyStateInfo: {
    imageSrc: string;
    title: string;
    subTitle?: string;
  };
}

export const List = ({
  isStatic = false,
  resources,
  fetchMore,
  rowKeyGetter,
  loading,
  isEmpty,
  emptyStateInfo,
  selectedResources,
  onSelect,
  onSelectPages,
  isCheckboxLoading
}: ListProps) => {
  const classes = useStyles();

  const isChecked = useCallback(
    (resource: Resource) => {
      const hasSubmenu = [
        ResourceType.Presentation,
        ResourceType.PdfDocument
      ].includes(resource?.ResourceTypeID);

      // #region this is checking if pdf/ppt file is checked
      if (hasSubmenu) {
        const entireFileSelected = selectedResources?.some(
          i => rowKeyGetter(i) === rowKeyGetter(resource)
        );
        const somePageSelected = selectedResources?.some(
          i =>
            `${resource?.ResourceID}-${resource?.CategoryID}` ===
            `${i?.ResourceID?.toString()?.slice(0, -3)}-${resource?.CategoryID}`
        );

        return entireFileSelected || somePageSelected;
      }
      // #endregion

      return selectedResources?.some(
        i => rowKeyGetter(i) === rowKeyGetter(resource)
      );
    },
    [selectedResources, rowKeyGetter]
  );

  return (
    <SInfiniteScroll
      containerClass={classes.container}
      list={resources}
      fetchMore={fetchMore}
      keyGetter={rowKeyGetter}
      render={(resource: any) => (
        <Item
          isStatic={isStatic}
          resource={resource}
          onSelect={onSelect}
          onSelectPages={onSelectPages}
          isChecked={isChecked(resource)}
          isLoading={isCheckboxLoading?.(resource)}
        />
      )}>
      <>
        {loading && (
          <Box display="flex" justifyContent="center">
            <SText weight="medium">Loading...</SText>
          </Box>
        )}
        {!loading && isEmpty && (
          <SemptyState
            imageSrc={emptyStateInfo.imageSrc}
            title={emptyStateInfo.title}
            subTitle={emptyStateInfo.subTitle}
          />
        )}
      </>
    </SInfiniteScroll>
  );
};
