import { makeStyles } from '@material-ui/core';

export const useSQuadrantStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  grid: {
    flexGrow: 1,
    height: '100%'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0
  },
  left: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  },
  topConatiner: {
    padding: theme.spacing(2),
    width: '100%',
    height: 75,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  }
}));
