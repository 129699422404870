import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  RefetchDataProps,
  STableProps,
  SortInterface,
  queryVariablesType
} from '../../../interfaces';
import { SortType } from '../../../enums';

interface OnPaginationChangeProps {
  newPage?: number;
  pageSize?: number;
}

type UseGetDataProps<T> = Pick<STableProps<T>, 'query' | 'queryVariables'>;

export const usePaginatedData = <T>({
  query,
  queryVariables
}: UseGetDataProps<T>) => {
  const [tablePage, setTablePage] = useState(queryVariables?.page || 1);
  const [tableSize, setTableSize] = useState(queryVariables?.size || 10);
  const [tableSearch, setTableSearch] = useState(queryVariables?.search || '');
  const [sortedColumn, setSortedColumn] = useState<SortInterface>({
    sortBy: queryVariables?.sortBy || undefined,
    orderBy: queryVariables?.orderBy || SortType.ASC
  });
  const [totalCount, setTotalCount] = useState(0);

  const inputData: queryVariablesType = useMemo(
    () => ({
      ...queryVariables,
      page: tablePage,
      size: tableSize,
      search: tableSearch?.trim() === '' ? undefined : tableSearch?.trim(),
      sortBy: sortedColumn.sortBy,
      orderBy: sortedColumn.orderBy
    }),
    [queryVariables, tablePage, tableSize, sortedColumn, tableSearch]
  );

  const {
    data: queryData,
    isLoading: loading,
    isFetching
  } = useQuery({ ...query(inputData) });

  const objectKey = useMemo(
    () => queryData && (Object.keys(queryData)[0] as string),
    [queryData]
  );

  const data = useMemo(
    () => queryData && queryData[objectKey as keyof typeof queryData],
    [queryData, objectKey]
  );

  const refetchData = useCallback(
    ({ pageSize = tableSize, newPage }: RefetchDataProps) => {
      pageSize !== tableSize && setTableSize(pageSize);
      tablePage !== newPage && setTablePage(newPage);
    },
    [tableSize, tablePage]
  );

  const onPaginationChange = useCallback(
    ({ pageSize, newPage }: OnPaginationChangeProps) => {
      refetchData({
        pageSize,
        newPage,
        input: {
          ...(sortedColumn.sortBy.length ? sortedColumn : {})
        }
      });
    },
    [refetchData, sortedColumn]
  );

  const onSortChange = useCallback(
    (columnForSort: string) => {
      const existSorted =
        sortedColumn.orderBy === SortType.ASC ? SortType.DESC : SortType.ASC;
      const overrideInput = {
        sortBy: columnForSort,
        orderBy:
          columnForSort === sortedColumn.sortBy ? existSorted : SortType.ASC
      };

      setTablePage(1);
      setSortedColumn(overrideInput);
    },
    [sortedColumn]
  );

  useEffect(() => {
    const { page, search, sortBy, orderBy } = queryVariables;
    setTableSearch(search || '');
    setTablePage(page || 1);
    setTotalCount(0);
    setSortedColumn({
      sortBy: sortBy || undefined,
      orderBy: orderBy || SortType.ASC
    });
  }, [queryVariables]);

  useEffect(() => {
    if (!queryData) return;
    // @ts-ignore
    setTotalCount(queryData?.TotalCount || queryData?.Count || 0);
  }, [queryData]);

  return {
    data,
    tablePage,
    loading: loading || isFetching,
    tableSearch,
    tableSize,
    sortedColumn,
    totalCount,
    onSortChange,
    onPaginationChange
  };
};
