import { LinkObjectType } from '../../enums';
import { Breadcrumb } from '../categories';

export interface ChildResource {
  ID: number;
  Name?: string;
  CategoryID?: number;
  ResourceTypeID: Resource['ResourceTypeID'];
  ResourceID: number;
  Material: string;
  ItemID: string;
  ThumbURL: string;
  ThumbURLWithSas: string;
  DownloadURL: string;
  SortOrder: number;
  DownloadURLWithSas: string;
}

export interface Resource {
  Key?: string;
  ID: number;
  ResourceID: string;
  Name: string;
  MaterilaLastChangeDate?: string;
  CategoryID?: number;
  CategoryName?: string | null;
  DateAdded?: string;
  ResourceTypeID: number;
  IsDownloadable?: boolean;
  IsShareable?: boolean;
  ResourceTypeName?: string | null;
  CompanyID?: number;
  Active?: number;
  ValidFromDate?: string;
  ExpireOnDate?: string | null;
  MaterialSize?: number;
  Comment?: string;
  ResourceLastChangedDate?: string | null;
  ResourceClass?: ResourceCategoryClass;
  UserID?: number;
  IsArchived?: boolean;
  ArchivedTime?: string;
  FileName?: string;
  BoxV2Id?: string;
  isInternallyDownloadable?: boolean;
  Pages?: number;
  PageNumber?: number;
  IsPublic?: boolean;
  ThumbURL?: string;
  ThumbURLWithSas?: string;
  DownloadURL?: string;
  DownloadURLWithSas?: string;
  IsFavorite?: boolean;
  ItemID?: string;
  Breadcrumbs?: Breadcrumb[];
  ChildResources?: ChildResource[];
  ObjectType?: LinkObjectType;
}

export enum ResourceCategoryClass {
  RESOURCES = 1,
  MY_RESOURCES = 2
}

export interface Resources {
  Data: {
    Result: Resource[];
    Count: number;
  };
}

export interface ResourceBookmark {
  BookmarkTitle: string;
  DateAdded: string;
  DateModified: string | null;
  ID: number;
  PageNumber: number;
  ResourceID: string;
  UserID: number;
}
