import { makeStyles, Theme } from '@material-ui/core';
import { maxLinesType } from '..';

export type LineHeightType = keyof typeof LineHeight;

enum LineHeight {
  sm = 1,
  base = 'normal',
  md = 1.5,
  lg = 2
}

interface STextStyles {
  maxLines: maxLinesType;
  fontColor: string;
  lineHeight: LineHeightType;
  maxWidth?: number;
}

export const sTextStyles = makeStyles<Theme, STextStyles>({
  text: {
    width: 'fit-content',
    maxWidth: ({ maxWidth }) => maxWidth || 'none'
  },
  whiteSpace: {
    whiteSpace: 'pre-wrap'
  },
  customTooltip: {
    maxHeight: '75vh',
    maxWidth: '75vw'
  },
  lineClamp: {
    overflow: 'hidden',
    lineHeight: ({ lineHeight }) => LineHeight[lineHeight],
    '-webkit-box-orient': 'vertical',
    wordBreak: ({ maxLines }) =>
      Number(maxLines) > 1 ? 'break-word' : 'break-all',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    color: ({ fontColor }) => fontColor,
    '-webkit-line-clamp': ({ maxLines }) => maxLines
  },
  noWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis'
  },
  xs: {
    fontSize: '0.75rem'
  },
  sm: {
    fontSize: '0.875rem'
  },
  base: {
    fontSize: '1rem'
  },
  lg: {
    fontSize: '1.125rem'
  },
  xl: {
    fontSize: '1.25rem'
  },
  '2xl': {
    fontSize: '1.5rem'
  },
  '3xl': {
    fontSize: '1.875rem'
  },
  '4xl': {
    fontSize: '2.25rem'
  },
  '5xl': {
    fontSize: '3rem'
  },
  thin: {
    fontWeight: 100
  },
  light: {
    fontWeight: 300
  },
  normal: {
    fontWeight: 400
  },
  medium: {
    fontWeight: 500
  },
  semibold: {
    fontWeight: 600
  },
  bold: {
    fontWeight: 700
  }
});
