import { useCallback } from 'react';
import { Box, Divider, Button } from '@material-ui/core';
import { SConfirmDialog } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { Link } from '@setvi/shared/interfaces';
import { Content } from '@setvi/shared/components/sdrawer/base';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { LinkItem } from './LinkItem';
import { Container, ButtonContainer } from './style';

interface ListProps {
  links: Array<Link>;
  handleEdit: (item: Link) => void;
  handleBack?: () => void;
  handleRemoveLink(link?: Link): void;
  handleClose: () => void;
}

export const List = ({
  links,
  handleEdit,
  handleBack: _handleBack,
  handleRemoveLink,
  handleClose
}: ListProps) => {
  const { openDialog, closeDialog } = useDialog();

  const onDelete = useCallback(
    (linkItem: Link) => {
      openDialog(
        <SConfirmDialog
          type="delete"
          title="Delete link"
          description="Are you sure you want to delete this link from the email?"
          additionalMessage="The action CANNOT be reverted back"
          onClose={() => closeDialog('delete-link')}
          onConfirm={() => {
            handleRemoveLink(linkItem);
            closeDialog('delete-link');
          }}
          confirmText="Delete & Close"
          closeText="Keep it"
        />,
        { id: 'delete-link' }
      );
    },
    [openDialog, closeDialog, handleRemoveLink]
  );

  return (
    <Content
      header={
        <Header
          title="Edit & Rename Link(s)"
          hasClose
          handleClose={handleClose}
        />
      }>
      <Container>
        <Box>
          {links?.map(item => (
            <Box key={item.Placeholder}>
              <LinkItem
                link={item}
                onEdit={() => handleEdit(item)}
                onDelete={() => onDelete(item)}
              />
              <Divider />
            </Box>
          ))}
        </Box>
        <ButtonContainer>
          <Button type="button" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </ButtonContainer>
      </Container>
    </Content>
  );
};
