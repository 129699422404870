import { Box } from '@material-ui/core';
import { GroupUsersIcon } from '@setvi/shared/components/sicons';
import { Resources as ResourcesIcon } from 'Components/Shared/Icons';
import { ROUTES } from 'enumsV2';
import { MenuItemProps } from '../..';

enum FLAGS {
  RESOURCES = 'adminResources',
  COMPANY_CAMPAIGNS = 'adminCompanyCampaign'
}

export const AdminLabel = ({
  collapsedDrawer
}: {
  collapsedDrawer: boolean;
}) => (
  <Box
    bgcolor="#F1F1F5"
    fontWeight={700}
    color="#92929D"
    py={2}
    px={3}
    sx={{
      textAlign: collapsedDrawer ? 'center' : 'left'
    }}>
    ADMIN
  </Box>
);

export const adminMenuItems: MenuItemProps[] = [
  {
    title: 'Resources',
    flag: FLAGS.RESOURCES,
    icon: <ResourcesIcon />,
    route: ROUTES.ADMIN_RESOURCES,
    baseRoute: ROUTES.ADMIN_RESOURCES
  },
  {
    title: 'Company Campaigns',
    flag: FLAGS.COMPANY_CAMPAIGNS,
    icon: <GroupUsersIcon />,
    route: ROUTES.ADMIN_CAMPAIGNS,
    baseRoute: ROUTES.ADMIN_CAMPAIGNS
  }
];
