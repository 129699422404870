import {
  Resource,
  PresentationItem,
  TemplateItem,
  LinkItemItems,
  AlgoliaHit,
  AlgoliaHitBreadcrumb
} from '@setvi/shared/interfaces';
import {
  ResourceType,
  ResourceIcon,
  ResourceTypeMime,
  ResourceName,
  LinkObjectType
} from '../../enums';

// TODO: Remove all these remapping functions since they only add unnecessary complexity and problems which are propagated to the rest of the codebase
export const getLinkItemFromResource = (item: Resource): LinkItemItems => ({
  ID: item.ID,
  ResourceID: item.ResourceID,
  Name: item.Name,
  ThumbURL: item.ThumbURLWithSas || item.ThumbURL,
  ResourceTypeID: item.ResourceTypeID,
  ResourceTypeName: item.ResourceTypeName,
  LastChangedDate: item.ResourceLastChangedDate,
  IsShareable: item.IsShareable,
  CategoryID: item?.CategoryID,
  ObjectType:
    item?.ObjectType ||
    (item.Key === 'child'
      ? LinkObjectType.ResourceChild
      : LinkObjectType.Resources),
  Children: item.ChildResources?.map(child => ({
    ID: child.ID,
    ItemID: child.ItemID,
    CategoryID: item?.CategoryID,
    ResourceID: child.ResourceID.toString(),
    Name: child.Material,
    ThumbURL: child.ThumbURLWithSas,
    ResourceTypeID: item.ResourceTypeID,
    ResourceTypeName: item.ResourceTypeName,
    LastChangedDate: item.ResourceLastChangedDate,
    IsShareable: item.IsShareable,
    ObjectType: LinkObjectType.ResourceChild
  }))
});

export const getLinkItemFromPresentationItem = (
  item: PresentationItem
): LinkItemItems => ({
  ID: item.ID,
  ItemID: item.ItemID,
  Name: item.Name,
  ThumbURL: item.ThumbURLWithSas,
  ResourceTypeID: item.ResourceTypeID,
  ResourceTypeName: item.ResourceTypeName,
  LastChangedDate: null,
  IsShareable: item.IsShareable,
  Children: [],
  ObjectType: LinkObjectType.Presentation
});

export const getLinkItemFromTemplateItem = (
  item: TemplateItem
): LinkItemItems => ({
  ID: item.ID,
  ItemID: item.ItemID,
  Name: item.Name,
  ThumbURL: item.ThumbURLWithSas,
  ResourceTypeID: item.ResourceTypeID,
  ResourceTypeName: item.ResourceTypeName,
  LastChangedDate: null,
  IsShareable: item.IsShareable,
  Children: [],
  ObjectType: LinkObjectType.Presentation
});

export const GetResourceTypeIcon = (resourceTypeID: number) =>
  ResourceIcon[ResourceType[resourceTypeID] as keyof typeof ResourceIcon];

export const GetResourceTypeIdFromMime = (mimeType: string) => {
  const key = Object.values(ResourceTypeMime).find((v: string) =>
    mimeType.includes(v)
  );

  return Object.keys(ResourceType)[
    Object.values(ResourceTypeMime).indexOf(key)
  ];
};

export const GetResourceTypeName = (resourceTypeID: number) =>
  ResourceName[ResourceType[resourceTypeID] as keyof typeof ResourceName];

export const getResourceFromAlgoliaHit = (
  hit: AlgoliaHit,
  sasToken: string
): Resource => ({
  ID: hit.id,
  ItemID: hit.id.toString(),
  Name: hit.name,
  ThumbURL: `${hit.thumbURL}${sasToken && sasToken}`,
  ResourceID: hit.resourceID,
  ResourceTypeID: hit.resourceTypeID,
  ResourceTypeName: hit.resourceTypeName,
  ResourceLastChangedDate: hit.resourceLastChangedDate,
  IsShareable: hit.isShareable,
  IsFavorite: false,
  CategoryName: hit.categoryName,
  CategoryID: hit.categoryID,
  Comment: hit.comment,
  CompanyID: hit.companyID,
  FileName: hit.fileName,
  DownloadURLWithSas: `${hit.downloadURL}${sasToken}`,
  isInternallyDownloadable: hit.isInternallyDownloadable,
  IsDownloadable: hit.isInternallyDownloadable,
  Pages: hit.pages,
  PageNumber: hit.pageNumber,
  BoxV2Id: hit?.boxV2Id,
  Breadcrumbs: hit.breadcrumbs.map((breadcrumb: AlgoliaHitBreadcrumb) => ({
    ID: breadcrumb.id,
    Name: breadcrumb.name,
    ResourceCategoryClass: breadcrumb.resourceCategoryClass
  })),
  ChildResources: []
});
