import { Header } from '@setvi/shared/components/sdrawer/header';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { DrawerContent } from '@setvi/shared/enums/common';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { AddressBook } from '@setvi/shared/components/sdrawer/contacts/address-book';
import { TContact } from '@setvi/shared/components/sdrawer/contacts/contact';
import { useContacts } from '@setvi/shared/components/sdrawer/contacts/useContacts';
import { Box, Typography } from '@material-ui/core';
import { SEmptyState } from '../..';
import { ContactFilter } from './contact-filter';
import { Recipient } from '../../../interfaces';

interface ContactsProps {
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

export const Contacts = ({
  selectedContacts,
  modifyRecipientList,
  setShowContent,
  handleClose
}: ContactsProps) => {
  const {
    contacts,
    isLoading,
    setSearchString,
    fetchMoreContacts,
    sortBy,
    setSortBy
  } = useContacts();

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Recipients" hasClose handleClose={handleClose} />
      <BaseSubheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="Contacts"
        bottomDivider
      />
      <Box p={2} mb={1} display="flex">
        <SSearchInput onChange={value => setSearchString(value)} />
        <ContactFilter sortBy={sortBy} setSortBy={setSortBy} />
      </Box>
      <Box overflow="auto">
        <AddressBook
          contacts={contacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreContacts}
          onContactClick={modifyRecipientList}>
          <>
            {isLoading && (
              <Box textAlign="center">
                <Typography variant="h6">Loading...</Typography>
              </Box>
            )}
            {!isLoading && contacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-contacts-2.png"
                subTitle="No contacts available"
              />
            )}
          </>
        </AddressBook>
      </Box>
    </Box>
  );
};
