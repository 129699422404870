import { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import ClearIcon from '@material-ui/icons/Clear';

import { ExpandIcon } from '@setvi/shared/components/sicons';
import { RESOURCE_UPLOAD_STATUS } from '@setvi/shared/enums';

import {
  AdminResroucesQueryKey,
  adminResourceUploadFinishedMutation,
  getAdminResourcesProcessStatusesQuery
} from 'Services';
import { useSubscribedMutation } from 'Hooks/React-Query';
import { UploadedResource } from 'Services/Query/Admin/Resources/Types';

import { UploadResourcePanelProps } from '../context';

export interface IFile extends File {
  mimetype?: string;
  filename?: string;
}
export interface UploadResourceProps {
  id: number;
  file: IFile;
  status: RESOURCE_UPLOAD_STATUS;
  error?: string;
  unzipedTotal?: number;
  unzipedProcesed?: number;
  unzippedResources?: UploadedResource[];
}

export const useUpload = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [showPanel, setShowPanel] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [offset, setOffset] = useState(0);

  const [uploadedResources, setUploadedResources] = useState<
    UploadResourceProps[]
  >([]);
  const queryVariables = useMemo(
    () => uploadedResources?.map(({ id }) => id),
    [uploadedResources]
  );

  const triggerAPI = useMemo(() => {
    if (!uploadedResources?.length) return false;

    const processed = uploadedResources?.filter(res =>
      [
        RESOURCE_UPLOAD_STATUS.ERROR,
        RESOURCE_UPLOAD_STATUS.PROCESSED,
        RESOURCE_UPLOAD_STATUS.BULK_PROCESSED
      ].includes(res?.status)
    )?.length;

    if (processed === uploadedResources.length) return false;

    return true;
  }, [uploadedResources]);

  const { data, isFetching } = useQuery({
    ...getAdminResourcesProcessStatusesQuery(queryVariables),
    enabled: triggerAPI,
    refetchInterval: 4000
  });

  // With this we update the status of the resources and check if it has an error
  useEffect(() => {
    if (!data || isFetching) return;

    setUploadedResources(prev =>
      prev?.map(resource => {
        const sameResource = data.find(
          ({ id }: { id: number }) => id === resource.id
        );

        let unzipedProcesed = 0;
        let unzipedCompleted;
        let oldUnzipedCompleted;
        if (sameResource?.unzippedResources?.length) {
          unzipedProcesed = sameResource?.unzippedResources?.filter(
            (res: UploadedResource) =>
              [
                RESOURCE_UPLOAD_STATUS.PROCESSING,
                RESOURCE_UPLOAD_STATUS.PROCESSED
              ].includes(res?.processing)
          )?.length;

          unzipedCompleted = sameResource?.unzippedResources?.filter(
            (res: UploadedResource) =>
              res?.processing === RESOURCE_UPLOAD_STATUS.PROCESSED
          );

          oldUnzipedCompleted = resource?.unzippedResources?.filter(
            (res: UploadedResource) =>
              res?.processing === RESOURCE_UPLOAD_STATUS.PROCESSED
          );
        }

        const unzipedTotal = sameResource?.unzippedResources?.length || 0;
        const status =
          sameResource?.processing === RESOURCE_UPLOAD_STATUS.BULK_UNPACKED &&
          unzipedCompleted?.length === unzipedTotal
            ? RESOURCE_UPLOAD_STATUS.PROCESSED
            : sameResource?.processing || resource?.status;

        if (
          (sameResource?.processing === RESOURCE_UPLOAD_STATUS.PROCESSED &&
            sameResource?.processing !== resource?.status) ||
          unzipedCompleted?.length !== oldUnzipedCompleted?.length
        ) {
          queryClient.invalidateQueries({
            queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCES]
          });

          enqueueSnackbar(`Resource upload completed.`, {
            variant: 'success'
          });
        }

        return {
          ...resource,
          error: sameResource?.errorMessage || resource?.error,
          status,
          unzipedTotal,
          unzipedProcesed,
          unzippedResources: sameResource?.unzippedResources || []
        };
      })
    );
  }, [data, enqueueSnackbar, isFetching, queryClient]);

  const { mutateAsync: uploadFinished } = useSubscribedMutation(
    adminResourceUploadFinishedMutation(),
    [AdminResroucesQueryKey.ADMIN_RESOURCES_PROCESS_STATUS]
  );

  const open = useCallback(({ resources }: UploadResourcePanelProps) => {
    setShowPanel(true);
    if (resources) setUploadedResources(prev => [...prev, ...resources]);
  }, []);

  const close = () => {
    setShowPanel(false);
    setExpanded(false);
    setUploadedResources([]);
  };

  const minmizedElements = [
    {
      key: '1',
      icon: (
        <ExpandIcon
          style={{
            transform: 'scale(0.6)'
          }}
        />
      ),
      onClick: () => setExpanded(true)
    },
    {
      key: '2',
      icon: <ClearIcon htmlColor="#fff" />,
      onClick: () => close()
    }
  ];

  const headerElements = [
    {
      key: '1',
      icon: <RemoveRoundedIcon />,
      onClick: () => setExpanded(false)
    },
    {
      key: '2',
      icon: <ClearIcon />,
      onClick: () => close()
    }
  ];

  const onUploadFinished = async (resourceId: number, filestackId: string) => {
    setUploadedResources(prev => {
      const updatedData = prev.map(item => {
        if (item.id === resourceId) {
          return {
            ...item,
            status: RESOURCE_UPLOAD_STATUS.UPLOADING
          };
        }
        return item;
      });
      return updatedData;
    });

    await uploadFinished({
      resources: [
        {
          resourceId,
          filestackId
        }
      ]
    });
  };

  const onUploadCancel = (resourceId: number) => {
    setUploadedResources(prev => {
      const updatedData = prev.map(item => {
        if (item.id === resourceId) {
          return {
            ...item,
            status: RESOURCE_UPLOAD_STATUS.ERROR,
            error: 'Upload canceled by user.'
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const title = useMemo(() => {
    const total = uploadedResources?.length || 0;
    const completed = uploadedResources?.filter(res =>
      [
        RESOURCE_UPLOAD_STATUS.PROCESSED,
        RESOURCE_UPLOAD_STATUS.BULK_PROCESSED
      ].includes(res?.status)
    )?.length;

    if (!total) setShowPanel(false);

    return `Upload Resources (${completed}/${total})`;
  }, [uploadedResources]);

  return {
    title,
    offset,
    expanded,
    showPanel,
    headerElements,
    minmizedElements,
    uploadedResources,

    open,
    close,
    setOffset,
    onUploadCancel,
    onUploadFinished
  };
};
