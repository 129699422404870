import { Box, makeStyles } from '@material-ui/core';
import Text from '@setvi/shared/components/sui/stext';
import NoFilesFoundIcon from './icons/NoFilesFoundIcon';

export const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorImage: {
    width: '100%'
  }
});

export const EmptyResources = () => {
  const classes = useStyles();
  return (
    <Box height="100%" className={classes.center}>
      <Box my={2} className={`${classes.center} ${classes.errorImage}`}>
        <NoFilesFoundIcon />
        <Text fontColor="#B6BFCC">You have no resources available.</Text>
      </Box>
      <Text fontColor="#B6BFCC" weight="bold">
        No Files
      </Text>
    </Box>
  );
};
