import { useCallback, useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Divider, IconButton, useTheme } from '@material-ui/core';

import { GetResourceTypeIcon } from '@setvi/shared/utils';
import { useFilestack } from '@setvi/shared/hooks';

import { getFileExtension } from 'pages/admin/pages/resources/pages/form/utils';
import { UploadResourceProps } from 'providersV2/upload-resource-panel/hooks';

import UploadItem from '../item';
import { ResourceHeader, ResourceUploadStatus } from '../item/components';

interface FilestackUploadItemProps extends UploadResourceProps {
  onUploadFinished?: (resourceId: number, filestackId: string) => void;
  onUploadCancel?: (resourceId: number) => void;
}

const FilestackUploadItem = ({
  id,
  file,
  error,
  status,
  unzipedTotal,
  unzipedProcesed,
  unzippedResources,
  onUploadCancel,
  onUploadFinished
}: FilestackUploadItemProps) => {
  const { spacing } = useTheme();
  const { filestackToken, fileStackUpload } = useFilestack();

  const [openUnziped, setOpenUnziped] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);

  const handleUpload = useCallback(
    async () => {
      const res = await fileStackUpload({
        file,
        tags: {
          resourceId: id?.toString()
        },
        error: {
          show: false
        },
        getProgress: (total: number) => setProgress(total)
      });

      if (res) {
        setProgress(100);

        setTimeout(() => {
          setProgress(null);
        }, 300);

        if (res?.handle) onUploadFinished?.(id, res.handle);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, file]
  );

  useEffect(() => {
    handleUpload();
  }, [handleUpload]);

  const onUploadRetry = useCallback(() => {
    handleUpload();
  }, [handleUpload]);

  const handleUploadCancel = useCallback(() => {
    filestackToken?.cancel?.();
    onUploadCancel?.(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filestackToken]);

  return (
    <UploadItem
      header={
        <ResourceHeader
          name={file?.name || file?.filename}
          icon={GetResourceTypeIcon(
            getFileExtension(file?.type || file?.mimetype)
          )}
          adornment={
            !!unzippedResources?.length && (
              <IconButton
                onClick={() => setOpenUnziped(prev => !prev)}
                style={{
                  padding: 0,
                  marginLeft: -4,
                  transition: 'transform 0.3s ease-in-out',
                  transform: `rotate(${openUnziped ? 180 : 0}deg)`
                }}>
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            )
          }
        />
      }
      body={
        <ResourceUploadStatus
          error={error}
          status={status}
          progress={progress}
          unzipedTotal={unzipedTotal}
          unzipedProcesed={unzipedProcesed}
          onUploadRetry={onUploadRetry}
          onUploadCancel={handleUploadCancel}
        />
      }
      status={status}
      progress={progress}>
      <Box height={openUnziped ? 'auto' : '0px'} overflow="hidden">
        <Box padding={spacing(0, 0, 0, 6)}>
          {unzippedResources?.map(resource => (
            <UploadItem
              header={
                <ResourceHeader
                  name={resource?.fileName || resource?.name}
                  icon={GetResourceTypeIcon(resource?.resourceTypeId)}
                />
              }
              body={
                <ResourceUploadStatus
                  error={
                    resource?.errorExceptionMessage || resource?.errorMessage
                  }
                  status={resource?.processing}
                  onUploadRetry={onUploadRetry}
                  onUploadCancel={handleUploadCancel}
                />
              }
              status={status}
            />
          ))}
        </Box>
        <Divider />
      </Box>
    </UploadItem>
  );
};

export default FilestackUploadItem;
