import { FC, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { generateLinkViewerUrl } from '@setvi/shared/utils/viewer';
import { Link } from '@setvi/shared/interfaces';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { getPreviewLink } from '@setvi/shared/utils';
import { Menu } from '../Menu';
import SText from '../../../sui/stext';
import { useDialog } from '../../../../providers';
import { SConfirmDialog } from '../../../sdialogs';

interface BackProps {
  handleBack: () => void;
  handleRemoveLink(link?: Link): void;
  link: Link;
}

const Container = withStyles(({ spacing }) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: spacing(2),
    paddingLeft: spacing(2)
  }
}))(Box);

const useStyles = makeStyles({
  title: {
    fontWeight: 600
  }
});

export const Back: FC<BackProps> = ({ handleBack, handleRemoveLink, link }) => {
  const classes = useStyles();
  const { openDialog, closeDialog } = useDialog();
  const onPreview = useCallback(
    () =>
      window
        .open(generateLinkViewerUrl(getPreviewLink(link)), '_blank')
        .focus(),
    [link]
  );
  const onDelete = useCallback(() => {
    openDialog(
      <SConfirmDialog
        type="delete"
        title="Delete resource"
        description="Are you sure you want to delete this resource from the resource link?"
        additionalMessage="The action CANNOT be reverted back"
        onClose={() => closeDialog('delete-resource-link')}
        onConfirm={() => {
          handleRemoveLink(link);
          closeDialog('delete-resource-link');
        }}
        confirmText="Delete"
        closeText="Keep it"
      />,
      {
        id: 'delete-resource-link'
      }
    );
  }, [openDialog, handleRemoveLink, link, closeDialog]);

  return (
    <BaseSubheader
      handleBack={handleBack}
      customTitle={
        <Container>
          <SText
            showTooltip
            variant="body1"
            title={link?.Name}
            className={classes.title}
          />
          <Menu onPreview={onPreview} onDelete={onDelete} />
        </Container>
      }
    />
  );
};
