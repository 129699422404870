import { Box } from '@material-ui/core';
import { useSearchLoaderStyle } from './style';
import SearchingResourcesIcon from './icons/SearchingResourcesIcon';

interface SearchLoaderProps {
  itemType?: string;
}

const SearchLoader = ({ itemType = 'resources' }: SearchLoaderProps) => {
  const classes = useSearchLoaderStyle();
  const text = `Searching all your ${itemType}. This may take a few moments...`;

  return (
    <Box className={classes.container}>
      <SearchingResourcesIcon />
      <Box className={classes.textContainer}>
        <p>{text}</p>
      </Box>
    </Box>
  );
};

export default SearchLoader;
