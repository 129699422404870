import {
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import {
  AxiosMethods,
  RESOURCE_PAGINATION,
  ResourceApi
} from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import { Resource, Resources } from '../../../../interfaces';

export enum ResourcesQueryKey {
  USER = 'user_resources',
  COMPANY = 'company_resources',
  FAVORITES = 'favorites_resources',
  RESOURCE_DETAILS = 'resource_details'
}

export enum RESOURCE_BOOKMARKS {
  GET_ALL = 'get_all',
  SAVE_BOOKMARKS = 'save_bookmarks'
}

const dynamicId = ':categoryId';

interface QueryVariables {
  pageNumber: number;
  pageSize: RESOURCE_PAGINATION;
}

export const getCompanyResourcesQuery = (
  Id: string | number,
  input?: QueryVariables
): UseInfiniteQueryOptions<Resources> => ({
  queryKey: [ResourcesQueryKey.COMPANY, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: `${ResourceApi.GetCompanyResources.replace(
        dynamicId,
        Id?.toString()
      )}`,
      method: AxiosMethods.GET,
      params: { ...input, pageNumber: data.pageParam || input.pageNumber }
    }),
  cacheTime: 0,
  getNextPageParam: (lastPage: Resources, allPages: Resources[]) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );

    const currentPageNumber = total / input.pageSize;

    return lastPage?.Data?.Result?.length < input.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getFavoritesResourcesQuery = (
  input?: QueryVariables
): UseInfiniteQueryOptions<Resources> => ({
  queryKey: [ResourcesQueryKey.FAVORITES, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: ResourceApi.GetFavorite,
      method: AxiosMethods.GET,
      params: { ...input, pageNumber: data.pageParam || input.pageNumber }
    }),
  cacheTime: 0,
  getNextPageParam: (lastPage: Resources, allPages: Resources[]) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );

    const currentPageNumber = total / input.pageSize;

    return lastPage?.Data?.Result?.length < input.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getUserResourcesQuery = (
  Id: string | number,
  input?: QueryVariables
): UseInfiniteQueryOptions<Resources> => ({
  queryKey: [ResourcesQueryKey.USER, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: ResourceApi.GetUserResources.replace(dynamicId, Id?.toString()),
      method: AxiosMethods.GET,
      params: { ...input, pageNumber: data.pageParam || input.pageNumber }
    }),
  cacheTime: 0,
  getNextPageParam: (lastPage: Resources, allPages: Resources[]) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );

    const currentPageNumber = total / input.pageSize;

    return lastPage?.Data?.Result?.length < input.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getResource = (
  Id: string | number
): UseQueryOptions<Resource> => ({
  queryKey: [ResourcesQueryKey.RESOURCE_DETAILS, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: ResourceApi.GetResource.replace(':resourceId', Id.toString()),
      method: AxiosMethods.GET
    })
});

export const getResourceBookmarksQuery = (input: { resourceID: string }) => ({
  queryKey: [RESOURCE_BOOKMARKS.GET_ALL, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: ResourceApi.GetResourceBookmarks,
      method: AxiosMethods.POST,
      params: data.queryKey[1]
    }),
  select: (res: any) => res?.Data
});
