import { useState, useEffect } from 'react';
import { Link, Resource } from '@setvi/shared/interfaces';
import { getLinkItemFromResource } from '@setvi/shared/utils';

interface UseSelectedProps {
  resources: Resource[];
  setIsExpanded: (value: boolean) => void;
  setLinkName: (value: string) => void;
  setLink: (link: Link) => void;
  setSelectedResources?: (isChecked: boolean, resources: Resource[]) => void;
  link: Link;
  handleOnInsert?: (link: Link, currentLink?: Link) => void;
  handleRemoveLink?: (link: Link) => void;
}

export const useSelected = ({
  resources,
  setIsExpanded,
  setLink,
  link,
  setSelectedResources,
  handleOnInsert,
  handleRemoveLink
}: UseSelectedProps) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (resources.length > 0) {
      return setItems(resources);
    }
    return setItems([]);
  }, [resources]);

  const removeItem = (id: number) => {
    // Sets the items inside the link
    const filteredItems = resources.filter(resource => resource.ID !== id);
    if (!link) {
      setItems(filteredItems);
    } else {
      setLink({
        ...link,
        Item: {
          ...link.Item,
          Items: filteredItems.map(item => getLinkItemFromResource(item))
        }
      });
    }

    // Actually updates the link with the new items
    // If link has already been inserted, than we actually
    // gotta update the existing link
    if (handleOnInsert) {
      handleOnInsert(
        {
          ...link,
          Item: {
            Items: filteredItems.map(item => getLinkItemFromResource(item))
          },
          Name: link.Name
        },
        link
      );
    }

    if (items.length === 1 && handleRemoveLink) {
      setLink(null);
      handleRemoveLink(link);
    }

    setSelectedResources?.(false, [
      resources.find(resource => resource.ID === id)
    ]);
    if (items.length < 2) {
      setIsExpanded(false);
    }
  };

  return {
    removeItem,
    items,
    setItems
  };
};
