import { Box } from '@material-ui/core';
import { getInitials } from '@setvi/shared/utils';
import { SAvatar, SText } from '@setvi/shared/components';
import { useSugestedContactsStyles } from '../style';

interface ContactProps {
  firstName: string;
  lastName: string;
  email: string;
}

export const Contact = ({ firstName, lastName, email }: ContactProps) => {
  const style = useSugestedContactsStyles();

  const fullName = `${firstName} ${lastName}`;

  return (
    <Box className={style.contact}>
      <Box mr={2}>
        <SAvatar value={getInitials({ firstName, lastName, email })} />
      </Box>
      <Box display="flex" flexDirection="column">
        <SText title={fullName.trim()} />
        <SText size={fullName.trim() ? 'xs' : 'base'} title={email} />
      </Box>
    </Box>
  );
};
