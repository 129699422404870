import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@tanstack/react-query';
import { Grid, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { SButton } from '@setvi/shared/components';

import { useAppContext } from 'Providers/AppProvider/AppContext';
import { UpdateUserDetailsInput } from 'Types/api/globalTypes';
import {
  emailRegex,
  PHONE_REGEX_EXP,
  ONLY_ALPHABETS_CHARACTERS
} from 'Utils/regex';
import { updateUserDetailsMutation } from 'Services';

import { UserInformationForm } from './UserInformationForm';

const useStyles = makeStyles({
  testClassName: {
    opacity: 0.2
  },
  updateButton: {
    height: 40
  },
  fullWidth: {
    width: '100%'
  },
  customWidth: {
    width: 109
  }
});

interface IUserInformation {
  onSubmitted?: () => void;
  label?: string;
  submitButtonText?: string;
  fullWidthSubmitButton?: boolean;
}

const UserInformationContainer = ({
  onSubmitted,
  label = 'User Information',
  submitButtonText = 'Update',
  fullWidthSubmitButton
}: IUserInformation) => {
  const { user, setUser } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { mutateAsync: updateUserDetails, isLoading: loading } = useMutation(
    updateUserDetailsMutation()
  );

  const initialValues: UpdateUserDetailsInput = {
    FirstName: emailRegex.test(user.FirstName) ? '' : user.FirstName,
    LastName: emailRegex.test(user.LastName) ? '' : user.LastName,
    PhoneNumber: user.ContactNumber || ''
  };

  const invalidNumberMessage = 'Phone number is not valid';
  const exceedsLimitNumberMessage = 'Exceeded limit for Phone number';

  const externalUserPhoneNumber = Yup.string()
    .required('Phone number is required')
    .matches(PHONE_REGEX_EXP, invalidNumberMessage)
    .max(128, exceedsLimitNumberMessage);
  const userPhoneNumber = Yup.string()
    .matches(PHONE_REGEX_EXP, invalidNumberMessage)
    .max(128, exceedsLimitNumberMessage);

  const validationSchema = Yup.object({
    FirstName: Yup.string()
      .required('First Name is required')
      .matches(ONLY_ALPHABETS_CHARACTERS, 'First Name can only contain letters')
      .max(100, 'Exceeded limit for First Name'),
    LastName: Yup.string()
      .required('Last Name is required')
      .matches(ONLY_ALPHABETS_CHARACTERS, 'Last Name can only contain letters')
      .max(100, 'Exceeded limit for Last Name'),
    PhoneNumber: window.location.origin.includes('middleby')
      ? externalUserPhoneNumber
      : userPhoneNumber
  });

  const handleSubmit = async (values: UpdateUserDetailsInput) => {
    const { Data } = await updateUserDetails({ body: values });

    if (Data) {
      const updatedUser = {
        ...user,
        FirstName: Data?.FirstName,
        LastName: Data?.LastName,
        ContactNumber: Data?.PhoneNumber
      };

      setUser(updatedUser);
      enqueueSnackbar(`Changes has been saved successfully`, {
        variant: 'success'
      });
      onSubmitted?.();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => handleSubmit(values)}>
      {() => (
        <Form>
          <UserInformationForm disabled={loading} label={label} />
          <Grid item xs={12}>
            <Box>
              <SButton
                className={clsx(
                  classes.updateButton,
                  fullWidthSubmitButton
                    ? classes.fullWidth
                    : classes.customWidth
                )}
                loading={loading}
                type="submit">
                {submitButtonText}
              </SButton>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserInformationContainer;
