import { Box, IconButton } from '@material-ui/core';
import { useMemo, useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SGalleryThumbnail } from '../thumbnail';
import { useGalleryStyles } from '../style';
import SETVIImage from '../../setvi-image';

export interface GalleryProps {
  images: string[];
  defaultOpenedImage?: string;
  numberOfPreviewThumbnails?: number;
  isFullScreen?: boolean;
  onImageClick?: (imageUrl: string) => void;
}

export const Gallery = ({
  images = [],
  defaultOpenedImage,
  numberOfPreviewThumbnails = 3,
  isFullScreen,
  onImageClick
}: GalleryProps) => {
  const styles = useGalleryStyles();
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(numberOfPreviewThumbnails);
  const [activeImage, setActiveImage] = useState<string>(
    defaultOpenedImage || images[0]
  );

  const thumbnails = useMemo(
    () => images.slice(startIndex, endIndex),
    [images, startIndex, endIndex]
  );

  const hasArrows = useMemo(
    () => images.length > numberOfPreviewThumbnails,
    [images, numberOfPreviewThumbnails]
  );

  const handleNext = () => {
    if (endIndex === images.length) return;
    setEndIndex(prev => prev + 1);
    setStartIndex(prev => prev + 1);
  };

  const handleBack = () => {
    if (startIndex === 0) return;
    setEndIndex(prev => prev - 1);
    setStartIndex(prev => prev - 1);
  };

  return (
    <Box width="100%">
      <Box
        height={isFullScreen ? 700 : 200}
        className={styles.mainImageContainer}>
        <SETVIImage
          onClick={() => onImageClick?.(activeImage)}
          className={styles.mainImage}
          src={activeImage}
          alt="main image"
        />
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        {hasArrows && (
          <Box>
            <IconButton
              disabled={startIndex === 0}
              onClick={handleBack}
              size="small">
              <ChevronLeftIcon htmlColor={isFullScreen && '#fff'} />
            </IconButton>
          </Box>
        )}
        {images.length > 1 && (
          <Box
            maxHeight={60}
            flexGrow={1}
            display="flex"
            justifyContent="center">
            {thumbnails.map(image => (
              <SGalleryThumbnail
                key={image}
                src={image}
                onClick={() => setActiveImage(image)}
                active={activeImage === image}
              />
            ))}
          </Box>
        )}
        {hasArrows && (
          <Box>
            <IconButton
              disabled={endIndex === images.length}
              onClick={handleNext}
              size="small">
              <ChevronRightIcon htmlColor={isFullScreen && '#fff'} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
