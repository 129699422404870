import { CATEGORY_TYPE } from '@setvi/shared/enums';
import { Box, IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useCategoryList } from './useCategoryList';
import { useStyles } from './style';
import SInfiniteScroll from '../../sinfinite-scroll';
import SemptyState from '../../sempty-state';
import SETVIImage from '../../setvi-image';
import { FillFolderIcon } from '../../sicons';
import { Category } from '../../../interfaces';
import SText from '../../sui/stext';

interface CategoryListProps {
  categoryType?: CATEGORY_TYPE;
  onLastItemLoaded?: () => void;
  categoryId?: number;
  onClick?: (rowData: any) => void;
  onEmpty?: () => void;
}

const rowKeyGetter = ({ ID }: any) => ID;

export const CategoryList = ({
  categoryType = CATEGORY_TYPE.COMPANY,
  onLastItemLoaded,
  categoryId,
  onClick,
  onEmpty
}: CategoryListProps) => {
  const { categories, loading, fetchMore } = useCategoryList({
    categoryId,
    onEmpty,
    categoryType,
    onLastItemLoaded
  });
  const classes = useStyles();
  const isEmpty = !categories.length;

  return (
    <Box p={2}>
      {!isEmpty && (
        <Box mb={2}>
          <SText weight="medium">Categories</SText>
        </Box>
      )}
      <SInfiniteScroll
        list={categories}
        fetchMore={fetchMore}
        keyGetter={rowKeyGetter}
        render={(category: Category) => (
          <Box className={classes.innerWrap} onClick={() => onClick(category)}>
            <SETVIImage
              className={classes.panelImage}
              src={category.ThumbURLWithSas}
              alt="Category"
              loading="lazy"
            />
            <Box flex={1} ml={2}>
              <SText
                title={category.Name}
                className={classes.itemName}
                weight="medium"
                size="sm"
                maxLines={2}
              />
              <Box display="flex" alignItems="center">
                <FillFolderIcon fontSize="small" />
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton size="small">
                <ChevronRight />
              </IconButton>
            </Box>
          </Box>
        )}>
        <>
          {loading && (
            <Box display="flex" justifyContent="center">
              <SText weight="medium">Loading...</SText>
            </Box>
          )}
          {!loading && isEmpty && (
            <SemptyState subTitle="No Categories available" />
          )}
        </>
      </SInfiniteScroll>
    </Box>
  );
};
