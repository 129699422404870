import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosMethods, SnippetsApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import { Snippet } from '../../../../interfaces';

export enum SnippetsQueryKey {
  SNIPPETS = 'snippets',
  COMPANY_SNIPPETS = 'company_snippets'
}

export const getSnippetsQuery = (): UseQueryOptions<Snippet[]> => ({
  queryKey: [SnippetsQueryKey.SNIPPETS],
  queryFn: () =>
    axiosHelper({
      endpoint: `${SnippetsApi.Snippets}`,
      method: AxiosMethods.GET
    }),
  select: (res: any) => res?.Data
});

export const getCompanySnippetsQuery = (): UseQueryOptions<Snippet[]> => ({
  queryKey: [SnippetsQueryKey.COMPANY_SNIPPETS],
  queryFn: () =>
    axiosHelper({
      endpoint: `${SnippetsApi.CompanySnippets}`,
      method: AxiosMethods.GET
    })
});
