import { CSSProperties, ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import {
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel
} from '@material-ui/core';

import { Table, TableHeaderCell, useStyles } from '../../styles';
import { ColumnsType } from '../../../../types';
import { SortType } from '../../../../enums';
import TableBodyContent from './content';
import { TableState } from './states';
import { useTable } from './useTable';
import { TableLabel } from '../../styles/TableStyles';
import { SortIcon } from '../../../sicons';
import { SCheckbox } from '../../../scheckbox';

export interface TableContentProps {
  data?: any;
  columns: ColumnsType;
  sortedColumn?: { sortBy: string; orderBy: SortType };
  loading: boolean;
  isSearch: boolean;
  isSelectable: boolean;
  selected?: any[];
  searchQuery?: string;
  selectDisabledText?: string;
  removeItems?: any[];
  customEmptyState?: ReactNode;
  onSortItems?: (columnName: string) => void;
  onRowClick?: (item: any, index: number) => void;
  onSelect?(rows: any): void;
  rowKeyGetter: (data: any) => string | number;
  rowStyleGetter?: (data: any) => CSSProperties;
  selectDisabled?: (row?: any) => boolean;
  overLimit?: (row?: any) => boolean;
}

const TableContent = ({
  data,
  columns,
  isSelectable = false,
  sortedColumn,
  isSearch,
  loading,
  selected,
  searchQuery,
  removeItems,
  customEmptyState,
  onSortItems,
  onSelect,
  rowKeyGetter,
  rowStyleGetter,
  overLimit,
  ...rest
}: TableContentProps) => {
  const classes = useStyles();

  const { handleSelect, selectedItems, isAllSelected, tableWidth } = useTable({
    columns,
    selected,
    removeItems,
    // This soulution is implemented because of static tables
    selectDisabled: rest?.selectDisabled,
    onSelect,
    rowKeyGetter
  });

  const tableHeadCheckbox = useMemo(() => {
    const isEmpty = !data?.items?.length;
    const hasSelectableItems = rest?.selectDisabled
      ? data?.items?.filter((i: any) => !rest?.selectDisabled(i))?.length > 0
      : true;
    const getSelectable = (items: any[]) => {
      if (rest?.selectDisabled) {
        return items.filter((i: any) => !rest?.selectDisabled(i));
      }
      return items;
    };
    const isChecked =
      data?.items && !loading ? isAllSelected(data.items) : false;

    const isOverLimit = overLimit?.() && !isChecked;

    const isDisabled = isEmpty || !hasSelectableItems || loading || isOverLimit;

    return (
      <TableHeaderCell style={{ width: 40, textAlign: 'center' }}>
        <SCheckbox
          disabled={isDisabled}
          checked={isChecked}
          onChange={e =>
            handleSelect(e.target.checked, getSelectable(data?.items))
          }
        />
      </TableHeaderCell>
    );
  }, [data.items, rest, loading, isAllSelected, overLimit, handleSelect]);

  return (
    <TableContainer className={clsx(classes.tableContainer, classes.grow)}>
      <Table
        style={{ width: tableWidth || 'unset' }}
        stickyHeader
        aria-label="table">
        <TableHead>
          <TableRow>
            {isSelectable && tableHeadCheckbox}
            {columns?.map((column: any) => {
              const isSorted = sortedColumn?.sortBy === column?.sortBy;
              return (
                <TableHeaderCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width,
                    textAlign: column?.align
                  }}>
                  <TableLabel
                    align={column?.align}
                    className={column.sortBy && classes.sortable}
                    onClick={() => column.sortBy && onSortItems(column.sortBy)}>
                    {column?.sortBy && (
                      <TableSortLabel
                        className={classes.sortIconWraper}
                        IconComponent={SortIcon}
                        active={isSorted}
                        direction={
                          isSorted ? sortedColumn.orderBy : SortType.ASC
                        }>
                        <Typography noWrap>{column?.label || ''}</Typography>
                      </TableSortLabel>
                    )}
                    {!column?.sortBy && (
                      <Typography noWrap>{column?.label || ''}</Typography>
                    )}
                  </TableLabel>
                </TableHeaderCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBodyContent
          data={data}
          loading={loading}
          overLimit={overLimit}
          isSelectable={isSelectable}
          handleSelect={handleSelect}
          selected={selectedItems}
          columns={columns}
          searchQuery={searchQuery}
          rowKeyGetter={rowKeyGetter}
          rowStyleGetter={rowStyleGetter}
          selectDisabledText={rest?.selectDisabledText}
          {...rest}
        />
      </Table>
      <TableState
        loading={loading}
        isSearch={isSearch}
        dataLength={data?.items?.length || 0}
        customEmptyState={customEmptyState}
      />
    </TableContainer>
  );
};

export default TableContent;
