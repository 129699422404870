import { createContext } from 'react';

type DrawerContextType = {
  // Flag when user is currently searching i.e. search input contains value
  setIsSearching: (value: boolean) => void;
  isSearching: boolean;

  sasToken: string;
  algoliaKey: string;
};

const contextDefaultValues: DrawerContextType = {
  isSearching: false,
  setIsSearching: () => {},
  sasToken: process.env.ALGOLIA_KEY || null,
  algoliaKey: process.env.SAS_TOKEN || null
};

export const DrawerContext =
  createContext<DrawerContextType>(contextDefaultValues);
