import { makeStyles } from '@material-ui/core';

export const useSugestedContactsStyles = makeStyles(theme => ({
  popper: {
    width: '95%',
    backgroundColor: theme.palette.common.white,
    zIndex: 1000
  },

  contact: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}));
