import { useState, useEffect, useReducer, useMemo } from 'react';
import { Resource } from '@setvi/shared/interfaces';

export enum UploadCounterActionTypes {
  ADD_TO_COUNTER = 'addToCounter',
  UPDATE_PERCENTAGE = 'updatePercentage',
  UPDATE_COUNTER_STATUS = 'updateCounterStatus',
  ADD_CANCEL_FUNCTION = 'addCancelFunction'
}

export enum UploadStatus {
  UPLOADING = 'uploading',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  ERROR = 'error',
  CANCELED = 'canceled'
}

interface UploadCounter {
  fileId: string;
  status?: UploadStatus;
  percent?: number;
  createdResource?: Resource;
  cancel?: () => void;
}

export interface UploadCounterAction {
  type: UploadCounterActionTypes;
  payload: UploadCounter;
}

// These are the file statuses that are considered finished
// i.e. not waiting for API calls
const FileFinishedStatuses = [
  UploadStatus.COMPLETE,
  UploadStatus.ERROR,
  UploadStatus.CANCELED
];

const initialCounter: UploadCounter[] = [];

const reducer = (state: any, action: UploadCounterAction) => {
  switch (action.type) {
    case UploadCounterActionTypes.ADD_TO_COUNTER: {
      const { fileId } = action.payload;

      return [...state, { fileId, status: UploadStatus.UPLOADING, percent: 0 }];
    }
    case UploadCounterActionTypes.UPDATE_PERCENTAGE: {
      const { fileId, percent } = action.payload;
      const index = state.findIndex((value: any) => value.fileId === fileId);

      const newCounter: any[] = state.map((i: any, idx: number) =>
        index === idx ? { ...i, percent } : i
      );

      return newCounter;
    }
    case UploadCounterActionTypes.UPDATE_COUNTER_STATUS: {
      const { fileId, status, createdResource } = action.payload;
      const newCounter = [...state];
      const index = newCounter.findIndex(value => value.fileId === fileId);
      newCounter[index].status = status;

      if (status === UploadStatus.COMPLETE) {
        newCounter[index].createdResource = createdResource;
      }

      return newCounter;
    }
    case UploadCounterActionTypes.ADD_CANCEL_FUNCTION: {
      const { fileId, cancel } = action.payload;
      const newCounter = [...state];
      const index = newCounter.findIndex(value => value.fileId === fileId);
      newCounter[index].cancel = cancel;

      return newCounter;
    }
    default: {
      return [...state];
    }
  }
};

interface UsePanelProps {
  onCompleteUpload: (resources: Resource[]) => void;
  setUploading?: (value: boolean) => void;
  canceled?: boolean;
  handleCancel?: () => void;
}

export const usePanel = ({
  onCompleteUpload,
  setUploading,
  canceled,
  handleCancel
}: UsePanelProps) => {
  const [totalPercent, _setTotalPercent] = useState(0);
  const [counter, dispatch] = useReducer(reducer, initialCounter);

  const completedFilesCount = useMemo(
    () =>
      counter.filter(file => FileFinishedStatuses.indexOf(file.status) !== -1)
        .length,
    [counter]
  );

  const validFiles = useMemo(
    () => counter.filter(c => c.status !== UploadStatus.CANCELED),
    [counter]
  );

  useEffect(() => {
    const successfullyCompleted = counter.every(
      i => i.status === UploadStatus.COMPLETE
    );

    if (counter?.length && successfullyCompleted) {
      /* uploadingFilesCount
        ? setTotalPercent(Math.round(filePercentage / uploadingFilesCount))
        : setUploading(false); */

      // Check if all files are finished
      if (completedFilesCount === counter.length) {
        const createdResources = validFiles.map(file => file.createdResource);

        return onCompleteUpload(createdResources);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedFilesCount, counter, setUploading, validFiles]);

  useEffect(() => {
    if (canceled && counter.length) {
      const processingFilesCount = counter.reduce((total, file) => {
        if (file.status === UploadStatus.PROCESSING) {
          return total + 1;
        }
        file.status === UploadStatus.UPLOADING && file.cancel();
        return total;
      }, 0);
      !processingFilesCount && handleCancel();
    }
  }, [canceled, counter, handleCancel]);

  return {
    totalPercent,
    dispatch
  };
};
