import { memo } from 'react';
import { TreeView, TreeViewProps } from '@material-ui/lab';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import STreeNode, { STreeNodeProps } from './node';

interface STreeProps<T>
  extends Omit<STreeNodeProps<T>, 'node' | 'nodeKeyGetter'> {
  nodes: T[];
  keyGetter: (item: T) => string | number;
  treeViewProps?: TreeViewProps;
}

const STree = <T,>({
  nodes,
  keyGetter,
  renderNodeLabel,
  onNodeClick,
  nodeChildrenGetter,
  treeViewProps
}: STreeProps<T>) => (
  <TreeView
    defaultCollapseIcon={<ExpandMoreRoundedIcon />}
    defaultExpandIcon={<ChevronRightRoundedIcon />}
    {...treeViewProps}>
    {nodes.map(node => (
      <STreeNode
        key={keyGetter(node).toString()}
        node={node}
        nodeKeyGetter={keyGetter}
        renderNodeLabel={renderNodeLabel}
        nodeChildrenGetter={nodeChildrenGetter}
        onNodeClick={onNodeClick}
      />
    ))}
  </TreeView>
);

export default memo(STree) as typeof STree;
