import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { SortableContactProperties } from '../contacts/contact-filter';
import { getRecentContacts } from '../../../services';
import { SortType } from '../../../enums';
import { Contact } from '../../../services/react-query/query/contacts/types';

const RECENT_CONTACTS_LIMIT = 20;
const SAMPLE_SIZE = 100;

export const useRecentContacts = () => {
  const [sortBy, setSortBy] = useState<SortableContactProperties>('FirstName');
  const [searchString, setSearchString] = useState<string | undefined>(
    undefined
  );

  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: RECENT_CONTACTS_LIMIT,
      orderBy: SortType.ASC,
      sortBy,
      search: searchString,
      sampleSize: SAMPLE_SIZE
    }),
    [searchString, sortBy]
  );

  const {
    data: recentContacts,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getRecentContacts(queryVariables));

  const fetchMoreRecentContacts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    recentContacts: (recentContacts?.pages?.map(page => page?.Result).flat(1) ||
      []) as Contact[],
    isLoading: isLoading || isFetching,
    fetchMoreRecentContacts,
    setSearchString,
    sortBy,
    setSortBy
  };
};
