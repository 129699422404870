import { useMemo } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Content as ContentContainer } from '@setvi/shared/components/sdrawer/base';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { DrawerContent } from '@setvi/shared/enums/common';
import SButton from '@setvi/shared/components/sbutton';
import { distances } from '../../../styles';
import { NavigationButton, NavigationButtonProps } from '../list/button';
import { useListStyles } from '../list/style';
import {
  FillCrmLeadIcon,
  FillCrmAccountIcon,
  FillRecentContactIcon,
  FillUserIcon
} from '../../sicons';

export type SalesforceUser = {
  SalesforceEmail: string;
  SalesforceUserID: string;
};

const useContactsStyles = makeStyles(() => ({
  description: {
    color: '#919294',
    fontSize: 12
  },
  container: {
    padding: `${distances.xlarge}px ${distances.medium}px ${distances.medium}px ${distances.medium}px`
  },
  listContainer: {
    marginTop: distances.large
  },
  divider: { marginTop: distances.large },
  button: {
    marginTop: distances.large
  }
}));

interface ContactsProps {
  salesforceUser?: SalesforceUser;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

export const ContactsNavigation = ({
  salesforceUser,
  handleClose,
  setShowContent
}: ContactsProps) => {
  const styles = useContactsStyles();
  const classes = useListStyles();

  const contactsNavigation: NavigationButtonProps[] = useMemo(
    () => [
      ...(salesforceUser?.SalesforceEmail && salesforceUser?.SalesforceUserID
        ? [
            {
              label: 'CRM Leads',
              icon: <FillCrmLeadIcon />,
              onClick: () => setShowContent(DrawerContent.crmLeads)
            },
            {
              label: 'CRM Accounts',
              icon: <FillCrmAccountIcon />,
              onClick: () => setShowContent(DrawerContent.crmAccounts)
            }
          ]
        : []),
      {
        label: 'Recent Contacts',
        icon: <FillRecentContactIcon />,
        onClick: () => setShowContent(DrawerContent.recentContacts)
      },
      {
        label: 'Contacts',
        icon: <FillUserIcon />,
        onClick: () => setShowContent(DrawerContent.contacts)
      },
      {
        label: 'Groups',
        icon: <FillRecentContactIcon />,
        onClick: () => setShowContent(DrawerContent.groups)
      }
    ],
    [salesforceUser, setShowContent]
  );

  return (
    <ContentContainer
      header={<Header title="Contacts" hasClose handleClose={handleClose} />}>
      <Box className={styles.container}>
        <Typography className={styles.description}>
          Please click the button below to insert recipients.
        </Typography>
        <Grid container spacing={2} className={styles.listContainer}>
          {contactsNavigation.map((el, _index, _array) => (
            <Grid
              key={el.label}
              className={classes.grid}
              item
              //   To add setvi contacts on new line xs={index + 1 === array.length ? 12 : 6}
              xs={6}>
              <NavigationButton
                label={el.label}
                icon={el.icon}
                onClick={el.onClick}
              />
            </Grid>
          ))}
        </Grid>
        <Divider className={styles.divider} />
        <SButton
          className={styles.button}
          onClick={() => handleClose()}
          fullWidth
          color="default"
          variant="outlined">
          Cancel
        </SButton>
      </Box>
    </ContentContainer>
  );
};
