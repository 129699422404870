import { Box, makeStyles, TableRow } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Skeleton } from '@material-ui/lab';
import { STableCell } from '../../cells';

const useStyles = makeStyles({
  loader: {
    display: 'grid',
    placeItems: 'center',
    flexGrow: 1
  }
});

interface TableSkeletonProps {
  columns?: any;
  height?: number;
  items?: number;
}

export const Loader = ({ columns, height, items }: TableSkeletonProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.loader} p={4}>
      <Box fontSize={16} fontWeight={600}>
        Loading...
      </Box>
    </Box>
  );

  // FIXME currently Loading component return just text until loading UI is done
  return items
    ? Array(items)
        .fill(0)
        .map((number: number) => (
          <TableRow hover key={number}>
            {columns.map((column: any) => (
              <STableCell
                key={column.id}
                align={column.align}
                style={{
                  maxWidth: column.maxWidth
                }}>
                <Skeleton variant="text" height={height} />
              </STableCell>
            ))}
          </TableRow>
        ))
    : columns.map((column: any) => (
        <STableCell
          key={column.id}
          align={column.align}
          style={{
            maxWidth: column.maxWidth
          }}>
          <Skeleton variant="text" height={height} />
        </STableCell>
      ));
};
