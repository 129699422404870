import { daysRemaining } from '@setvi/shared/utils';

export const formatFileSize = (bytes: number, decimalPoint?: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// Use this funciton to get the days remaining for a campaign and the color of the text
export const ValidUntilDateGen = (validUntilDate: string) => {
  const daysLeft = daysRemaining(validUntilDate);
  const colors = {
    default: '#44444F',
    overdue: '#FF0000',
    warning: '#FF9F29'
  };

  let text = `${daysLeft} days left`;
  let colorTxt = colors.default;

  if (daysLeft < 0) {
    colorTxt = colors.overdue;
    text = 'Overdue';
  } else if (daysLeft === 0) text = 'Last day';
  else if (daysLeft === 1) text = `${daysLeft} day left`;
  else if (daysLeft <= 3) colorTxt = colors.warning;

  return {
    text,
    colorTxt
  };
};
