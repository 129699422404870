import { makeStyles, Theme } from '@material-ui/core';

export const useSearchLoaderStyle = makeStyles<Theme>({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  textContainer: {
    width: 180,
    fontSize: 12,
    fontWeight: 500,
    color: '#B0BACC',
    overflow: 'hidden',
    textAlign: 'center',
    lineHeight: '1.5rem'
  }
});
