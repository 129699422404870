import { OutlinedInput } from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';

export const SearchField = withStyles(theme => ({
  adornedStart: {
    backgroundColor: '#FAFAFB'
  },
  adornedEnd: {
    borderRadius: 4,
    backgroundColor: '#FAFAFB',
    paddingRight: theme.spacing(2),
    fontSize: 'revert',
    '& fieldset': {
      borderColor: '#F1F1F5'
    }
  },
  input: {
    backgroundColor: '#FAFAFB',
    height: 12
  }
}))(OutlinedInput);

export const useStyles = makeStyles<Theme, { width: number | string }>({
  wrapper: {
    width: ({ width }) => width || '100%',
    maxWidth: '100%',
    height: 'min-content'
  }
});
