import { Box, Button } from '@material-ui/core';
import { Content as ContentContainer } from '@setvi/shared/components/sdrawer/base';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { SText } from '@setvi/shared/components';
import { UploadFileIcon } from '@setvi/shared/components/sicons';
import { Link } from '@setvi/shared/interfaces';
import UploadPanel from '@setvi/shared/components/supload-panel';
import { CenterBox, DropAreaContainer, DropArea } from './style';
import { useUpload } from './useUpload';

interface ContentProps {
  handleBack?(): void;
  handleClose(): void;
  handleInsert?: (link: Link) => void;
  handleComplete?: () => void;
  handleCancel?: () => void;
  categoryId?: number | null;
}

export const Content = ({
  handleBack,
  handleClose,
  handleInsert,
  handleComplete,
  handleCancel,
  categoryId = null
}: ContentProps) => {
  const {
    uploading,
    setUploading,
    files,
    resetFiles,
    onCompleteUpload,
    getRootProps,
    getInputProps,
    isDragActive,
    isCanceled,
    onCancel
  } = useUpload({ handleClose, handleInsert, handleComplete, handleCancel });

  return (
    <ContentContainer
      header={
        <Header
          title="Add from SETVI"
          hasClose
          handleClose={handleClose}
          disableClose={uploading}
        />
      }
      backContent={
        <BaseSubheader
          handleBack={files.length > 0 ? resetFiles : handleBack}
          text="Upload file"
          disabled={uploading}
        />
      }>
      {files.length > 0 ? (
        <UploadPanel
          drawer
          acceptedFiles={files}
          onCompleteUpload={onCompleteUpload}
          setUploading={setUploading}
          categoryId={categoryId}
          canceled={isCanceled}
          handleCancel={onCancel}
        />
      ) : (
        <DropAreaContainer>
          <DropArea {...getRootProps()}>
            <input {...getInputProps()} />
            <Box>
              <CenterBox width="100%" mb={3}>
                <UploadFileIcon color="primary" fontSize="large" />
              </CenterBox>
              {isDragActive ? (
                <CenterBox mb={2}>
                  <SText weight="bold">Drop files here...</SText>
                </CenterBox>
              ) : (
                <>
                  <CenterBox mb={2}>
                    <SText weight="bold">Drag & Drop</SText>
                  </CenterBox>
                  <CenterBox>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={uploading}>
                      Upload File
                    </Button>
                  </CenterBox>
                </>
              )}
            </Box>
          </DropArea>
        </DropAreaContainer>
      )}
    </ContentContainer>
  );
};
