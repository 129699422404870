import Fuse from 'fuse.js';

interface GetFuseResultsProps {
  fuse: Fuse<any>;
  searchTerm: string;
  items: any[];
}

export const FUSE_OPTIONS = {
  threshold: 0.1,
  distance: 1000,
  keys: [{ name: 'Name', weight: 1 }]
};

export function getFuseResult({
  fuse,
  searchTerm,
  items
}: GetFuseResultsProps) {
  const result = searchTerm ? fuse.search(searchTerm) : items;
  if (searchTerm) {
    const newResult = result.reduce((acc, curr) => {
      if (curr.item) {
        acc.push(curr.item);
      }
      return acc;
    }, []);

    return newResult;
  }

  return items;
}
