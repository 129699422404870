import { Header } from '@setvi/shared/components/sdrawer/header';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { AddressBook } from '@setvi/shared/components/sdrawer/contacts/address-book';
import { ContactFilter } from '@setvi/shared/components/sdrawer/contacts/contact-filter';
import { TContact } from '@setvi/shared/components/sdrawer/contacts/contact';
import { Box, Typography } from '@material-ui/core';
import { Group } from '@setvi/shared/services/react-query/query/contacts/types';
import { useGroupsContacts } from './useGroupsContacts';
import { SEmptyState } from '../../..';
import { Recipient } from '../../../../interfaces';

interface GroupsContactsProps {
  selectedGroup: Group;
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  handleBack: () => void;
  handleClose: () => void;
}

export const GroupsContacts = ({
  selectedGroup,
  selectedContacts,
  modifyRecipientList,
  handleBack,
  handleClose
}: GroupsContactsProps) => {
  const {
    contacts,
    fetchMoreContacts,
    isLoading,
    setSearchTerm,
    sortBy,
    setSortBy
  } = useGroupsContacts({ selectedGroup });

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Recipients" hasClose handleClose={handleClose} />
      <BaseSubheader
        handleBack={() => handleBack()}
        text={selectedGroup.Name}
        bottomDivider
      />
      <Box p={2} mb={1} display="flex">
        <SSearchInput onChange={value => setSearchTerm(value)} />
        <ContactFilter sortBy={sortBy} setSortBy={setSortBy} />
      </Box>
      <Box overflow="auto">
        <AddressBook
          contacts={contacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreContacts}
          onContactClick={modifyRecipientList}>
          <>
            {isLoading && (
              <Box textAlign="center">
                <Typography variant="h6">Loading...</Typography>
              </Box>
            )}
            {!isLoading && contacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-contacts-2.png"
                subTitle="No contacts available"
              />
            )}
          </>
        </AddressBook>
      </Box>
    </Box>
  );
};
