import { Box, makeStyles } from '@material-ui/core';
import Text from '@setvi/shared/components/sui/stext';
import { NoMyResourcesIcon } from './icons/NoMyResourcesIcon';

export const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorImage: {
    width: '100%'
  },
  btn: {
    borderRadius: 7,
    boxShadow: '0px 7px 16px 1px rgba(205, 214, 225, 0.69)',
    padding: '12px 40px',
    fontWeight: 700,
    width: '100%'
  }
});

export interface EmptyResourcesIconProps {
  onClick: () => void;
}

// Ignoring for now until we add button back again
// @ts-ignore
export const EmptyMyResources = ({ onClick }: EmptyResourcesIconProps) => {
  const classes = useStyles();
  return (
    <Box height="100%" className={classes.center}>
      <Box my={2} className={`${classes.center} ${classes.errorImage}`}>
        <NoMyResourcesIcon />
        <Text fontColor="#B6BFCC">No resources available</Text>
      </Box>
      {/* <Button
        className={classes.btn}
        variant="contained"
        color="primary"
        onClick={onClick}>
        Upload
      </Button> */}
    </Box>
  );
};
