import { memo } from 'react';
import {
  Box,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import { useStyles } from '@setvi/shared/components/s-table/styles/TableStyles';
import { TableHeaderCell } from '@setvi/shared/components/s-table/styles';
import { SortType } from '@setvi/shared/enums';
import { ColumnsType } from '@setvi/shared/types';
import { SCheckbox, SCheckboxProps } from '../../../../scheckbox';
import { SortIcon } from '../../../../sicons';

interface SortedColumn {
  sortBy: string;
  orderBy: SortType;
}

export interface TableHeaderProps {
  /**
   * @deprecated use checkboxV2 instead
   */
  checkbox?: JSX.Element;
  checkboxV2?: SCheckboxProps;
  sort?: {
    sortBy: string;
    orderBy: SortType;
    onChange: (sortBy: string) => void;
  };
  columns: ColumnsType;
  /**
   * @deprecated use sort instead
   */
  sortedColumn?: SortedColumn;
  /**
   * @deprecated use sort instead
   */
  onSortItems?: (columnName: string) => void;
  headerClass?: string;
}

const TableHeader = ({
  checkbox,
  checkboxV2,
  sort,
  sortedColumn = { sortBy: sort?.sortBy, orderBy: sort?.orderBy },
  columns,
  onSortItems = sort?.onChange,
  headerClass
}: TableHeaderProps) => {
  const classes = useStyles();
  return (
    <TableHead className={headerClass}>
      <TableRow>
        {checkbox && (
          <TableHeaderCell style={{ width: 40, textAlign: 'center' }}>
            {checkbox}
          </TableHeaderCell>
        )}
        {checkboxV2 && (
          <TableHeaderCell style={{ width: 40, textAlign: 'center' }}>
            <SCheckbox {...checkboxV2} />
          </TableHeaderCell>
        )}
        {columns?.map(column => {
          const isSorted = sortedColumn?.sortBy === column?.sortBy;

          return (
            <TableHeaderCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                width: column.width,
                textAlign: column?.align
              }}>
              <Box
                className={column.sortBy && classes.sortable}
                style={{
                  margin: column?.align === 'center' ? 'auto' : 'unset'
                }}
                onClick={() => column.sortBy && onSortItems?.(column.sortBy)}>
                {column?.sortBy && (
                  <TableSortLabel
                    className={classes.sortIconWraper}
                    active={isSorted}
                    IconComponent={SortIcon}
                    direction={isSorted ? sortedColumn?.orderBy : SortType.ASC}>
                    <Typography noWrap>
                      {column?.label || ''}
                      {column?.required && (
                        <span className={classes.required}>*</span>
                      )}
                    </Typography>
                  </TableSortLabel>
                )}
                {!column?.sortBy && (
                  <Typography noWrap>
                    {column?.label || ''}
                    {column?.required && (
                      <span className={classes.required}>*</span>
                    )}
                  </Typography>
                )}
              </Box>
            </TableHeaderCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default memo(TableHeader);
