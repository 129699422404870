import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={122}
    height={124}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M59.134 99.329c26.432 0 47.86-21.428 47.86-47.86 0-26.433-21.428-47.86-47.86-47.86-26.433 0-47.86 21.427-47.86 47.86 0 26.432 21.427 47.86 47.86 47.86Zm53.81-67.612a4.1 4.1 0 1 0 0-8.2 4.1 4.1 0 0 0 0 8.2Zm6.001-16.001a2.8 2.8 0 1 0 0-5.6 2.8 2.8 0 0 0 0 5.6ZM16.993 11.168A5.2 5.2 0 1 0 16.992.767a5.2 5.2 0 0 0 .001 10.4Z"
      fill="#EAEEF9"
    />
    <path
      d="M77.643 25.393H52.148v-9.676h33.604c5.222.124 9.461 4.438 9.461 9.676H84.156"
      fill="#CCD4E2"
    />
    <g filter="url(#a)">
      <path
        d="M86.489 15.717c-1.966.124-3.809.925-5.099 2.28a2.608 2.608 0 0 0-.368.432c-1.168 1.356-2.028 3.143-2.028 5.053v57.557c0 5.36-4.3 9.68-9.645 9.68H22.414V23.05c.123-1.972.86-3.76 2.15-5.053 1.413-1.418 3.318-2.281 5.468-2.281h56.457Z"
        fill="url(#b)"
      />
    </g>
    <path
      d="M69.655 28.906H55.71a.947.947 0 0 1-.92-.925c0-.493.43-.924.92-.924h13.945c.492 0 .922.43.922.924a.907.907 0 0 1-.922.925Zm-20.518 0H30.952a.947.947 0 0 1-.92-.925c0-.493.43-.924.92-.924h18.246c.491 0 .921.43.921.924 0 .493-.43.925-.982.925Zm11.673 9.121H42.687a.947.947 0 0 1-.921-.925c0-.493.43-.924.92-.924H60.81c.49 0 .922.43.922.924.06.493-.37.925-.922.925Z"
      fill="#B3BFD3"
    />
    <path
      d="M36.85 38.027h-5.898a.947.947 0 0 1-.92-.925c0-.493.43-.924.92-.924h5.898c.491 0 .921.43.921.924 0 .493-.43.925-.92.925Zm32.804 9.122H58.535a.947.947 0 0 1-.922-.93c0-.49.431-.92.922-.92h11.12c.491 0 .921.43.921.92 0 .5-.369.93-.922.93Z"
      fill="#D4DBEC"
    />
    <path
      d="M53.191 47.149H30.952a.946.946 0 0 1-.92-.93c0-.49.43-.92.92-.92h22.24c.491 0 .921.43.921.92 0 .5-.369.93-.922.93Zm7.62 9.12H48.586a.947.947 0 0 1-.922-.93c0-.49.43-.92.922-.92h12.225c.492 0 .922.43.922.92.061.5-.369.93-.922.93Z"
      fill="#B3BFD3"
    />
    <path
      d="M43.24 56.269H30.951a.946.946 0 0 1-.92-.93c0-.49.43-.92.92-.92H43.3c.492 0 .922.43.922.92 0 .5-.43.93-.983.93Z"
      fill="#D4DBEC"
    />
    <path
      d="M69.655 65.389h-8.539a.944.944 0 0 1-.92-.92c0-.5.43-.93.92-.93h8.54c.491 0 .921.43.921.93 0 .49-.369.92-.922.92Z"
      fill="#B3BFD3"
    />
    <path
      d="M55.587 65.389H41.273a.944.944 0 0 1-.921-.92c0-.5.43-.93.92-.93h14.315c.491 0 .921.43.921.93 0 .49-.43.92-.922.92Z"
      fill="#D4DBEC"
    />
    <path
      d="M35.928 65.389h-4.976a.944.944 0 0 1-.92-.92c0-.5.43-.93.92-.93h4.976c.492 0 .922.43.922.93 0 .49-.43.92-.922.92Zm24.882 9.119h-8.48a.944.944 0 0 1-.92-.92c0-.5.43-.93.92-.93h8.478c.491 0 .921.43.921.93.062.49-.368.92-.92.92Z"
      fill="#B3BFD3"
    />
    <path
      d="M46.741 74.508H30.952a.944.944 0 0 1-.92-.92c0-.5.43-.93.92-.93h15.79c.49 0 .92.43.92.93 0 .49-.43.92-.92.92Z"
      fill="#D4DBEC"
    />
    <path
      d="M48.707 83.078h12.41c0 2.09.86 4 2.211 5.36 1.351 1.42 3.256 2.22 5.344 2.22H12.83a7.582 7.582 0 0 1-5.344-2.22 7.618 7.618 0 0 1-2.212-5.36H42.01s6.82.06 6.697 0Z"
      fill="#CCD4E2"
    />
    <path
      d="m101.98 77.168-7.757-7.75 1.779-1.77 7.75 7.75-1.772 1.77Z"
      fill="#50B5FF"
    />
    <path
      d="m104.679 79.898-5.947-5.95a1.273 1.273 0 1 1 1.802-1.8l5.948 5.95c.497.5.497 1.3 0 1.8-.496.5-1.306.5-1.803 0Z"
      fill="#50B5FF"
    />
    <path
      d="m104.254 80.28-7.652-7.65a.462.462 0 0 1 0-.65l1.956-1.96a.478.478 0 0 1 .65 0l7.652 7.66a.461.461 0 0 1 0 .649l-1.956 1.95a.459.459 0 0 1-.65 0Z"
      fill="url(#c)"
    />
    <path
      d="M85.444 44.448c-8.119 0-14.698 6.58-14.698 14.7 0 8.11 6.58 14.69 14.692 14.69 8.118 0 14.697-6.57 14.697-14.69s-6.579-14.7-14.69-14.7Zm0 25.67c-6.518 0-10.798-4.36-10.798-10.98s4.28-10.97 10.798-10.97 10.797 4.36 10.797 10.98c0 6.61-4.285 10.97-10.797 10.97Z"
      fill="url(#d)"
    />
    <defs>
      <linearGradient
        id="b"
        x1={54.431}
        y1={13.983}
        x2={54.431}
        y2={91.529}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFEFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={96.464}
        y1={75.149}
        x2={106.996}
        y2={75.149}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#2196F3" />
        <stop offset={1} stopColor="#0062FF" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={70.733}
        y1={59.148}
        x2={100.141}
        y2={59.148}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#2196F3" />
        <stop offset={1} stopColor="#0062FF" />
      </linearGradient>
      <filter
        id="a"
        x={0.414}
        y={4.717}
        width={108.074}
        height={119.002}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19969_254713"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_19969_254713"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const SearchingResourcesIcon = memo(SvgComponent);
export default SearchingResourcesIcon;
