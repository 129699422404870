import { Box, Button, RadioGroup, withStyles } from '@material-ui/core';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { Skeleton as ListSkeleton } from '@setvi/shared/components/sdrawer/resources/list/skeleton';
import { Template, Link } from '@setvi/shared/interfaces';
import styled from 'styled-components';
import { Row } from './Row';
import {
  StyledList,
  useListStyles,
  useStyles
} from '../../presentations/style';
import { useList } from './useList';

interface ListProps {
  templates: Template[];
  isLoading: boolean;
  handleInsert(link: Link): void;
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 100px;
  height: 100%;
`;

const AddButton = withStyles(({ spacing, palette }) => ({
  root: {
    height: 40,
    marginBottom: spacing(1)
  },
  contained: {
    '&.Mui-disabled': {
      backgroundColor: palette.primary.light,
      color: palette.common.white
    }
  }
}))(Button);

const CancelButton = withStyles(({ palette }) => ({
  root: {
    background: palette.common.white,
    height: 40
  },
  contained: {
    '&.Mui-disabled': {
      backgroundColor: palette.common.white
    }
  }
}))(Button);

export const List = ({
  templates = [],
  isLoading,
  handleInsert
}: ListProps) => {
  const listClasses = useListStyles();
  const classes = useStyles();

  const { value, handleChange, onAdd, onCancel, setSelected } = useList({
    handleInsert
  });

  if (isLoading) return <ListSkeleton />;

  return (
    <Container>
      <RadioGroup
        aria-label="templates"
        name="selectedTemplate"
        value={value}
        onChange={handleChange}
        className={classes.radioGroup}>
        <StyledList>
          <AutoSizer>
            {({ height, width }: any) => (
              <FixedSizeList
                height={height}
                itemCount={templates.length}
                itemSize={72.16}
                itemData={{
                  // @ts-ignore
                  templates,
                  classes: listClasses,
                  setSelected
                }}
                overscanCount={5}
                width={width}>
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        </StyledList>
      </RadioGroup>
      <Box width="100%" p={2} boxShadow={1}>
        <AddButton
          variant="contained"
          color="primary"
          fullWidth
          disabled={!value}
          onClick={onAdd}>
          Add
        </AddButton>
        <CancelButton
          variant="contained"
          fullWidth
          disabled={!value}
          onClick={onCancel}>
          Cancel
        </CancelButton>
      </Box>
    </Container>
  );
};
