import {
  makeStyles,
  Box,
  Button,
  Theme,
  withStyles,
  Typography
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';

import { distances } from '@setvi/shared/styles';

import EmptyStatePresentationIcon from './EmptyStatePresentationIcon';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
});

export const WarningButton = withStyles((theme: Theme) => ({
  root: {
    color: '#fff',
    borderRadius: 7,
    backgroundColor: theme.palette.warning.main,
    boxShadow: '0px 7px 16px 1px rgba(205, 214, 225, 0.69)',
    padding: '12px 40px',
    fontWeight: 700,
    margin: `${distances.px.medium} 0px`,
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

export const EmptyStatePresentation = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <EmptyStatePresentationIcon />

      <Typography variant="subtitle1" color="textSecondary">
        No presentation available
      </Typography>
      {/* Will be added in phase 2 */}
      {/* <WarningButton variant="contained">Create Presentation</WarningButton> */}
    </Box>
  );
};
