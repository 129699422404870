import { memo } from 'react';
import { Box } from '@material-ui/core';
import SText from '../../sui/stext';

interface STreeNodeLabelProps<T> {
  item: T;
  title: string;
  actions?: (item: T) => JSX.Element;
}

const STreeNodeLabel = <T,>({
  item,
  title,
  actions
}: STreeNodeLabelProps<T>) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mr={2}>
    <SText style={{ userSelect: 'none' }} title={title} />
    {actions?.(item)}
  </Box>
);

export default memo(STreeNodeLabel);
