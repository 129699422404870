import { Box, ClickAwayListener } from '@material-ui/core';
import { useAlgolia } from '@setvi/shared/components/sresource/hooks/useAlgolia';
import { useCallback, useState } from 'react';
import { SSearchInput } from '@setvi/shared/components';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import SearchResults from './SearchResults';

const GlobalSearch = () => {
  const [query, setQuery] = useState<string>('');
  const [isSearchingInsideDocuments, setIsSearchingInsideDocuments] =
    useState<boolean>(false);

  const { algoliaKey, sasToken } = useAppContext();
  const {
    searchAlgoliaResources,
    resources,
    isLoading,
    isResultInsideDocuments
  } = useAlgolia({ sasToken, algoliaKey, hitsPerPage: 50 });

  const handleSearch = useCallback(
    (searchQuery: string) => {
      setQuery(searchQuery);
      searchAlgoliaResources(searchQuery, true, false);
      setIsSearchingInsideDocuments(false);
    },
    [searchAlgoliaResources]
  );

  const handleSearchInsideDocuments = useCallback(
    (searchQuery: string) => {
      searchAlgoliaResources(searchQuery, false, true);
      setIsSearchingInsideDocuments(true);
    },
    [searchAlgoliaResources]
  );

  const handleSearchClose = useCallback(() => {
    setQuery('');
  }, []);

  return (
    <ClickAwayListener onClickAway={() => handleSearchClose()}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        width="520px"
        zIndex={10}>
        <SSearchInput
          placeholder="Search resources..."
          width={520}
          iconPosition="start"
          loading={isLoading}
          searchedValue={query}
          onChange={value => handleSearch(value)}
        />

        {query && (
          <SearchResults
            resources={resources}
            isLoading={isLoading}
            searchQuery={query}
            isUserSearchingInsideDocuments={isSearchingInsideDocuments}
            onBackToQuickSearch={handleSearch}
            isResultInsideDocuments={isResultInsideDocuments}
            onSearchInsideDocuments={handleSearchInsideDocuments}
            handleSearchClose={handleSearchClose}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default GlobalSearch;
