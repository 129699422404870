import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SETVIImage } from 'Components/Shared/SETVIImage';

const useStyles = makeStyles(() => ({
  imageWrapper: {
    textAlign: 'center'
  },
  logo: {
    height: 48
  }
}));

export const Footer = () => {
  const classes = useStyles();

  return (
    <Box height={100}>
      <Typography
        align="center"
        style={{
          color: '#92929D',
          fontSize: '15px'
        }}>
        Powered by
      </Typography>
      <div className={classes.imageWrapper}>
        <SETVIImage
          className={classes.logo}
          src="https://setvi.azureedge.net/public/Setvi/Logos/SVG/FullColor/SETVI-Horizontal-Logo-RGB-FullColor_Transparent.svg"
          alt="Logo"
        />
      </div>
    </Box>
  );
};
