export enum AddResourceDefault {
  SINGLE_TITLE = 'Add Resource',
  SINGLE_DESCRIPTION = 'Enhance collaboration and accessibility by centralizing your resources.',
  BULK_TITLE = 'Bulk Add Resources',
  BULK_DESCRIPTION = 'Enabling you to efficiently upload multiple resources in one go. Accelerate your workflow by seamlessly importing and organizing content.',
  ZIP_TITLE = 'Zip Upload Resources',
  ZIP_DESCRIPTION = 'Enabling you to efficiently upload multiple resources in one go. Accelerate your workflow by seamlessly importing and organizing content.'
}

export enum EditResourceDefault {
  SINGLE_TITLE = 'Edit Resource',
  SINGLE_DESCRIPTION = 'Enhance collaboration and accessibility by centralizing your resources.',
  BULK_TITLE = 'Bulk Edit Resources',
  BULK_DESCRIPTION = 'Enabling you to efficiently upload multiple resources in one go. Accelerate your workflow by seamlessly importing and organizing content.',
  ZIP_TITLE = 'Zip Upload - Edit Resources',
  ZIP_DESCRIPTION = 'Enabling you to efficiently upload multiple resources in one go. Accelerate your workflow by seamlessly importing and organizing content.'
}

export enum AdminResourceSteps {
  'Resource Details' = 'Resource Details',
  'Rename Resources' = 'Rename Resources',
  'Upload Type' = 'Upload Type',
  'Multiple Categories' = 'Multiple Categories',
  'Permission' = 'Permission',
  'Comments & Tags' = 'Comments & Tags',
  'Notification' = 'Notification'
}

export enum ResourceValuesMax {
  Name = 100,
  Comment = 5000
}

export enum ResourceFormType {
  SINGLE = 1,
  BULK = 2,
  ZIP = 3
}
