import { useState, useEffect, ChangeEvent } from 'react';

import { Presentation } from '@setvi/shared/interfaces';

interface UsePresentationsProps {
  presentationId: number;
  presentations: Presentation[];
}

export const usePresentations = ({
  presentationId,
  presentations
}: UsePresentationsProps) => {
  const [value, setValue] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [selected, setSelected] = useState<any | null>(null);

  useEffect(() => {
    if (presentationId > 0) {
      setValue(presentationId.toString());
      setSelected(
        presentations.find(presentation => presentation.ID === presentationId)
      );
      setIsExpanded(true);
    }
  }, [presentationId, presentations]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    handleChange,
    isExpanded,
    setIsExpanded,
    selected,
    setSelected
  };
};
