import { Outlet, useLocation } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box } from '@material-ui/core';
import { useEffect } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { AuxProps } from '@setvi/shared/interfaces';
import DialogProvider from '@setvi/shared/providers/dialog';

import { ComposeEmailProvider } from 'Providers/ComposeEmail';
import { NylasProvider } from 'Providers/Nylas';
import { UserInfoDialogProvider } from 'Providers/UserInfoDialogProvider';
import { UploadResourcePanelProvider } from 'providersV2';

import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { useSidebar } from './Sidebar/useSidebar';
import { getPageTitle } from './utils/pageTitle';
import { useStyles } from './style';

const MainContainer = ({ children }: AuxProps) => (
  <NylasProvider>
    <IntercomProvider appId={process.env.INTERCOM_APP_ID}>
      <UploadResourcePanelProvider>
        <DialogProvider>
          <ComposeEmailProvider>
            <UserInfoDialogProvider>{children}</UserInfoDialogProvider>
          </ComposeEmailProvider>
        </DialogProvider>
      </UploadResourcePanelProvider>
    </IntercomProvider>
  </NylasProvider>
);

export const Main = () => {
  const classes = useStyles();
  const location = useLocation();
  const { expandedSidebarMenuItem, layout, handleSetExpandedMenu } =
    useSidebar();

  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location]);

  if (!layout)
    return (
      <MainContainer>
        <Outlet />
      </MainContainer>
    );

  return (
    <MainContainer>
      <div className={classes.root}>
        <CssBaseline />
        <Sidebar
          expandedSidebarMenuItem={expandedSidebarMenuItem}
          setExpandedSidebarMenuItem={handleSetExpandedMenu}
          collapsedDrawer
        />
        <main className={classes.container}>
          <Topbar />
          <Box className={classes.pageContainer}>
            <Outlet />
          </Box>
        </main>
      </div>
    </MainContainer>
  );
};
