import { Box } from '@material-ui/core';
import { SCheckbox, SText } from '@setvi/shared/components';
import React from 'react';
import { useLocationStyles } from './style';

interface LocationProps {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

export const Location = ({ title, onChange, checked }: LocationProps) => {
  const styles = useLocationStyles();
  return (
    <Box display="flex" alignItems="center" gridGap={10} p={2}>
      <SCheckbox checked={checked} onChange={onChange} />
      <SText className={styles.title} title={title} />
    </Box>
  );
};
