import { FC } from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';

interface HighlightedTextProps {
  value: string;
  searchQuery?: string;
  enableBoldStyle?: boolean;
  fontSize?: 'SM' | 'MD';
}

const Title = withStyles({
  root: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  }
})(Typography);

const useStyles = makeStyles(theme => ({
  highlight: {
    color: theme.palette.primary.main
  },
  boldText: {
    fontWeight: 600
  },
  textSM: {
    fontSize: 14
  },
  textMD: {
    fontSize: 16
  },
  title: {
    display: 'inline-block',
    maxWidth: '100%'
  }
}));

export const HighlightedText: FC<HighlightedTextProps> = ({
  value,
  searchQuery,
  enableBoldStyle,
  fontSize
}) => {
  const classes = useStyles();

  const index = searchQuery
    ? value.toLowerCase().indexOf(searchQuery.toLowerCase())
    : 0;

  return (
    <Tooltip title={value} arrow placement="top">
      {searchQuery && index !== -1 ? (
        <div>
          <Title
            variant="body1"
            className={clsx(
              classes.title,
              enableBoldStyle && classes.boldText,
              fontSize === 'SM' ? classes.textSM : classes.textMD
            )}>
            {value.slice(0, index)}
            <span className={classes.highlight}>
              {value.slice(index, index + searchQuery.length)}
            </span>
            {index + searchQuery.length < value.length &&
              value.slice(index + searchQuery.length, value.length)}
          </Title>
        </div>
      ) : (
        <Title
          variant="body1"
          className={clsx(
            classes.title,
            enableBoldStyle && classes.boldText,
            fontSize === 'SM' ? classes.textSM : classes.textMD
          )}>
          {value}
        </Title>
      )}
    </Tooltip>
  );
};
