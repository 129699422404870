import { Box } from '@material-ui/core';
import { usePaginatedData } from '../hooks/usePaginatedData';
import SPagination from '../components/pagination';
import { STableProps } from '../../../interfaces';
import TableContent from '../components/table';
import { useStyles } from '../styles';

const SPaginatedTable = <T, _B>({
  query,
  columns,
  queryVariables,
  select,
  onRowClick,
  rowKeyGetter,
  rowStyleGetter,
  customEmptyState
}: STableProps<T>) => {
  const classes = useStyles();
  const {
    tablePage,
    data,
    loading,
    totalCount,
    tableSearch,
    tableSize,
    sortedColumn,
    onSortChange,
    onPaginationChange
  } = usePaginatedData<T>({
    query,
    queryVariables
  });

  return (
    <Box className={classes.contentWrapper}>
      <TableContent
        data={{
          items: data,
          totalCount
        }}
        loading={loading}
        columns={columns}
        sortedColumn={sortedColumn}
        isSearch={tableSearch?.length > 0}
        overLimit={select?.overLimit}
        onRowClick={onRowClick}
        searchQuery={tableSearch}
        onSortItems={onSortChange}
        rowKeyGetter={rowKeyGetter}
        rowStyleGetter={rowStyleGetter}
        selectDisabledText={select?.selectDisabledText}
        isSelectable={!!select?.handleSelectedItems}
        selected={select?.selected}
        onSelect={select?.handleSelectedItems}
        selectDisabled={select?.selectDisabled}
        customEmptyState={customEmptyState}
      />
      {totalCount > 0 && (
        <SPagination
          totalItems={totalCount}
          itemsPerPage={tableSize}
          page={tablePage}
          disabled={loading}
          handleItemsPerPage={(items: string) =>
            onPaginationChange({ pageSize: Number(items) })
          }
          handlePageChange={newPage => onPaginationChange({ newPage })}
        />
      )}
    </Box>
  );
};

export default SPaginatedTable;
