import { Box, Typography, Tooltip } from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import {
  RefreshIcon as FileRetry,
  FileProcessingIcon as FileProcessing
} from '@setvi/shared/components/sicons';
import { UploadError } from '../index';
import { UploadStatus } from '../../usePanel';
import {
  ErrorBox,
  StyledVerticalDivider,
  RetryButton,
  useStyles,
  useTooltipsStyles
} from './style';

interface DetailsProps {
  status: UploadStatus;
  percent?: number;
  errorDetails?: UploadError;
  drawer?: boolean;
  retry?: () => void;
  cancel?: () => void;
}

export const Details = ({
  status,
  percent = 0,
  errorDetails,
  drawer: _,
  retry,
  cancel
}: DetailsProps) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipsStyles();

  switch (status) {
    case UploadStatus.PROCESSING:
      return (
        <>
          <Typography variant="subtitle2" className={classes.processingStatus}>
            Processing
          </Typography>
          <FileProcessing className={classes.processingIcon} />
        </>
      );
    case UploadStatus.COMPLETE:
      return (
        <>
          <Typography variant="subtitle2"> Completed </Typography>
          <CheckCircle className={classes.completeIcon} />
        </>
      );
    case UploadStatus.UPLOADING:
      return (
        <>
          <Typography variant="subtitle2" color="primary">
            {percent}%
          </Typography>
          <Cancel onClick={cancel} className={classes.cancelIcon} />
        </>
      );
    case UploadStatus.CANCELED:
      return (
        <>
          <Typography variant="subtitle2" className={classes.processingStatus}>
            Canceled
          </Typography>
          <FileRetry onClick={retry} className={classes.cancelIcon} />
        </>
      );
    case UploadStatus.ERROR:
      return (
        <Box display="flex">
          <Tooltip
            title={
              <>
                <Typography variant="subtitle2" color="textPrimary">
                  {errorDetails.message}. Please contact support
                </Typography>
                {errorDetails?.code && (
                  <Box display="flex">
                    <Typography variant="subtitle2" color="textSecondary">
                      Error code:
                    </Typography>
                    <Typography variant="subtitle2" color="error">
                      {errorDetails?.code}
                    </Typography>
                  </Box>
                )}
              </>
            }
            arrow
            classes={tooltipClasses}
            placement="top">
            <ErrorBox>
              <Typography variant="subtitle2" color="error">
                Error details
              </Typography>
            </ErrorBox>
          </Tooltip>

          <StyledVerticalDivider orientation="vertical" flexItem />

          <RetryButton
            onClick={retry}
            variant="contained"
            disableElevation
            endIcon={<FileRetry />}>
            Retry
          </RetryButton>
        </Box>
      );
    default:
      return <></>;
  }
};
