import { useMemo, ReactNode } from 'react';
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

interface ContentProps {
  children: ReactNode;
  header?: JSX.Element;
  backContent?: JSX.Element;
  search?: React.ReactNode;
}

interface DrawerRows {
  rows: number;
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

// This is necessary for the children container to occupy whole space after rows
const ContentContainer = withStyles({
  root: {
    minHeight: 0,
    height: '100%'
  }
})(Box);

const useStyles = makeStyles<Theme, DrawerRows>({
  containerTemplateRows: {
    gridTemplateRows: ({ rows }) => (rows ? `repeat(${rows}, auto) 1fr` : '1fr')
  }
});

export const Content = ({
  children,
  header,
  search,
  backContent
}: ContentProps) => {
  // Get number of rows before content
  const rows = useMemo(() => {
    let noOfRows = header ? 1 : 0;
    noOfRows = search ? noOfRows + 1 : noOfRows;
    noOfRows = backContent ? noOfRows + 1 : noOfRows;
    return noOfRows;
  }, [header, search, backContent]);

  const classes = useStyles({ rows });

  return (
    <Container className={classes.containerTemplateRows}>
      {header && <Box>{header}</Box>}

      {backContent && <Box>{backContent}</Box>}

      {search && <Box m={3}>{search}</Box>}

      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};
