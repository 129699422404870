import { Box, Divider, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { distances } from '../../styles';

type paddingType = 'small' | 'base' | 'medium' | 'xmedium' | 'large';

const getFontSize = (padding: paddingType) => {
  const fontSize: any = {
    small: 12,
    xmedium: 16,
    large: 16
  };
  return fontSize[padding] || 14;
};

const getLineHeight = (padding: paddingType) => {
  const lineHeight: any = {
    small: 2.5,
    base: 3.5,
    medium: 4,
    xmedium: 4.4
  };
  return lineHeight[padding] || 4.7;
};

const useStyles = makeStyles<Theme, { padding: paddingType }>(theme => ({
  tab: {
    textTransform: 'none',
    color: '#171725',
    fontWeight: 700,
    fontSize: ({ padding }) => getFontSize(padding),
    padding: ({ padding }) => distances.px[padding],
    lineHeight: '20px',
    width: 'max-content',
    minWidth: 'max-content'
  },
  activeTab: {
    color: theme.palette.primary.main
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: ({ padding }) => getLineHeight(padding),
    '& > span': {
      width: '100%',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

type Value = any;

export type STab = {
  label: string;
  value: Value;
};

interface TabsProps {
  currentTab: Value;
  tabsList: STab[];
  divider?: boolean;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  padding?: paddingType;
  handleTabChange: (value: Value) => void;
}

export const STabs = ({
  tabsList,
  currentTab = tabsList[0].value,
  divider = true,
  padding = 'medium',
  variant = 'standard',
  handleTabChange
}: TabsProps) => {
  const classes = useStyles({ padding });

  return (
    <Box>
      <Tabs
        variant={variant}
        value={currentTab}
        classes={{ indicator: classes.indicator }}
        onChange={(_, value) => handleTabChange(value)}
        TabIndicatorProps={{ children: <span /> }}>
        {tabsList?.map(tab => (
          <Tab
            key={tab.label}
            value={tab.value}
            label={tab.label}
            className={clsx(classes.tab, {
              [classes.activeTab]: currentTab === tab.value
            })}
          />
        ))}
      </Tabs>
      {divider ? <Divider /> : null}
    </Box>
  );
};
