import { TableCell, withStyles } from '@material-ui/core';

const TableHeaderCell = withStyles(({ spacing }) => ({
  root: {
    color: '#696974',
    padding: spacing(2),
    borderBottom: 'none',
    width: 'max-content',

    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px'
    },

    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px'
    },

    '& p': {
      display: 'inline',
      fontWeight: 700,
      fontSize: 13,
      color: '#696974'
    },
    '& button': {
      display: 'inline',
      padding: '5px',
      margin: '0px',

      '& svg': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }
  }
}))(TableCell);

export default TableHeaderCell;
