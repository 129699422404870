import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';

import { Link, WebLink } from '../../../../interfaces';
import { LinkObjectType } from '../../../../enums';
import { createWebLinkResourceMutation } from '../../../../services';

interface UseContentProps {
  handleInsert?: (link: Link) => void;
}

const normalizeCreatedWeblink = (webLink: WebLink): Link => ({
  Name: webLink.Name,
  Placeholder: uuidv4(),
  Item: {
    Items: [
      {
        ID: webLink.Id,
        Name: webLink.Name,
        LastChangedDate: new Date().toISOString(),
        ResourceTypeID: webLink.ResourceTypeId,
        ThumbURL: webLink.ThumbURLWithSas,
        ResourceTypeName: webLink.ResourceTypeName,
        ResourceID: webLink.ResourceId,
        ObjectType: LinkObjectType.Resources
      }
    ]
  }
});

export const useContent = ({ handleInsert }: UseContentProps) => {
  const { mutateAsync: createWebLink, isLoading: loading } = useMutation(
    createWebLinkResourceMutation()
  );

  const onInsertWebLink = useCallback(
    async (webText: string, url: string) => {
      const { Data } = await createWebLink({
        body: {
          Name: webText,
          Url: url
        }
      });

      if (!Data) return;

      const link: Link = normalizeCreatedWeblink(Data);

      handleInsert?.(link);
    },
    [createWebLink, handleInsert]
  );

  return { onInsertWebLink, loading };
};
