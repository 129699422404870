import { useState } from 'react';
import { Box, Slide, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DrawerContext } from './context';

export { Content } from './content';
export { useDrawerContext } from './context/useDrawerContext';

export const DRAWER_WIDTH = 320;

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'none',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: DRAWER_WIDTH,
    backgroundColor: theme.palette.common.white,
    zIndex: 1300,
    boxShadow: theme.shadows[16]
  }
}));

interface BaseProps {
  sasToken?: string;
  algoliaKey?: string;
  children: JSX.Element;
}

export const Base = ({ children, sasToken, algoliaKey }: BaseProps) => {
  const classes = useStyles();
  const [isSearching, setIsSearching] = useState(false);

  return (
    <Slide direction="left" in mountOnEnter unmountOnExit>
      <Box component={Paper} className={classes.container}>
        <DrawerContext.Provider
          value={{
            isSearching,
            setIsSearching,
            sasToken,
            algoliaKey
          }}>
          {children}
        </DrawerContext.Provider>
      </Box>
    </Slide>
  );
};
