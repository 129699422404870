import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ListContainer } from '..';

export const Loader = () => (
  <ListContainer>
    {Array(5)
      .fill(null)
      .map(() => (
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center">
          <Box>
            <Skeleton width={220} />
            <Skeleton width={120} />
          </Box>
          <Skeleton variant="rect" width={7} height={25} />
        </Box>
      ))}
  </ListContainer>
);
