import { Box } from '@material-ui/core';
import SPagination from '../components/pagination';
import TableContent from '../components/table';
import { useStyles } from '../styles';
import { useStaticPaginatedData } from '../hooks/useStaticPaginatedData';
import { STableProps } from '../../../interfaces';

type SStaticTableProps = Omit<STableProps, 'query' | 'queryVariables'>;

export interface SStaticPaginatedProps extends SStaticTableProps {
  data: any;
  input: any;
  loading?: boolean;
  removeItems?: any[];
}

const SStaticPaginatedTable = ({
  data,
  input,
  select,
  loading,
  columns,
  removeItems,
  customEmptyState,
  rowKeyGetter,
  rowStyleGetter,
  onRowClick
}: SStaticPaginatedProps) => {
  const {
    items,
    sortedColumn,
    onSortItems,
    page,
    rowsPerPage,
    changePageLimit,
    handlePageChange
  } = useStaticPaginatedData({
    data,
    input
  });

  const classes = useStyles();
  const selectable = !!select?.handleSelectedItems;

  return (
    <Box className={classes.contentWrapper}>
      <TableContent
        removeItems={removeItems}
        data={items}
        selected={select?.selected}
        loading={loading}
        rowStyleGetter={rowStyleGetter}
        columns={columns}
        isSelectable={selectable}
        sortedColumn={sortedColumn}
        customEmptyState={customEmptyState}
        isSearch={false}
        onSortItems={onSortItems}
        rowKeyGetter={rowKeyGetter}
        overLimit={select?.overLimit}
        onSelect={select?.handleSelectedItems}
        searchQuery={input?.searchString || ''}
        selectDisabled={select?.selectDisabled}
        onRowClick={onRowClick}
        selectDisabledText={select?.selectDisabledText}
      />

      {items.totalCount > 0 && (
        <SPagination
          totalItems={data?.totalCount}
          itemsPerPage={rowsPerPage}
          count={Math.ceil(data?.totalCount / rowsPerPage)}
          page={page}
          disabled={loading}
          handleItemsPerPage={changePageLimit}
          handlePageChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default SStaticPaginatedTable;
