import { Box } from '@material-ui/core';
import { Resource } from '@setvi/shared/interfaces';
import { Item } from './item';
import { usePanel } from './usePanel';
import { StyledList } from './style';

interface PanelProps {
  acceptedFiles: File[];
  drawer?: boolean;
  onCompleteUpload?: (resources: Resource[]) => void;
  setUploading?: (value: boolean) => void;
  categoryId?: number | null;
  canceled?: boolean;
  handleCancel?: () => void;
}

const UploadPanel = ({
  drawer,
  acceptedFiles,
  onCompleteUpload,
  setUploading,
  categoryId = null,
  canceled = false,
  handleCancel
}: PanelProps) => {
  const { dispatch } = usePanel({
    onCompleteUpload,
    setUploading,
    canceled,
    handleCancel
  });

  return (
    <Box maxHeight="100%" overflow="auto">
      <StyledList>
        {acceptedFiles?.map(file => (
          <Item
            key={file.name}
            file={file}
            drawer={drawer}
            dispatch={dispatch}
            setUploading={setUploading}
            categoryId={categoryId}
          />
        ))}
      </StyledList>
    </Box>
  );
};

export default UploadPanel;
