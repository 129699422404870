import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={195}
    height={163}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M98.416 138.389c29.7 0 53.9-24.1 53.9-53.7 0-29.7-24.1-53.7-53.9-53.7-29.8-.1-53.9 23.9-53.9 53.6 0 29.7 24.1 53.8 53.9 53.8Z"
      fill="#EAEEF9"
    />
    <path
      d="m88.914 65.889-1.4-3.9c-.3-1-1.3-1.6-2.3-1.6h-24.6c-2.1 0-3.7 1.6-3.7 3.7v55.1-9.1h84v-38.9c0-2.1-1.6-3.7-3.7-3.7h-46c-1 0-1.9-.6-2.3-1.6Z"
      fill="url(#a)"
    />
    <g filter="url(#b)">
      <path
        d="m59.315 34.088 16.8 29.2c.5 1 .2 2.3-.8 2.8l-28.6 16c-1 .5-2.3.2-2.8-.8l-21.1-37.8c-.7-1-.2-2.3.8-2.8l20.8-11.6 14.9 5Z"
        fill="#fff"
      />
    </g>
    <path
      d="m44.215 37.188-13.8 7.7c-.3.2-.8.1-.9-.3-.2-.3-.102-.8.198-.9l13.8-7.6c.3-.2.8-.1.9.2.2.3.102.8-.198.9Zm-6.698 8.1-5.3 2.9c-.3.2-.7.1-.8-.2-.2-.3-.1-.8.2-.9l5.2-2.9c.3-.2.8-.1.9.2.3.2.2.7-.2.9Zm6.897-16.2 4.6 8.1c.7 1.1 2.2 1.5 3.3.9l7.3-4m1.4 17.801c-.9-1.8-2.1-3.9-3.6-5.4-.8-.8-1.8-1.3-3-1.3s-2.3.4-3.5 1c-2.1.9-4 1.8-5.9 3-1.7 1-3.7 2.1-5 3.7-1.7 2.3-.8 4.6.4 7 .9 1.8 2.1 3.9 3.6 5.4 1.2 1.2 2.8 1.6 4.4 1.1 2.1-.5 6-2.7 7.8-3.8 1.6-1 3.5-1.9 4.7-3.4 2.3-2.3 1.5-4.7.1-7.3Zm-14 2.5c-.3-.6-.1-1.1.7-1 2.1.1 4.1.1 6.1.2.8 0 1 .4.7 1.1-1 1.7-2 3.5-3 5.3-.4.6-.9.6-1.2.1-.3-.3-3-5.2-3.3-5.7Z"
      fill="#D5DDEA"
    />
    <path
      d="m122.913 85.088-.9-.6c-.2-.2-.2-.4 0-.6.2-.2.4-.2.7-.1l.9.6c.2.1.2.4.1.6-.3.2-.6.2-.8.1Zm-2.397-1.899-5.1-4c-.2-.1-.2-.4-.1-.6.1-.2.4-.2.7-.1l5.1 4c.2.1.2.4.1.6-.2.2-.5.3-.7.1Z"
      fill="#E3EAF2"
    />
    <g filter="url(#c)">
      <path
        d="m172.414 57.489-19.1 24.7c-.6.9-1.8 1-2.7.3l-23.8-18.7c-.9-.6-1-1.8-.3-2.7l24.8-31.5c.6-.9 1.8-1 2.7-.3l17.3 13.6 1.1 14.6Z"
        fill="#fff"
      />
    </g>
    <path
      d="m164.314 45.589-11.5-9c-.2-.2-.3-.5-.1-.9.2-.2.5-.3.9-.1l11.5 9c.2.2.3.5.1.9-.3.3-.6.3-.9.1Zm-9.2-2.801-4.3-3.5c-.2-.2-.3-.5-.1-.9.2-.3.5-.3.9-.1l4.301 3.5c.2.2.299.5.099.9-.3.3-.7.3-.9.1Zm16.201.101-5.3 6.7c-.8 1-.5 2.4.4 3.1l6.1 4.8m-7.503 1.499-6-4.6-6.2-4.9-5.7-4.5c-.6-.5-1.6-.4-2.1.3l-8.2 10.4c-.5.6-.4 1.6.2 2.1l18 14.2c.6.5 1.6.4 2.1-.3l8.2-10.4c.5-.9.4-1.7-.3-2.3Zm-19-.1 1.8-2.4 5.5 4.3-1.8 2.4-5.5-4.3Zm5 5-1.7 2.2-5.5-4.3 1.7-2.2 5.5 4.3Zm4.7-6.1-1.8 2.4-5.5-4.3 1.8-2.4 5.5 4.3Zm-5-5 2-2.4 5.5 4.3-1.8 2.4-5.7-4.3Zm10 12.7-6.1-4.8 1.8-2.4 6.1 4.8-1.8 2.4Zm-13-10.2-5.9-4.6 1.8-2.4 5.9 4.6-1.8 2.4Zm-6.5-3.9 5.9 4.6-1.8 2.4-5.9-4.6 1.8-2.4Zm12.9 10 6.1 4.8-1.8 2.4-6.1-4.8 1.8-2.4Zm10.5-.8-1.5 1.9-6.1-4.8 1.8-2.4 5.6 4.4c.4.2.4.6.2.9Zm-18.1-15.1 5.4 4.3-1.8 2.4-5.9-4.6 1.5-1.9c.2-.3.6-.3.8-.2Zm-9 10.6 1.3-1.6 5.9 4.6-1.7 2.2-5.5-4.3c-.2-.2-.2-.7 0-.9Zm18.1 14.9-5.6-4.4 1.7-2.2 6.1 4.8-1.4 1.7c-.2.4-.6.4-.8.1Z"
      fill="#D5DDEA"
    />
    <path
      d="m115.813 81.288-5.7-.6c-.3 0-.499-.3-.399-.6 0-.3.3-.5.7-.4l5.699.6c.3 0 .501.3.401.6-.1.3-.401.6-.701.4Z"
      fill="url(#d)"
    />
    <path
      d="m142.616 86.689-6.2 27.7c-.4 1.6-2.3 2.9-4.7 2.9h-67.3c-2.3 0-4.3-1.2-4.7-2.9l-5.1-22.7c-.4-1.7 1.5-3.3 3.9-3.3h38c1.2 0 2.1-.6 2.5-1.3l1.1-2.4c.4-.8 1.4-1.3 2.5-1.3h36.2c2.3 0 4.1 1.6 3.8 3.3Z"
      fill="#fff"
    />
    <g filter="url(#e)">
      <path
        d="m147.516 82.988-6.9 42.5c-.4 2.5-2.6 4.4-5.2 4.4h-75c-2.6 0-4.8-1.8-5.2-4.4l-5.7-34.9c-.4-2.6 1.6-5.1 4.3-5.1h42.3c1.3 0 2.4-.9 2.8-2.1l1.2-3.7c.4-1.2 1.5-2.1 2.8-2.1h40.3c2.6.3 4.7 2.7 4.3 5.4Z"
        fill="url(#f)"
      />
    </g>
    <g filter="url(#g)">
      <path
        d="m124.716 27.388-3.2 34.7c-.1 1.2-1.2 2.1-2.4 1.9l-33.8-3.5c-1.2-.1-2.1-1.2-2-2.4l4.6-44.5c.1-1.2 1.2-2.1 2.4-1.9l24.5 2.5 9.9 13.2Z"
        fill="#fff"
      />
    </g>
    <path
      d="m114.913 14.288-1 9.5c-.1 1.3.9 2.6 2.3 2.7l8.6.9"
      fill="#D5DDEA"
    />
    <path
      d="m118.114 52.688-27.7-3.1c-.7-.1-1.2-.6-1.1-1.3l2.1-17.7c.1-.6.7-1.2 1.3-1.1l27.7 3c.7.1 1.2.6 1.1 1.3l-2.1 17.7c-.1.7-.7 1.2-1.3 1.2Z"
      fill="#ECF0F8"
    />
    <path d="m105.612 48.389-12.8-1.5 7.2-6.5 5.6 8Z" fill="#D5DDEA" />
    <path d="m116.215 49.588-18.5-2.2 10.5-9.3 8 11.5Z" fill="#fff" />
    <path
      d="M99.016 37.588c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5Zm10.697-17.5-16.8-1.9c-.2 0-.4-.2-.3-.4v-.2c0-.2.2-.4.4-.3l16.8 1.9c.2 0 .4.2.3.4v.2c.1.2-.1.4-.4.3Zm-.097 3.2-17-1.9c-.2 0-.4-.2-.3-.4v-.2c0-.2.2-.4.4-.3l17 1.9c.2 0 .4.2.3.4v.2c.1.1-.2.3-.4.3Zm.598 32.701-21.1-2.4c-.3 0-.5-.3-.4-.6 0-.3.3-.5.7-.4l21 2.4c.3 0 .499.3.399.6-.1.3-.299.5-.599.4Zm-3.101-29.701-14.8-1.7c-.3 0-.5-.3-.4-.6 0-.3.3-.5.7-.4l14.8 1.7c.3 0 .5.3.4.6-.1.2-.3.4-.7.4Zm10.901 33.801-29.4-3.5c-.3 0-.5-.3-.4-.6 0-.3.3-.5.7-.4l29.399 3.4c.3 0 .501.3.401.6-.1.3-.4.5-.7.5Zm-30.5-53.001c-.4.2-.9 0-1.1-.4l-1.8-4.5c-.2-.4 0-.9.4-1.1.4-.2.9 0 1.1.4l1.8 4.5c.3.6.1 1-.4 1.1Zm-3.298 4.4c-.2.2-.4.3-.8.2l-4.7-.9c-.4-.1-.8-.5-.7-1 .1-.4.5-.8 1-.6l4.7.9c.4.1.8.5.7 1-.1.1-.1.3-.2.4Zm9.496-4.9c-.1 0-.2-.1-.4-.1-.3-.3-.4-.8-.1-1.2l2.9-3.8c.3-.3.8-.4 1.2-.1.3.3.4.8.1 1.2l-2.9 3.8c-.3.1-.6.2-.8.2Z"
      fill="#D5DDEA"
    />
    <path
      d="M118.616 123.189c-.4 1.7-1 3.5-1.7 5-1.9 3.7-4.9 6.6-8.6 8.5-3.8 1.9-8.3 2.7-12.8 1.7-10.6-2.2-17.4-12.6-15.2-23.2 2.2-10.6 12.5-17.5 23.1-15.2 3.8.8 7.1 2.7 9.9 5.3 4.7 4.7 6.7 11.5 5.3 17.9Z"
      fill="url(#h)"
    />
    <path
      d="M105.713 117.488h-4.5v-4.5c0-.9-.7-1.7-1.7-1.7-.9 0-1.7.7-1.7 1.7v4.5h-4.5c-.9 0-1.7.7-1.7 1.7s.7 1.7 1.7 1.7h4.5v4.5c0 .9.7 1.7 1.7 1.7.9 0 1.7-.7 1.7-1.7v-4.5h4.5c.9 0 1.7-.7 1.7-1.7s-.8-1.7-1.7-1.7Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="a"
        x1={98.921}
        y1={62.432}
        x2={98.921}
        y2={95.436}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#B0BACC" />
        <stop offset={1} stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={109.583}
        y1={80.07}
        x2={116.407}
        y2={80.857}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#83A6FF" />
        <stop offset={1} stopColor="#5A78FF" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={98.483}
        y1={76.379}
        x2={98.483}
        y2={130.452}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFEFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={99.481}
        y1={100.911}
        x2={99.481}
        y2={122.956}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF974A" />
        <stop offset={1} stopColor="#FAA05D" />
      </linearGradient>
      <filter
        id="b"
        x={0.488}
        y={18.088}
        width={97.863}
        height={97.237}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_15518_187540"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_15518_187540"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        x={104.055}
        y={17.829}
        width={90.359}
        height={98.119}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_15518_187540"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_15518_187540"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        x={27.465}
        y={66.588}
        width={142.102}
        height={96.3}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_15518_187540"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_15518_187540"
          result="shape"
        />
      </filter>
      <filter
        id="g"
        x={61.309}
        y={0.66}
        width={85.406}
        height={96.356}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_15518_187540"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_15518_187540"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const EmptyStatePresentationIcon = memo(SvgComponent);
export default EmptyStatePresentationIcon;
