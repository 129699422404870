import {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import {
  AxiosMethods,
  CategoryApi,
  RESOURCE_PAGINATION
} from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import { Categories } from '../../../../interfaces';

export enum CategoryQueryKey {
  COMPANY_CATEGORY = 'company_category',
  USER_CATEGORY = 'user_category',
  BREADCRUMB = 'breadcrumb',
  CATEGORY = 'category'
}

interface ResourceQueryVariables {
  categoryId: number;
  pageNumber: number;
  pageSize: RESOURCE_PAGINATION;
}

export const getBreadcrumbsQuery = (
  Id: string | number = ''
): UseQueryOptions<any[]> => ({
  queryKey: [CategoryQueryKey.BREADCRUMB, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: `${CategoryApi.GetBreadcrumbs}/${Id}`,
      method: AxiosMethods.GET
    }),
  keepPreviousData: true,
  select: (res: any) => res?.Data
});

export const getUserCategoryQuery = (
  input?: ResourceQueryVariables
): UseInfiniteQueryOptions<Categories> => ({
  queryKey: [CategoryQueryKey.USER_CATEGORY, input],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: CategoryApi.GetUserCategories,
      method: AxiosMethods.GET,
      params: { ...input, pageNumber: data.pageParam || input.pageNumber }
    }),
  getNextPageParam: (lastPage: Categories, allPages: Categories[]) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );

    const currentPageNumber = total / input.pageSize;

    return lastPage?.Data?.Result?.length < input.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getCompanyCategoryQuery = (
  input?: ResourceQueryVariables
): UseInfiniteQueryOptions<Categories> => ({
  queryKey: [CategoryQueryKey.COMPANY_CATEGORY, input],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: CategoryApi.GetCompanyCategories,
      method: AxiosMethods.GET,
      params: { ...input, pageNumber: data.pageParam || input.pageNumber }
    }),
  getNextPageParam: (lastPage: Categories, allPages: Categories[]) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );

    const currentPageNumber = total / input.pageSize;

    return lastPage?.Data?.Result?.length < input.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});
