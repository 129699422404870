import { AxiosMethods, CompanyApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { CompanyResponse } from './Types';

export enum CompanyQueryKey {
  CREDENTIALS = 'company_credentials',
  SETTINGS = 'company_settings'
}

export const companyCredentialsQuery = () => ({
  queryKey: [CompanyQueryKey.CREDENTIALS],
  queryFn: () =>
    axiosHelper({
      endpoint: CompanyApi.CompanyCredentials,
      method: AxiosMethods.POST
    }),
  select: (res: any) => res?.Data[0] || ''
});

export const getCompanySettingsQuery = (companyId: string) => ({
  queryKey: [CompanyQueryKey.SETTINGS, companyId],
  queryFn: () =>
    axiosHelper({
      endpoint: CompanyApi.GetCompanySettings,
      baseURL: process.env.ADMIN_API_BASE_URL,
      method: AxiosMethods.GET,
      params: {
        companyId
      }
    }),
  select: (res: CompanyResponse) => res?.data
});
