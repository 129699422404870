import { memo } from 'react';
import { SDropdownMenu } from '@setvi/shared/components';
import { Resource } from '@setvi/shared/interfaces';
import useDropdownResource, {
  DropdownItemsType
} from 'Components/Shared/Resources/DropdownMenu/useDropdownMenu';

interface DropdownMenuProps {
  item: Resource;
  dropdownItems?: DropdownItemsType;
  refetchLoading?: boolean;
}

const DropdownMenu = ({
  item,
  dropdownItems,
  refetchLoading
}: DropdownMenuProps) => {
  const { createDropdownMenu } = useDropdownResource(refetchLoading);

  return <SDropdownMenu menuItems={createDropdownMenu(item, dropdownItems)} />;
};

export default memo(DropdownMenu);
