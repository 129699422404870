import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, IconButton, Badge } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { SText } from '@setvi/shared/components';
import { Notifications } from 'Components/Shared/Icons';
import { NotificationsPanel } from 'Components/Shared/NotificationsPanel';
import { ProfileDropdown } from 'Components/Shared';
import { useNotificationsPanel } from 'Components/Shared/NotificationsPanel/useNotificationsPanel';
import { ROUTES } from 'enumsV2';
import GlobalSearch from './GlobalSearch';
import { useNavbarStyles } from './style';

const StyledBadge = withStyles({
  badge: {
    marginRight: 2,
    marginTop: 1
  }
})(Badge);

const NavButtonsWrapper = withStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}))(Box);

export const Topbar = () => {
  const classes = useNavbarStyles();
  const location = useLocation();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [title, setTitle] = useState(document.title);
  const {
    list,
    loading,
    hasMore,
    haveUnread,
    fetchMoreNotifications,
    getPrefixForDayEnty,
    markAllNotificationsRead
  } = useNotificationsPanel();

  const handleBellClick = () => {
    markAllNotificationsRead({});
    setIsNotificationsOpen(prevProps => !prevProps);
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setTitle(document.title);
    });
    observer.observe(document.querySelector('title'), {
      subtree: true,
      characterData: true,
      childList: true
    });
    return () => observer?.disconnect();
  }, []);

  const displaySearch: boolean =
    location.pathname === ROUTES.HOME ||
    location.pathname === ROUTES.RESOURCES ||
    location.pathname === ROUTES.WORKSPACES_FILES;

  return (
    <>
      <Box className={classes.root}>
        <Box flexShrink={0} mr={2}>
          <SText size="xl" weight="bold" title={title} />
        </Box>
        {displaySearch && <GlobalSearch />}
        <NavButtonsWrapper>
          <IconButton onClick={handleBellClick}>
            <StyledBadge
              color="secondary"
              overlap="circular"
              badgeContent=" "
              invisible={!haveUnread}
              variant="dot">
              <Notifications />
            </StyledBadge>
          </IconButton>
          <ProfileDropdown />
        </NavButtonsWrapper>
      </Box>
      <NotificationsPanel
        isOpen={isNotificationsOpen}
        setIsOpen={setIsNotificationsOpen}
        list={list}
        loading={loading}
        hasMore={hasMore}
        fetchMore={fetchMoreNotifications}
        getPrefixForDayEnty={getPrefixForDayEnty}
      />
    </>
  );
};
