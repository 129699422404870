import { Box, withStyles } from '@material-ui/core';

export const CenterBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box);

export const DropAreaContainer = withStyles(({ spacing }) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(0, 3)
  }
}))(Box);

export const DropArea = withStyles(({ palette, spacing }) => ({
  root: {
    width: '100%',
    height: '100%',
    border: `2px ${palette.divider} dashed`,
    borderRadius: 5,
    margin: spacing(3, 0)
  }
}))(CenterBox);
