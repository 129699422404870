import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import SLabel from '@setvi/shared/components/sui/slabel';

import SText from '../sui/stext';
import { ContentContainer } from './style';
import { useFilestack } from '../../hooks';
import { SImageUploadProps } from './interfaces';
import { Loading, Placeholder, Content } from './components';

const SImageUpload = ({
  tags,
  name,
  value,
  label = 'Upload Image',
  required = false,
  loading: loadingProp = false,
  resolution,
  customPlaceholder,
  placeholderOptions,

  onChange
}: SImageUploadProps) => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(loadingProp);
  const [image, setImage] = useState<string | null>(value || null);

  useEffect(() => {
    if (value !== image) setImage(value || null);
  }, [image, value]);

  const { fileStackUpload } = useFilestack();

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
      'image/gif': []
    },
    onDropAccepted: async files => {
      setLoading(true);

      const currentFile = files[0];

      const getProgress = (total: number) => {
        setProgress(total);
      };

      const { url, handle } = await fileStackUpload({
        file: currentFile,
        tags,
        getProgress
      });

      if (url && handle) {
        setProgress(100);

        // Some times file is small and there is no time to update progress so l added delay
        setTimeout(() => {
          setProgress(0);
          setImage(url);
          setLoading(false);
          onChange({ name, url, id: handle });
        }, 400);
      }
    }
  });

  return (
    <Box width="100%" height="100%">
      <SLabel
        title={
          <Box display="flex" alignItems="center" gridGap={4}>
            <SText weight="bold" size="sm">
              {label}
            </SText>
            <SText weight="bold" size="sm" fontColor="#696974">
              {resolution}
            </SText>
          </Box>
        }
        required={required}
        variant="body2"
      />
      <ContentContainer
        border={`2px dashed ${!loading && !image ? '#E6E7E9' : '#50B5FF'}`}
        {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Loading loading={loading} progress={progress} />
        {!loading &&
          !image &&
          (customPlaceholder || (
            <Placeholder {...placeholderOptions} open={open} />
          ))}
        <Content loading={loading} image={image} open={open} />
      </ContentContainer>
    </Box>
  );
};

export default SImageUpload;
