import { Box, IconButton } from '@material-ui/core';
import {
  GetResourceTypeIcon,
  GetResourceTypeName
} from '@setvi/shared/utils/resources';
import { LinkItemItems } from '@setvi/shared/interfaces';
import { DangerIcon } from '@setvi/shared/components/sicons';
import { ResourceItem } from './ResourceItem';

interface ItemProps {
  resource: LinkItemItems;
  removeItem?: (resource: LinkItemItems) => void;
  search?: string;
}

export const Item = ({ resource, removeItem, search }: ItemProps) => (
  <ResourceItem
    item={resource}
    search={search}
    itemType={
      <Box display="flex">
        <img width={20} src={GetResourceTypeIcon(resource.ResourceTypeID)} />
        {GetResourceTypeName(resource.ResourceTypeID)}
      </Box>
    }
    itemAction={
      removeItem && (
        <IconButton size="small" onClick={() => removeItem(resource)}>
          <DangerIcon />
        </IconButton>
      )
    }
  />
);
