import { FC } from 'react';
import { List as MuiList } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SkeletonItem } from './SkeletonItem';

export { SkeletonItem } from './SkeletonItem';

const StyledList = withStyles({
  root: {
    height: '100%',
    overflow: 'hidden'
  }
})(MuiList);

export const Skeleton: FC = () => (
  <StyledList>
    {Array.from(new Array(10)).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <SkeletonItem key={`skeleton-${i}`} />
    ))}
  </StyledList>
);
