import { Box, Typography, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from '@setvi/shared/interfaces';
import { SPreviewEditor } from '@setvi/shared/components';
import { TContact } from '@setvi/shared/components/sdrawer/contacts/contact';
import { usePreview } from './usePreview';
import { TextWrapper, Title, useStyles } from './style';
import { parseHtmlForPreview } from '../../utils';

export interface SPreviewProps {
  emailSubject: string;
  emailBody: string;
  variables?: any[];
  links?: Link[];
  selectedRecipient?: TContact;
  showRecipientValues?: boolean;
}

const SPreview = ({
  emailSubject,
  emailBody,
  variables = [],
  links = [],
  selectedRecipient,
  showRecipientValues = false
}: SPreviewProps) => {
  const classes = useStyles();

  const { htmlPreviewSubject, htmlPreviewBody } = usePreview({
    emailSubject,
    emailBody,
    variables,
    links,
    selectedRecipient,
    showRecipientValues
  });

  return (
    <>
      <Box mt={2} mb={2}>
        <Box mb={1}>
          <Title variant="body2">Subject</Title>
        </Box>
        <TextWrapper>
          <Typography
            display="inline"
            className={clsx(
              classes.subjectContainer,
              !showRecipientValues && classes.variableStyles
            )}>
            {htmlPreviewSubject}
          </Typography>
        </TextWrapper>
      </Box>
      <Divider />
      <Box mt={2}>
        <Box mb={1}>
          <Title variant="body2">Email body</Title>
        </Box>
        <TextWrapper>
          <SPreviewEditor
            content={
              showRecipientValues
                ? parseHtmlForPreview(htmlPreviewBody, true)
                : htmlPreviewBody
            }
            variables={variables}
            showVariableValues={showRecipientValues}
            recipient={selectedRecipient}
          />
        </TextWrapper>
      </Box>
    </>
  );
};

export default SPreview;
