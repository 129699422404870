import { FC, useMemo, useCallback } from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { generateLinkViewerUrl } from '@setvi/shared/utils/viewer';
import { Link } from '@setvi/shared/interfaces';
import { Menu } from '../Menu';
import SText from '../../../sui/stext';
import { getPreviewLink } from '../../../../utils';

interface LinkItemProps {
  onEdit(): void;
  onDelete(): void;
  link: Link;
}

const LinkContainer = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${spacing(2)}px ${spacing(3)}px`,
    cursor: 'pointer'
  }
}))(Box);

const useStyles = makeStyles(theme => ({
  linkIcon: {
    marginRight: theme.spacing(1)
  },
  editIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    height: 20
  },
  bold: {
    fontWeight: 'bold'
  },
  gray: {
    color: '#92929D'
  },
  textWrapper: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  }
}));

export const LinkItem: FC<LinkItemProps> = ({ onEdit, onDelete, link }) => {
  const classes = useStyles();

  const resourceCount = useMemo(() => link?.Item?.Items?.length, [link]);

  const openPreview = useCallback(
    () =>
      window
        .open(generateLinkViewerUrl(getPreviewLink(link)), '_blank')
        .focus(),
    [link]
  );

  return (
    <LinkContainer onClick={onEdit}>
      <Box>
        <Box display="flex">
          <SText
            variant="body1"
            showTooltip
            className={classes.bold}
            title={link.Name}
            maxLines={2}
          />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {resourceCount} resources
        </Typography>
      </Box>
      <Box onClick={e => e.stopPropagation()}>
        <Menu onPreview={openPreview} onEdit={onEdit} onDelete={onDelete} />
      </Box>
    </LinkContainer>
  );
};
