import { useState } from 'react';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { DrawerContent } from '@setvi/shared/enums/common';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { TContact } from '@setvi/shared/components/sdrawer/contacts/contact';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Contacts } from '@setvi/shared/components/sdrawer/crm/accounts/contacts';
import { SEmptyState, SInfiniteScroll } from '@setvi/shared/components';
import { Account as AccountProps } from '@setvi/shared/services/react-query/query/user/types';
import { Account } from './account';
import { useAccounts } from './useAccounts';
import { SalesforceUser } from '../../contacts-navigation';
import { LocationSelect } from './location-select';
import { useLocation } from './location-select/useLocation';
import { Recipient } from '../../../../interfaces';

interface CrmAccountsProps {
  salesforceUser: SalesforceUser;
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

const useAccountStyles = makeStyles(() => ({
  loader: {
    textAlign: 'center'
  }
}));

export const CrmAccounts = ({
  salesforceUser,
  selectedContacts,
  modifyRecipientList,
  setShowContent,
  handleClose
}: CrmAccountsProps) => {
  const [selectedAccount, setSelectedAccount] = useState<AccountProps | null>(
    null
  );
  const {
    states,
    statesLoading,
    stateSearchTerm,
    setStateSearchTerm,
    selectedStateCodes,
    setSelectedStateCodes,

    cities,
    citiesLoading,
    selectedCities,
    citySearchTerm,
    setCitySearchTerm,
    setSelectedCities
  } = useLocation({
    salesforceUser
  });
  const { accounts, isLoading, fetchMoreAccounts, setSearchTerm, searchTerm } =
    useAccounts({
      salesforceUser,
      state: selectedStateCodes,
      city: selectedCities
    });
  const styles = useAccountStyles();

  if (selectedAccount)
    return (
      <Contacts
        account={selectedAccount}
        handleClose={handleClose}
        modifyRecipientList={modifyRecipientList}
        onBack={() => setSelectedAccount(null)}
        selectedContacts={selectedContacts}
      />
    );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Recipients" hasClose handleClose={handleClose} />
      <BaseSubheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="CRM Accounts"
        bottomDivider
      />
      <Box p={2} mb={1}>
        <SSearchInput
          defaultValue={searchTerm}
          onChange={value => setSearchTerm(value)}
        />
      </Box>
      <LocationSelect
        states={states}
        statesLoading={statesLoading}
        stateSearchTerm={stateSearchTerm}
        selectedStateCodes={selectedStateCodes}
        setSelectedStateCodes={setSelectedStateCodes}
        setSteateSearchTerm={setStateSearchTerm}
        cities={cities}
        citiesLoading={citiesLoading}
        citySearchTerm={citySearchTerm}
        selectedCities={selectedCities}
        setCitySearchTerm={setCitySearchTerm}
        setSelectedCities={setSelectedCities}
      />
      <Box overflow="auto">
        <SInfiniteScroll
          list={accounts}
          keyGetter={account => account.Id}
          fetchMore={fetchMoreAccounts}
          render={account => (
            <Account
              account={account}
              onClick={() => {
                setSelectedAccount(account);
              }}
            />
          )}>
          <>
            {isLoading && (
              <Typography className={styles.loader} variant="h6">
                Loading...
              </Typography>
            )}
            {!isLoading && accounts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-contacts.png"
                subTitle="No Accounts available"
              />
            )}
          </>
        </SInfiniteScroll>
      </Box>
    </Box>
  );
};
