import _ from 'lodash';

import { SSearchInput as SearchInput } from '@setvi/shared/components/sforms/ssearch';
import { Content as ContentContainer } from '@setvi/shared/components/sdrawer/base';
import { BaseSubheader } from '@setvi/shared/components/sdrawer/base/subheader';
import { Header } from '@setvi/shared/components/sdrawer/header';
import { Link } from '@setvi/shared/interfaces';

import { List } from '../list';
import { useContent } from './useContent';

interface ContentProps {
  handleBack(): void;
  handleInsert(link: Link): void;
  handleClose(): void;
}

export const Content = ({
  handleBack,
  handleInsert,
  handleClose
}: ContentProps) => {
  const { searchPresentations, data, loading, searchString } = useContent();

  return (
    <ContentContainer
      header={
        <Header title="Add from SETVI" hasClose handleClose={handleClose} />
      }
      search={
        <SearchInput
          onChange={searchPresentations}
          loading={loading}
          placeholder="Search..."
        />
      }
      backContent={
        <BaseSubheader handleBack={handleBack} text="Presentations" />
      }>
      <List
        presentations={data?.items}
        isLoading={loading}
        handleInsert={handleInsert}
        isSearching={!!searchString?.length}
      />
    </ContentContainer>
  );
};
