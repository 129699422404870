import { AxiosMethods, AdminCategoryApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { AdminCategory } from 'Services/Query/Admin/Category/Types';

export enum AdminCategoryQueryKey {
  ADMIN_CATEGORIES = 'ADMIN_CATEGORIES',
  ADMIN_GROUP_CATEGORIES = 'ADMIN_GROUP_CATEGORIES',
  ADMIN_CATEGORY_DETAILS = 'ADMIN_CATEGORY_DETAILS'
}

export const getAdminCategoriesQuery = (Id: string | number) => ({
  queryKey: [AdminCategoryQueryKey.ADMIN_CATEGORIES, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminCategoryApi.GetCategories.replace(':id', Id.toString()),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: { data: { items: AdminCategory[] } }) => {
    const nestedArray = res?.data?.items;
    nestedArray.sort((a, b) => a.order - b.order);

    // Sorting the children arrays based on their "order" property
    nestedArray.forEach(item => {
      if (item.children) {
        item.children.sort((childA, childB) => childA.order - childB.order);
      }
    });

    return {
      items: nestedArray
    };
  },
  refetchOnWindowFocus: false,
  refetchOnMount: false
});

export const getAdminCategoryDetailsQuery = (Id: string | number) => ({
  queryKey: [AdminCategoryQueryKey.ADMIN_CATEGORY_DETAILS, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminCategoryApi.GetCategoryDetails.replace(
        ':id',
        Id.toString()
      ),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: any) => res?.data,
  refetchOnWindowFocus: false
});

export const getAdminGroupCategoriesQuery = (id: string | number) => ({
  queryKey: [AdminCategoryQueryKey.ADMIN_GROUP_CATEGORIES, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminCategoryApi.GetGroupCategories.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: any) => res?.data,
  refetchOnWindowFocus: false
});
