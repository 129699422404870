import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { distances } from '@setvi/shared/styles';
import {
  Box,
  TextField,
  InputAdornment,
  TextFieldProps
} from '@material-ui/core';
import clsx from 'clsx';
import SLabel from '../slabel';

const useStyles = makeStyles(theme => ({
  paperBox: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: distances.px.small,
    backgroundColor: 'transparent'
  },
  title: {
    marginBottom: distances.px.small
  },
  required: {
    color: '#d72638'
  },
  inputWrapper: {
    boxShadow: 'none',
    position: 'relative'
  },
  input: {
    height: '48px',
    marginBottom: distances.px.large
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '14px',
    marginTop: distances.px.xsmall,
    textAlign: 'right'
  },
  multiline: {
    '& .MuiInputBase-input': {
      alignSelf: 'flex-start'
    }
  }
}));

export type FormikTextfieldProps = {
  labelClass?: string;
  endIcon?: JSX.Element;
} & TextFieldProps;

const SInput: FC<FormikTextfieldProps> = ({
  name,
  required,
  className,
  label,
  labelClass,
  endIcon,
  variant = 'outlined',
  ...other
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.paperBox} key={name}>
      <SLabel
        title={label}
        className={clsx(labelClass, classes.title)}
        variant="body2"
        required={required}
      />

      <Box className={classes.inputWrapper}>
        <TextField
          className={clsx(classes.input, className)}
          InputProps={{
            endAdornment: endIcon && (
              <InputAdornment position="start">{endIcon}</InputAdornment>
            )
          }}
          variant={variant}
          {...other}
        />
      </Box>
    </Box>
  );
};

export default SInput;
