import { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { RESOURCE_TYPE, CATEGORY_TYPE } from '@setvi/shared/enums';
import { Category, Resource } from '@setvi/shared/interfaces';
import { CategoryList } from '@setvi/shared/components/scategory';
import { ResourceList } from '@setvi/shared/components/sresource/list';
import { useDrawerContext } from '@setvi/shared/components/sdrawer/base';

interface ListProps {
  type?: RESOURCE_TYPE;
  categoryId: number;
  openCategory: (category: Category) => void;
  onSelect: (isChecked: boolean, resources: any) => void;
  onSelectPages: (resource: Resource) => void;
  onLastCategoryLoaded: () => void;
  showResources: boolean;
  showCategories: boolean;
  onEmptyCategories: () => void;
  onEmptyResources: () => void;
  searchTerm?: string;
  selectedResources: Resource[];
}

const Container = withStyles(({ spacing }) => ({
  root: {
    minHeight: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    '& .MuiTableCell-body': {
      padding: spacing(2)
    }
  }
}))(Box);

export const ListContainer = ({
  type = RESOURCE_TYPE.ALL_RESOURCES,
  categoryId,
  openCategory,
  onSelect,
  onSelectPages,
  onLastCategoryLoaded,
  showCategories,
  showResources,
  onEmptyCategories,
  onEmptyResources,
  searchTerm,
  selectedResources
}: ListProps) => {
  const { sasToken, algoliaKey } = useDrawerContext();

  const categoriesVisible = useMemo(
    () =>
      searchTerm.length < 1 &&
      type !== RESOURCE_TYPE.FAVORITE_RESOURCES &&
      showCategories,
    [showCategories, searchTerm, type]
  );
  const resourcesVisible = useMemo(
    () =>
      showResources ||
      searchTerm.length > 0 ||
      type === RESOURCE_TYPE.FAVORITE_RESOURCES,
    [showResources, searchTerm, type]
  );

  return (
    <Container>
      {categoriesVisible && (
        <CategoryList
          onLastItemLoaded={onLastCategoryLoaded}
          categoryId={categoryId}
          onClick={openCategory}
          categoryType={
            type === RESOURCE_TYPE.USER_RESOURCES
              ? CATEGORY_TYPE.USER
              : CATEGORY_TYPE.COMPANY
          }
          onEmpty={onEmptyCategories}
        />
      )}
      {resourcesVisible && (
        <ResourceList
          categoryId={categoryId}
          onSelect={onSelect}
          searchTerm={searchTerm}
          sasToken={sasToken}
          algoliaKey={algoliaKey}
          resourceType={type}
          selectedResources={selectedResources}
          onEmpty={onEmptyResources}
          onSelectPages={onSelectPages}
        />
      )}
    </Container>
  );
};
