import { Box, makeStyles } from '@material-ui/core';
import Text from '@setvi/shared/components/sui/stext';
import { NoSnippetsIcon } from './NoSnippetsIcon';

export const useStyles = makeStyles({
  center: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorImage: {}
});

export const EmptySnippets = () => {
  const classes = useStyles();
  return (
    <Box
      height="100%"
      my={2}
      className={`${classes.center} ${classes.errorImage}`}>
      <NoSnippetsIcon />
      <Text fontColor="#B6BFCC">No snippets available</Text>
    </Box>
  );
};
