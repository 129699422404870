import { makeStyles, Theme, alpha } from '@material-ui/core';
import clsx from 'clsx';
import { CSSProperties, forwardRef, useEffect, useRef, useState } from 'react';

type ObjectFitProperty = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';

interface Image extends React.HTMLProps<HTMLImageElement> {
  /** class names */
  className?: string;
  /** custom styles */
  style?: CSSProperties;
  /** Image src */
  src: string;
  /** Placeholder image before the src image successfully loads */
  blurDataURL?: string;
  /** Image alt text, used as title for placeholder if image was not loaded */
  alt: string;
  /** Image object-fit property */
  fit?: ObjectFitProperty;
  /** Image width, defaults to 100%, cannot exceed 100% */
  width?: number | string;
  /** Image height, defaults to original image height adjusted to given width */
  height?: number | string;
  /** Predefined border-radius value from theme.radius or number for border-radius in px */
  radius?: number | string;
  /** Customize placeholder content */
  placeholder?: string;
  /** The loading behavior of the image */
  loading?: 'lazy' | 'eager' | undefined;
}

// path for the default placeholder image
const defaultPlaceholder = '/images/setvi-placeholder.jpg';

interface IMakeStyles {
  fit: string;
  width: number | string;
  height: number | string;
  radius: number | string;
  blurDataURL?: string;
}

const useStyles = makeStyles<Theme, IMakeStyles>(theme => ({
  image: {
    objectFit: ({ fit }) => (fit as ObjectFitProperty) || 'cover',
    width: ({ width }) => width,
    heigh: ({ height }) => height,
    borderRadius: ({ radius }) => radius
  },
  // Stole some of the stying of mui skeleton
  // https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/Skeleton/Skeleton.js
  blur: {
    backgroundSize: 'cover',
    backgroundColor: alpha(
      theme.palette.text.primary,
      theme.palette.type === 'light' ? 0.11 : 0.13
    )
  },
  /* Styles applied to the root element if `animation="pulse"`. */
  pulse: {
    animation: '$pulse 1.5s ease-in-out 0.5s infinite'
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.4
    },
    '100%': {
      opacity: 1
    }
  }
}));

export const SETVIImage = forwardRef<HTMLImageElement, Image>(
  (
    {
      alt,
      className,
      style,
      fit = 'fill',
      height = 'auto',
      width = 'auto',
      radius = '0px',
      src,
      placeholder,
      blurDataURL,
      loading = 'lazy'
    },
    _ref
  ) => {
    const [loaded, setLoaded] = useState(false);
    const [_error, setError] = useState(!src);
    const [blurComplete, setBlurComplete] = useState(false);
    const imageRef = useRef<HTMLImageElement>(null);

    const classes = useStyles({
      fit,
      width,
      height,
      radius,
      blurDataURL
    });

    const onLoad = () => {
      setLoaded(true);
      setBlurComplete(true);
    };

    const onError = () => {
      setBlurComplete(true);
      setError(true);
      imageRef.current?.setAttribute('src', defaultPlaceholder || placeholder);
    };

    useEffect(() => {
      if (imageRef.current?.complete) {
        onLoad();
      }
    }, []);

    return (
      <img
        // alt was showing up during loading
        alt={loaded ? alt : ''}
        className={clsx(className, classes.image, {
          [classes.blur]: !blurComplete,
          [classes.pulse]: !blurComplete
        })}
        style={{ ...style }}
        src={src}
        loading={loading}
        ref={imageRef}
        onLoad={onLoad}
        onError={onError}
        placeholder=""
      />
    );
  }
);
