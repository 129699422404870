import { useState, useCallback, useEffect } from 'react';
import Fuse from 'fuse.js';
import { FUSE_OPTIONS, getFuseResult } from '@setvi/shared/utils/fuse';
import { Link, LinkItemItems } from '@setvi/shared/interfaces';

interface UseEditProps {
  handleBack: () => void;
  handleInsert(link: Link, currentLink?: Link): void;
  handleRemoveLink(link?: Link): void;
  link: Link;
}

export const useEdit = ({
  handleBack,
  handleInsert,
  handleRemoveLink,
  link
}: UseEditProps) => {
  const [items, setItems] = useState(link?.Item?.Items);
  const [searchResults, setSearchResults] = useState<LinkItemItems[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = useCallback(
    (value: string) => {
      handleInsert(
        {
          ...link,
          Name: value,
          Item: {
            ...link.Item,
            Items: items.map(item => item)
          }
        },
        link
      );
    },
    [handleInsert, link, items]
  );

  const handleRemoveItem = (removeItem: LinkItemItems) => {
    if (!removeItem) {
      return;
    }

    const updated = items.filter(newItem => newItem.ID !== removeItem.ID);

    if (!updated.length) {
      handleRemoveLink(link);
      handleBack();
      return;
    }

    setItems(updated);
    handleInsert(
      {
        ...link,
        Item: {
          ...link.Item,
          Items: updated
        }
      },
      link
    );
  };

  const onSearch = useCallback(
    (search: string) => {
      const fuse = items ? new Fuse(items, FUSE_OPTIONS) : null;
      setSearchResults(getFuseResult({ fuse, searchTerm: search, items }));
    },
    [items]
  );

  useEffect(() => onSearch(searchTerm), [onSearch, searchTerm]);

  useEffect(() => setItems(link?.Item?.Items), [link]);

  return {
    items,
    handleSubmit,
    handleRemoveItem,
    setSearchTerm,
    searchResults,
    searchTerm
  };
};
