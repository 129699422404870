import { Box, Divider, makeStyles } from '@material-ui/core';
import { Resource } from '@setvi/shared/interfaces';
import { EmptySearchResults } from 'Components/Shared/ResourcesPanel/EmptyState/EmptySearchResults';
import ResultsLoader from '../ResultsLoader';
import SearchResult from '../SearchResult';

interface IResultsContent {
  isLoading: boolean;
  results: Resource[];
  searchQuery: string;
  handleSearchClose: () => void;
}

const useResultsContentStyles = makeStyles(() => ({
  list: {
    maxHeight: '40vh',
    overflowY: 'scroll'
  },
  devider: {
    marginLeft: 24
  }
}));

const ResultsContent = ({
  isLoading,
  results,
  searchQuery,
  handleSearchClose
}: IResultsContent) => {
  const styles = useResultsContentStyles();
  if (isLoading) return <ResultsLoader />;
  if (!results.length) return <EmptySearchResults />;

  return (
    <Box className={styles.list}>
      {results.map((resource, index) => (
        <Box key={resource.ID}>
          <SearchResult
            resource={resource}
            searchQuery={searchQuery}
            handleSearchClose={handleSearchClose}
          />
          {index < results.length - 1 && <Divider className={styles.devider} />}
        </Box>
      ))}
    </Box>
  );
};

export default ResultsContent;
