import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { Box, Divider } from '@material-ui/core';
import { htmlTags } from '@setvi/shared/utils';
import { useDialog } from '@setvi/shared/providers';
import { SConfirmDialog } from '@setvi/shared/components';
import { SetviEditor } from '@setvi/shared/components/seditor/components';
import { Contact } from 'Services/Query/Contacts/Types';
import { ROUTES } from 'enumsV2';

import { useNylas } from 'Hooks/useNylas';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import NylasDialog from 'Components/Campaigns/NylasDialog';
import { useStyles } from './Style';
import { ComposeEmailProps } from './Interface';
import { isValidEmail, validationSchema } from './Utils';
import { SuggestedContacts } from './Components/SuggestedContacts';
import { Footer, Header, RecipientField, SubjectField } from './Components';
import { useSuggestedContacts } from './Components/SuggestedContacts/useSuggestedContacts';

export const ComposeEmail = ({
  links,
  values,
  loading,
  setValues,
  openDrawer,
  drawerType,
  chips,
  initLinkOptions,
  isMinimized,
  onSubmit,
  onChipAdd,
  toggleDrawer,
  onChipDelete,
  setEditorNode,
  setIsMinimized,
  closeComposeEmail,
  onSuggestedContactSelect
}: ComposeEmailProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [recipientInputValue, setRecipientInputValue] = useState('');
  const [currentLinks, setCurrentLinks] = useState(links?.length || 0);
  const { suggestedContacts } = useSuggestedContacts({
    search: recipientInputValue
  });

  const { nylasToken } = useAppContext();
  const { getStatus: getNylasStatus } = useNylas(nylasToken);
  const { openDialog, closeDialog } = useDialog();
  const { pathname } = useLocation();
  const classes = useStyles();

  const isSuggestedContactsOpened = useMemo(
    () => recipientInputValue.length > 1 && suggestedContacts?.length > 0,
    [recipientInputValue, suggestedContacts]
  );

  useEffect(() => {
    if (links?.length !== currentLinks) {
      setIsMinimized(false);
      setCurrentLinks(links?.length);
    }
  }, [links, currentLinks, setIsMinimized]);

  useEffect(() => {
    if (pathname === ROUTES.LOGIN) closeComposeEmail();
  }, [pathname, closeComposeEmail]);

  const onCloseCompose = () => {
    if (
      values.sendTo.length === 0 &&
      values.subject.trim().length === 0 &&
      values.body.replace(htmlTags, '').trim().length === 0
    ) {
      if (openDrawer) toggleDrawer();
      return closeComposeEmail();
    }

    openDialog(
      <SConfirmDialog
        type="delete"
        closeText="Keep it"
        title="Discard your email"
        confirmText="Discard & Close"
        additionalMessage="The action CANNOT be reverted back"
        description="Are you sure you want to discard your entered data in this email?"
        onClose={closeDialog}
        onConfirm={() => {
          closeDialog();
          closeComposeEmail();
          openDrawer && toggleDrawer();
        }}
      />
    );
  };

  const handleSubmit = async () => {
    if (nylasToken) {
      const nylasResponse = await getNylasStatus();

      if (nylasResponse.message || nylasResponse.sync_state === 'invalid')
        return openDialog(<NylasDialog />);
    }
    onSubmit();
  };

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      <Form
        onKeyDown={e => {
          e.key === 'Enter' && e.preventDefault();
        }}>
        <Box
          className={clsx(
            classes.container,
            !isMinimized ? classes.containerMax : classes.containerMin,
            openDrawer && classes.marginContainerPinned
          )}>
          <Box boxShadow={3} px={2}>
            <Header
              subject={values?.subject}
              closeComposeEmail={onCloseCompose}
              isMinimized={isMinimized}
              setIsMinimized={setIsMinimized}
            />
            <Box display={isMinimized && 'none'}>
              <Box px={2}>
                <Divider />
                <Box onClick={e => setAnchorEl(e.currentTarget)}>
                  <RecipientField
                    label="Send to"
                    name="sendTo"
                    fieldValue={values.sendTo}
                    chips={chips}
                    onChipAdd={chip => {
                      onChipAdd(chip);
                      setRecipientInputValue('');
                    }}
                    onChipDelete={chip => {
                      onChipDelete(chip);
                      setRecipientInputValue('');
                    }}
                    onClickAway={() => {
                      setAnchorEl(null);
                      setRecipientInputValue('');
                    }}
                    onInputChange={value => setRecipientInputValue(value)}
                    blurBehavior={
                      !isSuggestedContactsOpened ||
                      (isSuggestedContactsOpened &&
                        isValidEmail(recipientInputValue))
                        ? 'add'
                        : 'clear'
                    }
                  />
                </Box>
                {isSuggestedContactsOpened && (
                  <SuggestedContacts
                    anchorEl={anchorEl}
                    suggestedContacts={suggestedContacts}
                    onContactClick={(contact: Contact) => {
                      onSuggestedContactSelect(contact);
                    }}
                  />
                )}

                <Divider />

                <SubjectField
                  label="Subject"
                  name="subject"
                  boldText
                  onChange={(v: string) =>
                    setValues((prevVals: any) => ({
                      ...prevVals,
                      subject: v
                    }))
                  }
                />
              </Box>
              <SetviEditor
                id="compose-email-editor"
                height={380}
                content={values.body}
                setups={[initLinkOptions]}
                customClass={classes.editor}
                setEditorNode={setEditorNode}
                handleEditorChange={(content: string, text: string) =>
                  setValues((prevVals: any) => ({
                    ...prevVals,
                    body: content,
                    textBody: text || ''
                  }))
                }
              />

              <Footer
                handleDrawerOpen={toggleDrawer}
                loading={loading}
                links={links.length > 0}
                drawerType={drawerType}
              />
            </Box>
          </Box>
        </Box>
      </Form>
    </Formik>
  );
};
