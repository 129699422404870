import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { distances } from '@setvi/shared/styles';

const useStyles = makeStyles(({ palette }) => ({
  itemContainer: {
    width: '100%',
    display: 'flex',
    color: palette.text.secondary
  },
  borderedBox: {
    border: '1px solid #F1F1F5',
    borderRadius: '6px',
    minWidth: '135px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bold: {
    fontWeight: 'bold'
  },
  innerBox: {
    padding: `0 ${distances.px.small}`
  },
  icon: {
    color: '#92929D'
  }
}));
export interface MenuItem {
  icon?: ReactNode;
  text: string;
  subText?: string;
  color?: string;
  disabled?: boolean;
  tooltipText?: string;
  onClick?: () => void;
}
export interface DropdownMenuProps {
  menuItems: Array<MenuItem>;
  handleClose: () => void;
  anchorEl?: HTMLElement;
  closeOnClick?: boolean;
  borderedBox?: boolean;
  boldText?: boolean;
  position?: 'top' | 'bottom';
  transformPosition?: 'top' | 'bottom';
  transformPositionHorizontal?: 'left' | 'right';
  anchorPosition?: any;
  anchorReference?: 'anchorEl' | 'anchorPosition' | 'none';
}

const StyledMenu = withStyles(({ spacing }) => ({
  paper: {
    marginTop: spacing(2),
    padding: 0,
    borderRadius: 8,
    left: spacing(0),
    minWidth: 250,
    '& ul': {
      padding: 0
    }
  }
}))(Menu);

const DropdownText = withStyles({
  root: {
    fontSize: '1rem',
    color: 'rgba(33, 33, 33, 1)'
  }
})(Typography);

const DropdownSubtext = withStyles({
  root: {
    fontSize: '0.8rem',
    color: '#92929D'
  }
})(Typography);

export const DropdownMenu: FC<DropdownMenuProps> = ({
  menuItems,
  handleClose,
  anchorEl,
  closeOnClick,
  borderedBox,
  position,
  transformPosition,
  transformPositionHorizontal,
  boldText,
  anchorPosition = null,
  anchorReference = 'anchorEl'
}) => {
  const classes = useStyles();

  const handleClick = (onClick: () => void) => {
    closeOnClick && handleClose();
    onClick?.();
  };
  return (
    <StyledMenu
      anchorReference={anchorReference}
      anchorEl={anchorEl}
      anchorPosition={anchorPosition}
      keepMounted
      open={
        anchorReference === 'anchorEl'
          ? Boolean(anchorEl)
          : anchorPosition !== null
      }
      onClose={handleClose}
      elevation={3}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: position || 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: transformPosition || 'top',
        horizontal: transformPositionHorizontal || 'right'
      }}>
      {menuItems?.map(item => (
        <Tooltip
          title={item?.tooltipText}
          arrow
          disableHoverListener={Boolean(!item?.tooltipText)}
          placement="top"
          key={item.text}>
          <span>
            <MenuItem
              disabled={item.disabled}
              onClick={() => handleClick(item.onClick)}>
              <Box
                className={classes.itemContainer}
                style={{ color: item.color && item.color }}>
                <>
                  <Box
                    className={classes.icon}
                    style={{ color: item.color && item.color }}>
                    {item.icon && item.icon}
                  </Box>
                  <Box
                    className={clsx({
                      [classes.borderedBox]: borderedBox
                    })}>
                    <Box className={classes.innerBox}>
                      <DropdownText
                        className={clsx({
                          [classes.bold]: boldText
                        })}
                        style={{ color: item.color && item.color }}>
                        {item.text}
                      </DropdownText>
                      {item.subText && (
                        <DropdownSubtext>{item.subText}</DropdownSubtext>
                      )}
                    </Box>
                  </Box>
                </>
              </Box>
            </MenuItem>
          </span>
        </Tooltip>
      ))}
    </StyledMenu>
  );
};
