import { useCallback, useMemo } from 'react';
import { client } from 'filestack-react';
import { useSnackbar } from 'notistack';

interface SUploadTags {
  [key: string]: string;
}
interface FileStackToken {
  cancel: () => void | null;
}

interface FileStackUploadProps {
  file: File;
  tags?: SUploadTags;
  error?: {
    show: boolean;
    message?: string;
  };
  getProgress?: (total: number) => void;
}

export const useFilestack = () => {
  const { enqueueSnackbar } = useSnackbar();
  const filestackToken: FileStackToken = useMemo(() => ({ cancel: null }), []);

  const fileStackUpload = useCallback(
    ({ file, tags, error, getProgress }: FileStackUploadProps) =>
      client
        .init(process.env.FILESTACK_KEY)
        .upload(
          file,
          {
            onProgress: async ({ totalPercent }: { totalPercent: number }) =>
              getProgress(totalPercent),
            tags
          },
          {
            location: 'azure'
          },
          filestackToken
        )
        .then(res => res)
        .catch(() => {
          if (error?.show)
            enqueueSnackbar(
              error?.message ||
                'There was an error uploading your file, please try again later',
              {
                variant: 'error'
              }
            );
        }),
    [enqueueSnackbar, filestackToken]
  );

  return {
    filestackToken,
    fileStackUpload
  };
};
