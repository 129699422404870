import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from 'enumsV2';
import { useAppContext } from 'Providers/AppProvider/AppContext';

export const AuthorizedRoutes = () => {
  const { token } = useAppContext();

  if (token) return <Outlet />;

  return <Navigate to={ROUTES.LOGIN} replace />;
};
