import { AxiosMethods, AdminCategoryApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

import {
  CreateAdminCategoryInput,
  UpdateAdminCategoryInput,
  UpdateAdminCategoryParentInput
} from './Types';

export enum AdminCategoryMutationsKey {
  CREATE_ADMIN_CATEGORY = 'CREATE_ADMIN_CATEGORY',
  UPDATE_USER_CATEGORY = 'UPDATE_USER_CATEGORY',
  DUPLICATE_CATEGORY = 'DUPLICATE_CATEGORY',
  DELETE_ADMIN_CATEGORY = 'DELETE_ADMIN_CATEGORY'
}

const CATEGORY_ID = ':categoryId';
const NPCID = ':newParentCategoryId';

export const createAdminCategoryMutation = () => ({
  mutationFn: (body: CreateAdminCategoryInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.CreateCategory,
      method: AxiosMethods.POST,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const updateAdminCategoryMutation = () => ({
  mutationFn: (body: UpdateAdminCategoryInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.UpdateCategory,
      method: AxiosMethods.PUT,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const updateAdminCategoryParentMutation = () => ({
  mutationFn: ({
    CategoryId,
    NewParentCategoryId
  }: UpdateAdminCategoryParentInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.UpdateCategoryParent.replace(
        CATEGORY_ID,
        CategoryId.toString()
      ).replace(NPCID, NewParentCategoryId.toString()),
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const duplicateAdminCategoryMutation = () => ({
  mutationFn: (id: number | string) =>
    axiosHelper({
      endpoint: AdminCategoryApi.DuplicateCategory.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.POST,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const deleteAdminCategoryMutation = () => ({
  mutationFn: (id: string | number) =>
    axiosHelper({
      endpoint: AdminCategoryApi.DeleteCategory.replace(':id', id.toString()),
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});
