export enum ResourceApi {
  GetFavorite = '/api/v1.0/resource/favorite',
  GetFeatured = '/api/v1.0/resource/featured',
  GetPopular = '/WebApi/GetPopularResources',
  GetRecentlyUploaded = '/api/v1.0/resource/whatsnew/paged',
  AddToFavorites = '/WebAPI/AddToFavorites',
  RemoveFromFavorites = '/WebAPI/DeleteFavorite',
  GetShareableLink = '/WebAPI/GetLink',
  GetCategoriesAndResources = '/WebAPI/GetCategoriesAndResources',
  GetUserResources = '/api/v1.0/resource/user/:categoryId',
  GetCompanyResources = '/api/v1.0/resource/company/:categoryId',
  CreateResource = '/WebAPI/UploadUserResource',
  CreateWebLinkResource = '/api/v1.0/resource/createweblink',
  GetResource = '/api/v1.0/resource/:resourceId',
  GetChildResources = 'webapi/GetChildResources',
  GetResourceBookmarks = 'webapi/GetResourceBookmarks',
  SaveResourceBookmarks = 'webApi/SaveResourceBookmarks',
  DeleteUserResource = '/WebAPI/deleteuserresource'
}

export enum AdminResourceApi {
  GetResources = '/api/v2/ResourceCategory/:id/resources',
  GetResource = '/api/v2/Resources/:id',
  GetCategoryResources = '/api/v2/Resources/:id/getCompanyResources',
  CopyCategoryPermissions = '/api/v2/Groups/getGroupsFromResourceCategories',
  ChangeResourcesCategory = 'api/v2/Resources/:sourceCategoryID/changeCategory',
  ChangeResourceStatus = '/api/v2/Resources/:resourceId/changestatus/:statusId',
  DeleteResource = '/api/v2/Resources/:id',
  ResourceTags = '/api/v2/tags',
  UpdateResource = '/api/v2/Resources/update',
  CreateResource = '/api/v2/Resources/create',
  GetResourceProcessStatus = '/api/v2/Resources/resourceProcessingStatus',
  ResourceUploadFinished = '/api/v2/Resources/uploadFinish'
}
