import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  innerWrap: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    marginBottom: spacing(3)
  },
  panelImage: {
    width: 70,
    height: 55,
    borderRadius: 6,
    objectFit: 'cover'
  },
  itemName: {
    textAlign: 'left'
  },
  loader: {
    textAlign: 'center',
    margin: spacing(2)
  },
  container: {
    height: '100%'
  }
}));
