import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { distances } from '@setvi/shared/styles';

export const CenterBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box);

export const useStyles = makeStyles({
  btn: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: 180,
    padding: `${distances.px.base} ${distances.px.xxxlarge}`,
    '&:disabled': {
      color: '#fff',
      backgroundColor: 'rgba(30,150,252, 0.5)'
    },
    textTransform: 'none'
  },
  spinner: {
    marginLeft: 5,
    marginBottom: 2
  },
  cancelBtnTxt: {
    color: '#B0BACC',
    textTransform: 'none'
  },
  topTxt: {
    color: '#717480'
  },
  bold: {
    fontWeight: 700
  }
});
