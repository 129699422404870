import { Dialog, makeStyles } from '@material-ui/core';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react';
import { v4 as uuid } from 'uuid';

const useModalStyles = makeStyles(({ spacing }) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: spacing(2)
    }
  }
}));

interface IDialogProvider {
  children: ReactNode;
}

interface DialogOptions {
  // Prevents user from closing dialog by clicking escape or backdrop
  preventClose?: boolean;
  /**
   * Required property if opening multiple dialogs, id acts as a key and has to be passed
   * to specify which dialog should be closed if not provided all opened dialogs should close
   * default id is 1
   */
  id?: number | string;
  /**
   * Required property if using router modals
   * to change current route on dialog close to previous route
   */
  onClose?: () => void;
  /**
   * Required property if black backdrop is needed
   */
  showBackdrop?: boolean;
  /**
   * Required property if keyboard focus needs to be disabled
   */
  disableEnforceFocus?: boolean;
}

interface ContextProps {
  openDialog(component: ReactNode, options?: DialogOptions): void;
  closeDialog(id?: DialogOptions['id'], onClose?: () => void): void;
}

const defaultValues: ContextProps = {
  openDialog: () => {},
  closeDialog: () => {}
};

interface DialogProps {
  options: DialogOptions;
  component: ReactNode | null;
  id?: DialogOptions['id'];
  onClose?: () => void;
}

export const DialogContext = createContext<ContextProps>(defaultValues);

const DialogProvider = ({ children }: IDialogProvider) => {
  const [dialogs, setDialogs] = useState<DialogProps[]>([]);
  const styles = useModalStyles();

  const openDialog = useCallback(
    (node: ReactNode, dialogOptions?: DialogOptions) => {
      setDialogs(prevDialogs => [
        ...prevDialogs,
        ...[
          {
            component: node,
            options: dialogOptions,
            id: dialogOptions?.id || uuid(),
            onClose: () => dialogOptions?.onClose?.()
          }
        ]
      ]);
    },
    []
  );

  /**
   * Provide id to specify which dialog to close,
   * if not provided all opened dialogs will be closed
   */
  const closeDialog = useCallback(
    (id?: DialogOptions['id'], onClose?: () => void) => {
      // overwrite for mui which automatically injects event object as the fist argument to function passed to onClose
      onClose?.();

      if (id && (typeof id === 'string' || typeof id === 'number')) {
        // remove only specified dialog id if value is provided
        const filteredids = dialogs.filter(dialog => dialog.id !== id);

        return setDialogs(filteredids);
      }

      // remove all dialogs
      setDialogs([]);
    },
    [dialogs]
  );

  return (
    <>
      <DialogContext.Provider
        value={{
          openDialog,
          closeDialog
        }}>
        {dialogs.map(dialog => (
          <Dialog
            key={dialog?.id}
            open
            disableEnforceFocus={dialog?.options?.disableEnforceFocus || false}
            className={styles.dialog}
            onClose={(_event, reason) => {
              if (
                dialog.options?.preventClose &&
                (reason === 'backdropClick' || reason === 'escapeKeyDown')
              )
                return;

              closeDialog(dialog.id, dialog.onClose);
            }}
            hideBackdrop={
              !dialog?.options?.preventClose || !dialog?.options?.showBackdrop
            }
            maxWidth={false}>
            {dialog.component}
          </Dialog>
        ))}
        {children}
      </DialogContext.Provider>
    </>
  );
};

export default DialogProvider;

export const useDialog = () => useContext<ContextProps>(DialogContext);
