import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={129}
    height={144}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={59.906} cy={59.331} r={56.008} fill="#EAEEF9" />
    <path
      d="M118.7 37.847a4.798 4.798 0 1 0 0-9.596 4.798 4.798 0 0 0 0 9.596Zm7.022-18.724a3.277 3.277 0 1 0 0-6.554 3.277 3.277 0 0 0 0 6.554ZM10.593 12.17a6.085 6.085 0 1 0 0-12.17 6.085 6.085 0 0 0 0 12.17Z"
      fill="#EAEEF9"
    />
    <path
      d="M90.651 28.818H60.816V17.495h39.325c6.111.144 11.071 5.193 11.071 11.323h-12.94"
      fill="#CCD4E2"
    />
    <g filter="url(#a)">
      <path
        d="M101.003 17.495c-2.301.144-4.458 1.082-5.968 2.668-.143.145-.287.289-.43.505-1.367 1.587-2.373 3.678-2.373 5.914V93.94c0 6.275-5.033 11.323-11.287 11.323H26.02V26.077c.143-2.308 1.006-4.399 2.516-5.914 1.653-1.658 3.882-2.668 6.398-2.668h66.069Z"
        fill="url(#b)"
      />
    </g>
    <path
      d="M81.304 32.928h-16.32c-.575 0-1.078-.505-1.078-1.082 0-.576.503-1.081 1.079-1.081h16.319c.575 0 1.078.505 1.078 1.081 0 .577-.43 1.082-1.078 1.082Zm-24.012 0h-21.28c-.575 0-1.078-.505-1.078-1.082 0-.576.503-1.081 1.078-1.081h21.352c.575 0 1.078.505 1.078 1.081 0 .577-.503 1.082-1.15 1.082ZM70.95 43.602H49.743c-.575 0-1.078-.505-1.078-1.082 0-.577.503-1.082 1.078-1.082h21.209c.575 0 1.078.505 1.078 1.082.072.577-.431 1.082-1.078 1.082Z"
      fill="#B3BFD3"
    />
    <path
      d="M42.914 43.602h-6.902c-.575 0-1.078-.505-1.078-1.082 0-.577.503-1.082 1.078-1.082h6.902c.575 0 1.078.505 1.078 1.082 0 .577-.503 1.082-1.078 1.082Zm38.388 10.674H68.289c-.575 0-1.078-.505-1.078-1.082 0-.577.503-1.082 1.078-1.082h13.013c.575 0 1.078.505 1.078 1.082 0 .577-.431 1.082-1.078 1.082Z"
      fill="#D4DBEC"
    />
    <path
      d="M62.037 54.276H36.012c-.575 0-1.078-.505-1.078-1.082 0-.577.503-1.082 1.078-1.082h26.025c.575 0 1.078.505 1.078 1.082 0 .577-.431 1.082-1.078 1.082Zm8.914 10.674H56.645c-.575 0-1.079-.505-1.079-1.082 0-.577.504-1.082 1.079-1.082H70.95c.575 0 1.079.505 1.079 1.082.072.577-.432 1.082-1.079 1.082Z"
      fill="#B3BFD3"
    />
    <path
      d="M50.39 64.95H36.012c-.575 0-1.078-.505-1.078-1.082 0-.577.503-1.082 1.078-1.082h14.45c.575 0 1.079.505 1.079 1.082 0 .577-.504 1.082-1.15 1.082Z"
      fill="#D4DBEC"
    />
    <path
      d="M81.306 75.622h-9.993c-.575 0-1.079-.505-1.079-1.082 0-.577.504-1.082 1.079-1.082h9.993c.575 0 1.078.505 1.078 1.082 0 .577-.431 1.082-1.078 1.082Z"
      fill="#B3BFD3"
    />
    <path
      d="M64.84 75.622H48.09c-.575 0-1.078-.505-1.078-1.082 0-.577.503-1.082 1.078-1.082h16.75c.576 0 1.08.505 1.08 1.082 0 .577-.504 1.082-1.08 1.082Z"
      fill="#D4DBEC"
    />
    <path
      d="M41.835 75.622h-5.823c-.575 0-1.078-.505-1.078-1.082 0-.577.503-1.082 1.078-1.082h5.823c.575 0 1.079.505 1.079 1.082 0 .577-.504 1.082-1.079 1.082Zm29.118 10.674H61.03c-.575 0-1.078-.505-1.078-1.081 0-.577.503-1.082 1.078-1.082h9.922c.575 0 1.078.505 1.078 1.082.072.576-.431 1.081-1.079 1.081Z"
      fill="#B3BFD3"
    />
    <path
      d="M54.488 86.296H36.012c-.575 0-1.078-.505-1.078-1.081 0-.577.503-1.082 1.078-1.082h18.476c.575 0 1.078.505 1.078 1.082 0 .576-.503 1.081-1.078 1.081Z"
      fill="#D4DBEC"
    />
    <path
      d="M56.788 96.32h14.523a8.902 8.902 0 0 0 2.588 6.275c1.581 1.658 3.81 2.596 6.254 2.596h-65.35a8.847 8.847 0 0 1-6.254-2.596 8.902 8.902 0 0 1-2.588-6.275h42.991s7.98.072 7.836 0Z"
      fill="#CCD4E2"
    />
    <defs>
      <linearGradient
        id="b"
        x1={63.487}
        y1={15.465}
        x2={63.487}
        y2={106.209}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFEFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <filter
        id="a"
        x={0.274}
        y={4.623}
        width={126.475}
        height={139.258}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={12.873} />
        <feGaussianBlur stdDeviation={12.873} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_17991_197373"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_17991_197373"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const NoSnippetsIcon = memo(SvgComponent);
