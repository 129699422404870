import { useState, useCallback, ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getLinkItemFromTemplateItem } from '@setvi/shared/utils';
import { Template, Link } from '@setvi/shared/interfaces';

interface UseListProps {
  handleInsert(link: Link): void;
}

export const useList = ({ handleInsert }: UseListProps) => {
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState<Template | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onCancel = useCallback(() => {
    setSelected(null);
    setValue(null);
  }, [setSelected, setValue]);

  const onAdd = useCallback(() => {
    handleInsert({
      Name: selected.Name,
      Placeholder: uuidv4(),
      ParentID: selected.ID,
      Item: {
        ID: selected.ID,
        Name: selected.Name,
        ThumbURL: selected.ThumbURL,
        Items: selected.Items.map(item => getLinkItemFromTemplateItem(item))
      }
    });
  }, [handleInsert, selected]);

  return {
    value,
    setValue,
    handleChange,
    onCancel,
    onAdd,
    selected,
    setSelected
  };
};
