import { memo } from 'react';
import { StyledTreeItem } from '../style';

export interface STreeNodeProps<T> {
  node: T;
  nodeKeyGetter: (node: T) => string | number;
  nodeChildrenGetter: (node: T) => T[];
  renderNodeLabel: (node: T) => JSX.Element;
  onNodeClick?: (node: T) => void;
}

const STreeNode = <T,>({
  node,
  nodeKeyGetter,
  nodeChildrenGetter,
  renderNodeLabel,
  onNodeClick
}: STreeNodeProps<T>) => (
  <StyledTreeItem
    key={nodeKeyGetter(node).toString()}
    nodeId={nodeKeyGetter(node).toString()}
    onClick={() => onNodeClick?.(node)}
    label={renderNodeLabel(node)}>
    {Array.isArray(nodeChildrenGetter(node)) &&
      nodeChildrenGetter(node).length > 0 &&
      nodeChildrenGetter(node).map(n =>
        STreeNode({
          node: n,
          nodeKeyGetter,
          nodeChildrenGetter,
          renderNodeLabel,
          onNodeClick
        })
      )}
  </StyledTreeItem>
);

export default memo(STreeNode);
