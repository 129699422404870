import { Box, Typography, makeStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert/Alert';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { distances } from '@setvi/shared/styles';
import { SText } from '@setvi/shared/components';
import { CreateLinkButtons } from './Buttons';

interface IPreviewLink {
  onClose: () => void;
  linkName: string;
}

const usePreviewLinkStyles = makeStyles(({ palette, spacing }) => ({
  disabledInput: {
    color: palette.text.primary
  },
  alert: {
    color: '#469E73',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    padding: distances.px.base
  },
  linkLabel: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: spacing(2)
  },
  link: {}
}));

const PreviewLink = ({ onClose, linkName }: IPreviewLink) => {
  const styles = usePreviewLinkStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = useCallback(
    (link: string) => {
      navigator.clipboard.writeText(link);
      enqueueSnackbar(`Link has been copied successfully`, {
        variant: 'success'
      });
    },
    [enqueueSnackbar]
  );

  return (
    <>
      <Box mt={2} mb={4}>
        <Box mb={4}>
          <Alert
            icon={<CheckCircle fontSize="small" />}
            className={styles.alert}
            severity="success">
            Link created successfully.
          </Alert>
        </Box>
        <Typography className={styles.linkLabel}>Link</Typography>
        <SText maxLines={3} title={linkName} lineHeight="md" />
      </Box>
      <CreateLinkButtons
        confirmButtonText="Copy Link"
        onConfirm={() => handleConfirm(linkName)}
        onClose={onClose}
      />
    </>
  );
};

export default PreviewLink;
