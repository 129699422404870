import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';

import { AdminResourceApi, AxiosMethods } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import {
  AdminCategoryResourcesQuery,
  AdminResourceResponse,
  AdminResourcesQuery,
  AdminUploadedResourceResponse,
  BaseQueryParams,
  CategoryResourcesQueryParams,
  CopyCategoryPermissionsQueryParams,
  ResrouceTagsResponse
} from './Types';

export enum AdminResroucesQueryKey {
  ADMIN_RESOURCE = 'ADMIN_RESOURCE',
  ADMIN_RESOURCES = 'ADMIN_RESOURCES',
  ADMIN_RESOURCE_TAGS = 'ADMIN_RESOURCE_TAGS',
  ADMIN_CATEGORY_RESOURCES = 'ADMIN_CATEGORY_RESOURCES',
  COPY_CATEGORY_PERMISSIONS = 'COPY_CATEGORY_PERMISSIONS',
  ADMIN_RESOURCES_PROCESS_STATUS = 'ADMIN_RESOURCES_PROCESS_STATUS'
}

export const getAdminResourceQuery = (id: string) => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCE, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetResource.replace(':id', id),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: AdminResourceResponse) => {
    const response = {
      ...res?.data,
      categoryBreadcrumb: res?.data?.resourceCategoryBreadcrumbs
        ?.map((breadcrumb: any) => breadcrumb?.resourceCategoryName)
        .join(' / '),
      categories:
        res?.data?.resourceCategoryMembership?.map((elem: any) => ({
          id: elem?.resourceCategoryID,
          name: elem?.resourceCategoryName
        })) || []
    };

    return response;
  },
  refetchOnWindowFocus: false
});

export const getAdminCategoyResourcesQuery = (
  params: CategoryResourcesQueryParams
): UseInfiniteQueryOptions<AdminCategoryResourcesQuery> => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_CATEGORY_RESOURCES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: `${AdminResourceApi.GetCategoryResources.replace(
        ':id',
        params.categoryId?.toString()
      )}`,
      method: AxiosMethods.GET,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (
    lastPage: AdminCategoryResourcesQuery,
    allPages: AdminCategoryResourcesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.data?.items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.data?.items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const copyCategoryPermissionsQuery = (
  queryVariables: CopyCategoryPermissionsQueryParams
) => ({
  queryKey: [AdminResroucesQueryKey.COPY_CATEGORY_PERMISSIONS, queryVariables],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.CopyCategoryPermissions,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        ...queryVariables
      }
    }),
  select: (res: any) => res?.data,
  refetchOnWindowFocus: false
});

export const getAdminResourcesQuery = (
  params: CategoryResourcesQueryParams
): UseInfiniteQueryOptions<AdminResourcesQuery> => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: `${AdminResourceApi.GetResources.replace(
        ':id',
        params.categoryId?.toString()
      )}`,
      method: AxiosMethods.GET,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (
    lastPage: AdminResourcesQuery,
    allPages: AdminResourcesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.data?.resources?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.data?.resources?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getResourceTagsQuery = (
  params: BaseQueryParams
): UseInfiniteQueryOptions<ResrouceTagsResponse> => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCE_TAGS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: AdminResourceApi.ResourceTags,
      method: AxiosMethods.GET,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (
    lastPage: ResrouceTagsResponse,
    allPages: ResrouceTagsResponse[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.data?.items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.data?.items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getAdminResourcesProcessStatusesQuery = (
  resourceId: number[]
) => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCES_PROCESS_STATUS, resourceId],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetResourceProcessStatus,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        resourceId
      }
    }),
  select: (res: AdminUploadedResourceResponse) => res?.data,
  refetchOnWindowFocus: false
});
