import React from 'react';
import { Field, FieldProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, InputAdornment } from '@material-ui/core';
import clsx from 'clsx';

import { distances } from '@setvi/shared/styles';

import SLabel from '../../../sui/slabel';

const useStyles = makeStyles(theme => ({
  paperBox: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'transparent'
  },
  title: {
    marginBottom: distances.px.small
  },
  required: {
    color: '#d72638'
  },
  inputWrapper: {
    boxShadow: 'none',
    position: 'relative',
    borderRadius: '8px'
  },
  input: {
    minHeight: '48px',
    height: 'auto',
    borderRadius: '8px'
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '14px',
    marginTop: distances.px.xsmall,
    textAlign: 'right'
  },
  multiline: {
    '& .MuiInputBase-input': {
      alignSelf: 'flex-start'
    }
  }
}));

interface FormikTextfieldProps {
  name: string;
  label?: string;
  placeholder: string;
  defaultValue?: string;
  required?: boolean;
  type?: string;
  labelClass?: any;
  inputClass?: any;
  endIcon?: JSX.Element;
  disabled?: boolean;
  multiline?: boolean;
  onChange?: (value: string) => void;
  customErrors?: any;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
}

const SFormikTextfield = ({
  name,
  label,
  placeholder,
  required,
  type = 'text',
  labelClass,
  inputClass,
  disabled,
  endIcon,
  multiline = false,
  defaultValue,
  onChange,
  maxLength,
  customErrors,
  minRows = 6,
  maxRows = 6
}: FormikTextfieldProps) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Box className={classes.paperBox}>
          {label ? (
            <SLabel
              title={label}
              className={clsx(labelClass, classes.title)}
              variant="body2"
              required={required}
            />
          ) : null}

          <Box className={classes.inputWrapper}>
            <TextField
              id={`standard-multiline-flexible-${name}`}
              name={field.name}
              type={type}
              placeholder={placeholder}
              defaultValue={defaultValue}
              value={meta.value}
              multiline={multiline}
              minRows={minRows}
              maxRows={maxRows}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                form.setFieldValue(name, event.target.value);
                onChange?.(event.target.value);
              }}
              autoComplete="off"
              autoFocus={false}
              className={clsx(classes.input, inputClass)}
              disabled={disabled}
              error={
                (meta.touched && Boolean(meta.error)) ||
                Boolean(customErrors?.[name])
              }
              helperText={meta.error || customErrors?.[name]}
              InputProps={{
                endAdornment: endIcon && (
                  <InputAdornment position="start">{endIcon}</InputAdornment>
                )
              }}
              variant="outlined"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={
                maxLength && {
                  maxLength: maxLength + 1
                }
              }
            />
          </Box>
        </Box>
      )}
    </Field>
  );
};

export default SFormikTextfield;
