import { useQuery } from '@tanstack/react-query';
import { getTemplatesQuery } from '@setvi/shared/services';

export const useContent = () => {
  const { data, isLoading: loading } = useQuery(getTemplatesQuery());

  return {
    data,
    loading
  };
};
