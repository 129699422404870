import { Box, makeStyles } from '@material-ui/core';
import Text from '@setvi/shared/components/sui/stext';
import { NoFavoritesIcon } from './icons/NoFavoritesIcon';

export const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorImage: {
    width: '100%'
  }
});

export const EmptyFavorites = () => {
  const classes = useStyles();
  return (
    <Box height="100%" className={classes.center}>
      <Box my={2} className={`${classes.center} ${classes.errorImage}`}>
        <NoFavoritesIcon />
        <Text fontColor="#B6BFCC">Nothing added to favorites</Text>
      </Box>
    </Box>
  );
};
