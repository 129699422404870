import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  roundedField: {
    marginTop: '1rem',
    marginBottom: '1rem',
    '& fieldset': {
      borderRadius: 10
    }
  }
}));

export const RoundedTextField = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.roundedField}>
      <TextField {...props} />
    </div>
  );
};
