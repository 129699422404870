import {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosMethods, CommonApi, IntegrationApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import {
  GetCompanyDataQuery,
  GetCrmDataQuery,
  GetCompanyModulesQuery,
  Account as AccountProps,
  Lead as LeadProps,
  Contact as ContactProps,
  State as StateProps,
  City as CityProps
} from './types';

export enum CommonQueryKeys {
  ALGOLIA_TOKEN = 'algolia_token',
  USER_INFO = 'user_info',
  COMPANY_DATA = 'company_data',
  COMPANY_MODULES = 'company_modules',
  SAS_TOKEN = 'sas_token',
  CRM_DATA = 'crm_data'
}

export const userInfoQuery = () => ({
  queryKey: [CommonQueryKeys.USER_INFO],
  queryFn: () =>
    axiosHelper({
      endpoint: CommonApi.UserInfo,
      method: AxiosMethods.POST
    })
});

export const companyDataQuery = (
  token: string
): UseQueryOptions<GetCompanyDataQuery> => ({
  queryKey: [CommonQueryKeys.COMPANY_DATA],
  queryFn: () =>
    axiosHelper({
      endpoint: CommonApi.CompanyData,
      method: AxiosMethods.POST,
      body: { token }
    }),
  retryOnMount: false
});

export const getCompanyModulesQuery = (
  token: string
): UseQueryOptions<GetCompanyModulesQuery> => ({
  queryKey: [CommonQueryKeys.COMPANY_MODULES],
  queryFn: () =>
    axiosHelper({
      endpoint: CommonApi.CompanyModules,
      method: AxiosMethods.POST,
      body: { token }
    }),
  retryOnMount: false
});

export const sasTokenQuery = () => ({
  queryKey: [CommonQueryKeys.SAS_TOKEN],
  queryFn: () =>
    axiosHelper({
      endpoint: CommonApi.SASToken,
      method: AxiosMethods.POST
    }),
  retryOnMount: false,
  refetchInterval: 1000 * 60 * 60 * 23
});

export const algoliaTokenQuery = () => ({
  queryKey: [CommonQueryKeys.ALGOLIA_TOKEN],
  queryFn: () =>
    axiosHelper({
      endpoint: CommonApi.AlgoliaToken,
      method: AxiosMethods.GET
    }),
  retryOnMount: false
});

export interface GetCrmDataQueryParams {
  pageSize: number;
  offset: number;
  ownerId?: string;
  accountId?: string;
  type: CrmTypes;
  searchTerm?: string;
  state?: string[];
  city?: string[];
}

export enum CrmTypes {
  User = 'User',
  Lead = 'Lead',
  Account = 'Account',
  Contact = 'Contact',
  State = 'State',
  City = 'City'
}

export const getCrmDataQuery = (
  params: GetCrmDataQueryParams
): UseInfiniteQueryOptions => ({
  queryKey: [CommonQueryKeys.CRM_DATA, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: IntegrationApi.GetCrmData,
      method: AxiosMethods.POST,
      body: { ...params, offset: data.pageParam || params.offset }
    }),
  getNextPageParam: (
    lastPage: GetCrmDataQuery<
      AccountProps | LeadProps | ContactProps | StateProps | CityProps
    >,
    allPages: GetCrmDataQuery<
      AccountProps | LeadProps | ContactProps | StateProps | CityProps
    >[]
  ) => {
    const total = allPages.reduce(
      (acc, curr) => acc + curr.Data.records.length,
      0
    );

    return lastPage?.Data?.records?.length < params.pageSize
      ? undefined
      : total;
  },
  refetchOnWindowFocus: false,
  enabled:
    params.type === CrmTypes.City
      ? params.searchTerm?.length >= 2 || params.state?.length > 0
      : true
});
