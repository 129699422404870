import { useCallback, useMemo, useRef, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  Resource,
  AlgoliaHit,
  ResourceCategoryClass
} from '@setvi/shared/interfaces';
import { getResourceFromAlgoliaHit } from '@setvi/shared/utils';
import { RESOURCE_PAGINATION } from '@setvi/shared/enums';

// Generated by https://quicktype.io
export interface AlgoliaResponse {
  hits: AlgoliaHit[];
  nbHits: number;
  page: number;
  nbPages: number;
  hitsPerPage: number;
  exhaustiveNbHits: boolean;
  exhaustiveTypo: boolean;
  query: string;
  params: string;
  processingTimeMS: number;
}

export enum IndexType {
  QUICK = 'Quick',
  DEEP = 'Deep'
}

interface UseAlgoliaProps {
  sasToken: string;
  algoliaKey: string;
  hitsPerPage?: number;
  filters?: string;
}

const breadcrumbFilter = 'breadcrumbs.resourceCategoryClass';
export const resourceFilter = `${breadcrumbFilter}=${ResourceCategoryClass.RESOURCES}`;
export const myResourceFilter = `${breadcrumbFilter}=${ResourceCategoryClass.MY_RESOURCES}`;

export const useAlgolia = ({
  sasToken,
  algoliaKey,
  hitsPerPage = RESOURCE_PAGINATION.LIMIT,
  filters
}: UseAlgoliaProps) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResultInsideDocuments, setIsResultInsideDocuments] =
    useState<boolean>(false);
  const lastQuery = useRef(null);

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const client = useMemo(
    () => algoliasearch(process.env.ALGOLIA_APP_ID, algoliaKey),
    [algoliaKey]
  );
  const index = useMemo(
    () => client.initIndex(process.env.ALGOLIA_INDEX),
    [client]
  );
  const textIndex = useMemo(
    () => client.initIndex(process.env.ALGOLIA_TEXT_INDEX),
    [client]
  );

  const handleAlgoliaResponse = useCallback(
    (
      response: AlgoliaResponse,
      isInsideDocuments: boolean,
      goToNextPage = false
    ) => {
      // @ts-ignore for property mismatch on algolia
      const res: Partial<Resource[]> = response.hits.map(resource =>
        getResourceFromAlgoliaHit(resource, sasToken)
      );

      setResources(loadedResources => {
        const result = goToNextPage ? [...loadedResources, ...res] : res;
        return result;
      });
      setIsLoading(false);
      setIsResultInsideDocuments(isInsideDocuments);
      setPage(response.page);
      setTotalCount(response.nbHits);
      return res;
    },
    [sasToken]
  );

  const searchAlgolia = useCallback(
    (
      query: string,
      indexType: IndexType.QUICK | IndexType.DEEP,
      goToNextPage = false
    ): Promise<AlgoliaResponse> =>
      new Promise(resolve => {
        lastQuery.current = query;
        const idx = indexType === IndexType.QUICK ? index : textIndex;

        idx
          .search(query, {
            filters,
            hitsPerPage,
            distinct: 1,
            ...(goToNextPage ? { page: page + 1 } : {})
          })
          .then((response: AlgoliaResponse) => {
            if (lastQuery.current === query) resolve(response);
          });
      }),
    [index, textIndex, hitsPerPage, filters, page]
  );

  const searchAlgoliaResources = useCallback(
    (
      query: string,
      autoStartDeepSearch = false,
      isDeepSearch = false,
      goToNextPage = false
    ) => {
      setIsLoading(true);
      isDeepSearch
        ? searchAlgolia(query, IndexType.DEEP, goToNextPage).then(response => {
            handleAlgoliaResponse(response, true, goToNextPage);
          })
        : searchAlgolia(query, IndexType.QUICK, goToNextPage)
            .then(response => {
              if (response.hits.length === 0 && autoStartDeepSearch)
                return searchAlgolia(query, IndexType.DEEP).then(deepResponse =>
                  handleAlgoliaResponse(deepResponse, true, goToNextPage)
                );
              return handleAlgoliaResponse(response, false, goToNextPage);
            })
            .finally(() => setIsLoading(false));
    },
    [searchAlgolia, handleAlgoliaResponse]
  );

  const loadNextAlgoliaResult = useCallback(
    (query: string) => searchAlgoliaResources(query, false, false, true),
    [searchAlgoliaResources]
  );

  return {
    searchAlgoliaResources,
    isResultInsideDocuments,
    resources,
    isLoading,
    loadNextAlgoliaResult,
    totalCount
  };
};
