import { useState } from 'react';
import { Box, Menu, IconButton } from '@material-ui/core';
import { SNavigateButton, SButton } from '@setvi/shared/components';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from '@tanstack/react-query';
import { ResourceBookmark, ChildResource } from '@setvi/shared/interfaces';
import {
  RESOURCE_BOOKMARKS,
  getResourceBookmarksQuery,
  saveResourceBookmarks
} from '@setvi/shared/services';
import { useSubscribedMutation } from '@setvi/shared/hooks';
import { useSnackbar } from 'notistack';
import { List } from './list';
import { BookmarkForm } from './form';
import { useBookmarkStyles } from './style';

interface BookmarkProps {
  id: string;
  activePage: ChildResource;
  disabled?: boolean;
  setActivePage: (bookmarkedIndex: number) => void;
}

export const Bookmark = ({
  id,
  activePage,
  setActivePage,
  disabled
}: BookmarkProps) => {
  const [editMode, setEditMode] = useState(false);
  const [bookmark, setBookmark] = useState<ResourceBookmark>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useBookmarkStyles();
  const open = Boolean(anchorEl);

  const { mutateAsync: modifyBookmark, isLoading: isModifyLoading } =
    useSubscribedMutation(saveResourceBookmarks(), [
      RESOURCE_BOOKMARKS.GET_ALL
    ]);

  const { data: bookmarks, isLoading } = useQuery({
    ...getResourceBookmarksQuery({ resourceID: id }),
    enabled: open
  });

  const handleConfirm = async (name: string) => {
    const newBookmark = [
      ...bookmarks,
      {
        BookmarkTitle: name,
        ID: 0,
        PageNumber: activePage.SortOrder,
        resourceID: id
      }
    ];

    const response = await modifyBookmark({
      body: {
        resourceID: id,
        bookmarks: newBookmark
      }
    });

    if (!response.Errors) {
      setEditMode(false);
      enqueueSnackbar(`Bookmark successfully created`, {
        variant: 'success'
      });
    }
  };

  const handleUpdate = async (name: string) => {
    const updatedBookmarks = bookmarks.map((i: ResourceBookmark) => {
      if (i.ID !== bookmark.ID) return i;
      return { ...i, BookmarkTitle: name };
    });

    const response = await modifyBookmark({
      body: {
        resourceID: id,
        bookmarks: updatedBookmarks
      }
    });

    if (!response.Errors) {
      setEditMode(false);
      enqueueSnackbar(`Bookmark successfully updated`, {
        variant: 'success'
      });
    }
  };

  const handleRename = (item: ResourceBookmark) => {
    setBookmark(item);
    setEditMode(true);
  };

  const handleCreate = () => {
    setEditMode(true);
    setBookmark(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEditMode(false);
  };

  const header = (
    <Box className={classes.header}>
      {editMode ? (
        <Box className={classes.titleWithButton}>
          <SNavigateButton onClick={() => setEditMode(false)} />
          {bookmark ? 'Edit Bookmark' : 'Create Bookmark'}
        </Box>
      ) : (
        'Bookmarks'
      )}
      <IconButton
        style={{ minWidth: 34 }}
        aria-label="close"
        size="small"
        onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  return (
    <Box>
      <SButton
        variant="outlined"
        disabled={disabled}
        className={classes.bookmark}
        style={{
          border: `1px solid ${open ? '#1E96FC' : '#E6E7E9'}`
        }}
        onClick={handleClick}
        startIcon={
          <BookmarkBorderIcon
            fontSize="inherit"
            color={open ? 'primary' : 'disabled'}
            style={{ fontSize: '1.8rem' }}
          />
        }>
        Bookmark
      </SButton>
      {open && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          elevation={3}
          getContentAnchorEl={null}
          className={classes.menu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <Box className={classes.container}>
            {header}
            {editMode ? (
              <BookmarkForm
                defaultValue={bookmark?.BookmarkTitle || ''}
                edit={Boolean(bookmark)}
                handleConfirm={bookmark ? handleUpdate : handleConfirm}
                loading={isModifyLoading || isLoading}
              />
            ) : (
              <List
                bookmarks={bookmarks}
                handleRename={handleRename}
                handleCreate={handleCreate}
                setActivePage={setActivePage}
                loading={isLoading}
                disabled={isLoading || !activePage}
              />
            )}
          </Box>
        </Menu>
      )}
    </Box>
  );
};
