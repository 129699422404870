import { Box, IconButton, Divider, makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SText } from '@setvi/shared/components';
import { distances } from '@setvi/shared/styles';
import { memo } from 'react';

const useAccountStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    padding: distances.base,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    }
  },
  details: {
    flexGrow: 1
  },
  secondaryText: {
    fontSize: 13,
    fontWeight: 500,
    color: palette.primary.main
  },
  grayText: {
    fontSize: 13,
    fontWeight: 500,
    color: '#92929D'
  }
}));

interface AccountProps {
  account: any;
  onClick?: (account: any) => void;
}

export const Account = memo(({ account, onClick }: AccountProps) => {
  const styles = useAccountStyles();

  return (
    <Box onClick={() => onClick?.(account)}>
      <Box className={styles.container}>
        <Box className={styles.details}>
          <SText title={account.Name} />
          <SText className={styles.secondaryText} title={account.Phone} />
          <SText
            className={styles.secondaryText}
            title={account.Covetrus_Customer_Number__c}
          />
          <SText
            className={styles.grayText}
            title={`${account.ShippingState ? account.ShippingState : ''} ${
              account.ShippingState ? '|' : ''
            } ${account.ShippingCity}`}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton size="medium">
            <ChevronRightIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
});
