import { makeStyles, Theme } from '@material-ui/core';
import { distances } from '../../../../../../styles';

interface StyleProps {
  open?: boolean;
  width?: number;
}

export const useCustomSelectButtonStyles = makeStyles<Theme, StyleProps>({
  container: {
    width: ({ width }) => width || '230px',
    height: '38px',
    padding: 0,
    borderRadius: '4px',
    border: ({ open }) => `1px solid ${open ? '#1E96FC' : '#F1F1F5'}`
  },
  label: {
    fontSize: '14px',
    color: '#696974',
    padding: `0px ${distances.px.small}`,
    display: 'flex',
    flexShrink: 0,
    fontWeight: 400
  },
  labelDetails: {
    fontSize: '14px',
    fontWeight: 500
  },
  labelHighlight: {
    color: '#1E96FC'
  },
  iconContainer: {
    display: 'flex',
    borderLeft: '1px solid #F1F1F5',
    borderRadius: 0,
    paddingLeft: distances.px.small,
    paddingRight: distances.px.small,
    marginLeft: 'auto',

    '& svg': {
      transform: ({ open }) =>
        `rotate(${open ? '180deg' : '0deg'}) scale(0.5) !important `,
      fill: ({ open }: { open: boolean }) => `${open ? '#1E96FC' : '#696974'}`,
      transition: 'all 0.3s ease-in-out'
    }
  }
});
