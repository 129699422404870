import { useMemo, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  getSnippetsQuery,
  getCompanySnippetsQuery
} from '../../../../services';
import { Snippet } from '../../../../interfaces';
import { SNIPPETS_TAB } from './index';

export const useContent = () => {
  const [currentTab, setCurrentTab] = useState(SNIPPETS_TAB.mySnippets);
  const [previewSnippet, setPreviewSnippet] = useState<Snippet>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleOnBack = useCallback(() => {
    setPreviewSnippet(null);
  }, [setPreviewSnippet]);

  const {
    data: mySnippetsResponse,
    isLoading: loading,
    refetch: mySnippetsRefetch
  } = useQuery(getSnippetsQuery());

  const { data: companySnippetsResponse, isLoading: companySnippetsLoading } =
    useQuery(getCompanySnippetsQuery());

  const mySnippets = useMemo(
    () =>
      mySnippetsResponse?.map((snippet: any) => ({
        id: snippet.Id,
        title: snippet.Name,
        message: snippet.Text,
        lastModified: snippet?.LastChanged
      })) || [],
    [mySnippetsResponse]
  );

  const companySnippets = useMemo(
    () =>
      companySnippetsResponse?.map((snippet: any) => ({
        id: snippet.Id,
        title: snippet.Name,
        message: snippet.Text,
        lastModified: snippet?.ChangeDate
      })) || [],
    [companySnippetsResponse]
  );

  const showSearch = useMemo(
    () =>
      currentTab === SNIPPETS_TAB.mySnippets
        ? !!mySnippets?.length
        : !!companySnippets?.length,
    [companySnippets?.length, currentTab, mySnippets?.length]
  );

  return {
    showSearch,
    currentTab,
    setCurrentTab,
    previewSnippet,
    setPreviewSnippet,
    mySnippets: mySnippets.filter((snippet: Snippet) =>
      snippet.title.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    companySnippets: companySnippets.filter((snippet: Snippet) =>
      snippet.title.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    mySnippetsRefetch,
    searchTerm,
    setSearchTerm,
    loading: loading || companySnippetsLoading,
    handleOnBack
  };
};
