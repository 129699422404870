import { Box, IconButton } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { SText } from '@setvi/shared/components';

export const Container = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    padding: spacing(3, 2),
    alignItems: 'center',
    backgroundColor: '#FAFAFB'
  }
}))(Box);

export const BackIconButton = withStyles(({ palette }) => ({
  root: {
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    '&:hover': {
      color: palette.text.primary
    }
  }
}))(IconButton);

export const Title = withStyles(({ spacing }) => ({
  root: {
    fontWeight: 600,
    marginLeft: spacing(2)
  }
}))(SText);

export const useStyles = makeStyles(theme => ({
  backButton: {
    padding: theme.spacing(1)
  }
}));
