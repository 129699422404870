import { FC, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { SDropdownMenu as DropdownMenu } from '@setvi/shared/components';
import { TrashIcon, PenIcon } from '../../sicons';

interface MenuProps {
  onPreview: () => void;
  onEdit?: () => void;
  onDelete: () => void;
}

const IconContainer = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
})(Box);

export const Menu: FC<MenuProps> = ({ onPreview, onEdit, onDelete }) => {
  const { palette } = useTheme();

  const menuItems = useMemo(
    () => [
      {
        icon: (
          <IconContainer>
            <OpenInNewIcon htmlColor={palette.text.primary} />
          </IconContainer>
        ),
        text: 'Preview',
        onClick: onPreview
      },
      ...(onEdit
        ? [
            {
              icon: (
                <IconContainer color={palette.text.primary}>
                  <PenIcon fontSize="small" />
                </IconContainer>
              ),
              text: 'Edit',
              onClick: onEdit
            }
          ]
        : []),
      {
        icon: (
          <IconContainer>
            <TrashIcon fontSize="small" />
          </IconContainer>
        ),
        text: 'Delete',
        onClick: onDelete,
        color: 'danger'
      }
    ],
    [palette.text.primary, onPreview, onEdit, onDelete]
  );

  return <DropdownMenu menuItems={menuItems} />;
};
