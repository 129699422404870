import { useState, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Category, Link, Resource } from '@setvi/shared/interfaces';
import { DrawerContent, RESOURCE_TYPE } from '@setvi/shared/enums';
import { getLinkItemFromResource } from '@setvi/shared/utils';
import { useSelected } from '../../../../hooks/selected';
import { useDialog } from '../../../../providers';
import { SelectPages } from '../../resources/content/select-pages';

interface UseResourcesContentProps {
  type?: RESOURCE_TYPE;
  handleInsert(link: Link): void;
  preselected?: Resource[];
  hideEditLink?: boolean;
  handleClose(): void;
  setShowContent?(content: string | null): void;
}

export const useResourcesContent = ({
  type = RESOURCE_TYPE.ALL_RESOURCES,
  handleInsert,
  preselected = [],
  hideEditLink,
  handleClose,
  setShowContent
}: UseResourcesContentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [linkName, setLinkName] = useState('');

  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [showResources, setShowResources] = useState(false);
  const [showCategories, setShowCategories] = useState(true);
  const [isCategoriesEmpty, setIsCategoriesEmpty] = useState(false);
  const [isResourcesEmpty, setIsResourcesEmpty] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  // const { sendCategoryAnalytics } = useAnalytics();
  const { openDialog, closeDialog } = useDialog();
  const { selected, handleSelect } = useSelected<Resource>({
    dataKeyGetter: (resource: Resource) => resource.ID,
    preselected
  });

  const handleSelectPages = (resource: Resource) => {
    openDialog(
      <SelectPages
        resource={resource}
        onClose={closeDialog}
        onConfirm={sp => {
          const selectedPagesFormatted: Resource[] = sp.map(page => ({
            ...page,
            ID: page.ID,
            ResourceID: page.ItemID,
            Name: `${resource.Name} (${page.SortOrder})`,
            ResourceTypeID: resource.ResourceTypeID,
            Key: 'child'
          }));
          handleSelect(true, selectedPagesFormatted);
          closeDialog();
        }}
      />
    );
  };

  const openCategory = (categoryID: number) => {
    setCategoryId(categoryID);
    setShowResources(false);
    setShowCategories(true);

    setIsCategoriesEmpty(false);
    setIsResourcesEmpty(false);
    // TODO: Implemente category analtyics when graphql is moved to shared folder
    // sendCategoryAnalytics({ CategoryID: categoryID });
  };

  const onCategoryClick = (rowData: Category) => {
    openCategory(rowData.ID);
  };

  const onEmptyCategories = () => {
    setShowCategories(false);
    setShowResources(true);
    setIsCategoriesEmpty(true);
  };

  const onLastCategoryLoaded = useCallback(() => {
    if (!isResourcesEmpty) {
      setShowResources(true);
    }
  }, [isResourcesEmpty]);

  const onEmptyResources = useCallback(() => {
    setIsResourcesEmpty(true);
    if (!isCategoriesEmpty) {
      setShowResources(false);
    }
  }, [isCategoriesEmpty]);

  const hasSelection = useMemo(() => selected.length > 0, [selected]);

  const hasBreadcrumbs = useMemo(
    () => searchTerm.length < 1 && type !== RESOURCE_TYPE.FAVORITE_RESOURCES,
    [searchTerm, type]
  );

  const subHeaderText = 'Add from Required Reading';

  const clearSelected = useCallback(() => {
    setIsExpanded(false);
    setLinkName('');

    handleSelect(false, selected);
    setShowContent?.(DrawerContent.resources);
  }, [handleSelect, selected, setShowContent]);

  const handleInsertLink = useCallback(
    (name: string) => {
      handleInsert({
        Name: name,
        Placeholder: uuidv4(),
        Item: {
          Items: selected.map(item => getLinkItemFromResource(item))
        }
      });
      handleClose?.();
    },
    [handleInsert, selected, handleClose]
  );

  const submitText = useMemo(() => {
    if (preselected.length) {
      return 'Confirm';
    }
    if (hideEditLink) {
      return 'Add';
    }
    return 'Add link';
  }, [hideEditLink, preselected]);

  return {
    isExpanded,
    setIsExpanded,
    selectedResources: selected,
    linkName,
    setLinkName,
    onSelect: handleSelect,
    handleSelectPages,
    searchTerm,
    setSearchTerm,
    categoryId,
    showResources,
    setShowResources,
    openCategory,
    onCategoryClick,
    hasSelection,
    hasBreadcrumbs,
    clearSelected,
    handleInsertLink,
    showCategories,
    onEmptyCategories,
    onEmptyResources,
    subHeaderText,
    onLastCategoryLoaded,
    submitText
  };
};
