import { Field, FieldProps } from 'formik';
import { Typography, Box, InputBase } from '@material-ui/core';

import { useStyles, InputContainer, ErrorContainer } from './Style';

interface SubjectFieldProps {
  name: string;
  label: string;
  boldText?: boolean;
  placeholder?: string;

  onChange?(val: string): void;
}

const SubjectField = ({
  name,
  label,
  boldText,
  placeholder,

  onChange
}: SubjectFieldProps) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <>
          <InputContainer>
            <Box className={classes.textLabelContainer}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.textLabel}>
                {label}
              </Typography>
            </Box>
            <Box width="100%">
              <InputBase
                name={field.name}
                type="text"
                placeholder={placeholder}
                value={meta.value}
                inputProps={{ 'aria-label': 'naked' }}
                className={`${classes.input} ${boldText && classes.boldText}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldValue(name, event.target.value);
                  onChange?.(event.target.value);
                }}
              />
            </Box>
          </InputContainer>
          {meta.touched && meta.error && (
            <ErrorContainer>
              <Typography className={classes.error}>
                {Array.isArray(meta.error) ? meta.error[0] : meta.error}
              </Typography>
            </ErrorContainer>
          )}
        </>
      )}
    </Field>
  );
};

export default SubjectField;
