import { AxiosMethods, PresentationsApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';

export enum PresentationQueryKey {
  PRESENTATIONS = 'presentations'
}

export const getPresentationsQuery = (input?: any) => ({
  queryKey: [PresentationQueryKey.PRESENTATIONS, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: PresentationsApi.Presentations,
      method: AxiosMethods.GET,
      params: data.queryKey[1]
    }),
  select: (res: any) => {
    const data = res?.Result?.map((presentation: any) => ({
      ...presentation,
      ThumbURL: presentation.ThumbURLWithSas,
      // @ts-ignore
      PresentationItems:
        presentation?.PresentationItems?.map((item: any) => ({
          ...item,
          ThumbURL: item.ThumbURLWithSas
        })) || []
    }))?.sort((a: any, b: any) => {
      const c = new Date(a.LastChanged);
      const d = new Date(b.LastChanged);
      // @ts-ignore
      return d - c;
    });

    return {
      total: res?.Total || 0,
      items: data || []
    };
  }
});
