import * as yup from 'yup';
import { ResourceFormType, ResourceValuesMax } from '../../../enum';

export const SingleDetailsValidations = {
  Name: yup
    .string()
    .trim()
    .required('Resource Name is required.')
    .max(ResourceValuesMax.Name),
  Status: yup.number().required('Status is required.'),
  Thumbnail: yup.string()
};

export const CategoriesValidations = {
  Categories: yup.array(yup.object()).min(1)
};

export const ZipDetailsValidations = {
  UploadType: yup.number().notOneOf([0]).required('Upload type is required.'),
  CategoryOption: yup.boolean()
};

export const BulkDetailsValidations = {
  Names: yup
    .array()
    .of(
      yup
        .string()
        .trim()
        .required('Resource Name is required.')
        .max(ResourceValuesMax.Name)
    )
    .required('Resource Names are required.')
};

export const PermissionsValidations = {
  ValidFromDate: yup.date().nullable(),
  ExpireOnDate: yup.date().nullable(),
  IsShareable: yup.boolean(),
  IsDownloadable: yup.boolean(),
  IsRequiredDownload: yup.boolean(),
  IsInternallyDownloadable: yup.boolean()
};

export const CommentAndTagsValidations = {
  Tags: yup.array().of(yup.object()),
  Comment: yup.string().trim().max(ResourceValuesMax.Comment)
};

export const NotificationsValiadtions = {
  PushNotification: yup.boolean(),
  EmailNotification: yup.boolean(),
  ExcludeNotification: yup.boolean()
};

export const AddResourceSchema = (type: ResourceFormType) => ({
  ...(type === ResourceFormType.SINGLE
    ? {
        ...SingleDetailsValidations,
        ...CategoriesValidations
      }
    : {}),
  ...(type === ResourceFormType.ZIP ? ZipDetailsValidations : {}),
  ...(type === ResourceFormType.BULK ? BulkDetailsValidations : {}),
  ...PermissionsValidations,
  ...CommentAndTagsValidations,
  ...(type !== ResourceFormType.SINGLE ? NotificationsValiadtions : {})
});
