import { Box, Paper, Popper } from '@material-ui/core';
import { Contact as ContactInterface } from 'Services/Query/Contacts/Types';
import { useSugestedContactsStyles } from './style';
import { Contact } from './Contact';

interface SuggestedContactsProps {
  anchorEl: HTMLElement | null;
  suggestedContacts: ContactInterface[];
  onContactClick: (contact: ContactInterface) => void;
}

export const SuggestedContacts = ({
  anchorEl,
  suggestedContacts,
  onContactClick
}: SuggestedContactsProps) => {
  const style = useSugestedContactsStyles();

  return (
    <Popper
      disablePortal
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="bottom-start"
      className={style.popper}>
      {suggestedContacts?.length > 0 && (
        <Paper elevation={5}>
          {suggestedContacts.map(contact => (
            <Box key={contact.Email} onClick={() => onContactClick(contact)}>
              <Contact
                firstName={contact.FirstName}
                lastName={contact.LastName}
                email={contact.Email}
              />
            </Box>
          ))}
        </Paper>
      )}
    </Popper>
  );
};
