export enum CategoryApi {
  GetCategory = '/api/v1.0/categories',
  CreateUserCategory = '/WebAPI/CreateUserCategory',
  UpdateUserCategory = '/WebAPI/UpdateUserCategory',
  DeleteUserCategory = '/WebAPI/DeleteUserResourceCategory',
  GetUserCategories = '/api/v1.0/categories/user',
  GetBreadcrumbs = '/api/v1.0/categories/breadcrumbs',
  GetCompanyCategories = '/api/v1.0/categories/company'
}

export enum AdminCategoryApi {
  GetCategories = '/api/v2/ResourceCategory/:id/categorytree',
  GetCategoryDetails = '/api/v2/ResourceCategory/:id/details',
  GetGroupCategories = '/api/v2/ResourceCategory/1/group/:id/categorytree',
  UpdateCategoryParent = '/api/v2/ResourceCategory/:categoryId/changeparent/:newParentCategoryId',
  DuplicateCategory = '/api/v2/ResourceCategory/1/copy/:id',
  CreateCategory = 'api/v2/ResourceCategory',
  UpdateCategory = 'api/v2/ResourceCategory',
  DeleteCategory = '/api/v2/ResourceCategory/:id'
}
