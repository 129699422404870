import {
  Box,
  Divider,
  Typography,
  TextField,
  Button,
  makeStyles
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export interface FormProps {
  handleSubmit: (value: string) => void;
  handleClose: () => void;
  submitText: string;
  initialValue: string;
  handleOnBlur?: (value: string) => void;
  disableDivider?: boolean;
  hideEditLink?: boolean;
}

const useStyles = makeStyles(({ spacing }) => ({
  confirmBtn: {
    marginBottom: spacing(1)
  }
}));

const SubmitButton = withStyles({
  root: {
    height: 40
  }
})(Button);

// FIXME added because form height is expand under window when user wants to update link name
// works well when  user want to add new link name
const LinkTextField = withStyles(({ spacing }) => ({
  root: {
    marginBottom: spacing(3),
    '& p': {
      position: 'absolute',
      top: 30
    },
    '& .MuiInput-root': {
      fontWeight: 600
    }
  }
}))(TextField);

export const Form = ({
  handleSubmit,
  handleClose,
  submitText,
  initialValue,
  handleOnBlur,
  hideEditLink,
  disableDivider = false
}: FormProps) => {
  const classes = useStyles();
  const linkNameForm = useFormik({
    initialValues: {
      linkName: initialValue
    },
    validationSchema: Yup.object({
      linkName: Yup.string()
        .max(100, 'Limit is 100 characters')
        .required('Link name is required')
    }),
    onSubmit: values => {
      handleSubmit(values.linkName.trim());
      handleClose?.();
    },
    enableReinitialize: true
  });

  return (
    <Box p={2}>
      {!disableDivider && <Divider />}
      {!hideEditLink ? (
        <form onSubmit={linkNameForm.handleSubmit}>
          <Box width="100%" my={2}>
            <Box>
              <Typography variant="body2">Link Name</Typography>
            </Box>
            <Box pt={1}>
              <LinkTextField
                name="linkName"
                autoComplete="off"
                onChange={linkNameForm.handleChange}
                value={linkNameForm.values.linkName}
                fullWidth
                error={
                  linkNameForm.touched.linkName &&
                  Boolean(linkNameForm.errors.linkName)
                }
                helperText={
                  linkNameForm.touched.linkName && linkNameForm.errors.linkName
                }
                onBlur={e => handleOnBlur?.(e.target.value)}
              />
            </Box>
          </Box>
          <Box>
            <SubmitButton
              className={classes.confirmBtn}
              color="primary"
              variant="contained"
              type="submit"
              fullWidth>
              {submitText}
            </SubmitButton>
            <Button type="button" onClick={handleClose} fullWidth>
              Cancel
            </Button>
          </Box>
        </form>
      ) : (
        <form
          onSubmit={() => {
            handleSubmit('');
            handleClose();
          }}>
          <Box>
            <SubmitButton
              onClick={() => handleSubmit('')}
              className={classes.confirmBtn}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth>
              {submitText}
            </SubmitButton>
            <Button type="button" onClick={handleClose} fullWidth>
              Cancel
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};
