import { useCallback } from 'react';
import { Box, RadioGroup } from '@material-ui/core';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { EmptySearchResults } from '@setvi/shared/components/sdrawer/resources/list/empty-states';
import { Skeleton as ListSkeleton } from '@setvi/shared/components/sdrawer/resources/list/skeleton';
import { Presentation, Link } from '@setvi/shared/interfaces';
import { usePresentations } from './hooks/usePresentations';
import { EmptyStatePresentation } from './empty-states';
import SearchLoader from '../../search-loader';
import { Detail } from '../components';
import { Row } from './Row';
import { StyledList, useListStyles, useStyles } from '../style';

interface ListProps {
  presentations: Presentation[];
  presentationId?: number;
  isLoading: boolean;
  handleInsert(link: Link, currentLink?: Link): void;
  isSearching: boolean;
}

export const List = ({
  presentations = [],
  presentationId,
  isLoading,
  handleInsert,
  isSearching
}: ListProps) => {
  const listClasses = useListStyles();
  const classes = useStyles();

  const {
    value,
    setValue,
    handleChange,
    isExpanded,
    setIsExpanded,
    selected,
    setSelected
  } = usePresentations({ presentationId, presentations });

  const handleOnClose = useCallback(() => {
    setIsExpanded(false);
    setSelected(null);
    setValue(null);
  }, [setIsExpanded, setSelected, setValue]);

  if (isLoading)
    return isSearching ? (
      <Box height="100%">
        <SearchLoader itemType="presentations" />
      </Box>
    ) : (
      <ListSkeleton />
    );

  if (presentations.length === 0)
    return (
      <Box height="100%">
        {isSearching ? <EmptySearchResults /> : <EmptyStatePresentation />}
      </Box>
    );
  return (
    <>
      <RadioGroup
        aria-label="presentations"
        name="selectedPresentation"
        value={value}
        onChange={handleChange}
        className={classes.radioGroup}>
        <StyledList>
          <AutoSizer>
            {({ height, width }: any) => (
              <FixedSizeList
                height={height}
                itemCount={presentations.length}
                itemSize={82}
                itemData={{
                  // @ts-ignore
                  presentations,
                  classes: listClasses,
                  setSelected,
                  setIsExpanded
                }}
                overscanCount={5}
                width={width}>
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        </StyledList>
      </RadioGroup>
      {isExpanded && selected && (
        <Detail
          isExpanded={isExpanded}
          handleClose={handleOnClose}
          selected={selected}
          handleInsert={handleInsert}
        />
      )}
    </>
  );
};
