import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 60px;
  height: 100%;
  overflow-y: auto;
`;

export const ButtonContainer = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))(Box);
