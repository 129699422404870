export enum ResourceType {
  Unknown = 0,
  Product = 1,
  TextDocument = 2,
  PdfDocument = 3,
  WordDocument = 4,
  Video = 5,
  Audio = 6,
  Images = 7,
  WebPage = 8,
  YoutubeVideos = 9,
  VimeoVideos = 10,
  Spreadsheet = 11,
  Presentation = 12,
  HTML5 = 13,
  PhotoAlbum = 14,
  KeyNote = 15,
  Slides = 16,
  PptVar = 17,
  JSON = 19,
  CSV = 20,
  App = 21,
  Powerpoint = 22,
  Bulk = 100,
  Zip = 100
}

export enum ResourceIcon {
  Unknown = '/icons/App.svg',
  Product = '/icons/App.svg',
  TextDocument = '/icons/Word.svg',
  PdfDocument = '/icons/Pdf.svg',
  WordDocument = '/icons/Word.svg',
  Video = '/icons/Video.svg',
  Audio = '/icons/Video.svg',
  Images = '/icons/Image.svg',
  WebPage = '/icons/Web.svg',
  YoutubeVideos = '/icons/Youtube.svg',
  VimeoVideos = '/icons/Vimeo.svg',
  Spreadsheet = '/icons/Excel.svg',
  Presentation = '/icons/Powerpoint.svg',
  HTML5 = '/icons/App.svg',
  PhotoAlbum = '/icons/Image Gallery.svg',
  KeyNote = '/icons/Image Gallery.svg',
  Slides = '/icons/Powerpoint.svg',
  PptVar = '/icons/Powerpoint.svg',
  PdfSinglePage = '/icons/Pdf.svg',
  JSON = '/icons/Word.svg',
  CSV = '/icons/Excel.svg',
  App = '/icons/App.svg',
  Powerpoint = '/icons/Powerpoint.svg',
  Bulk = '/icons/Category.svg',
  Zip = '/icons/Category.svg'
}

export enum ResourceTypeMime {
  Unknown = 'application/octet-stream',
  Product = 'product',
  TextDocument = 'text',
  PdfDocument = 'application/pdf',
  WordDocument = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Video = 'video',
  Audio = 'audio',
  Images = 'image',
  WebPage = 'text/html',
  YoutubeVideos = 'youtube',
  VimeoVideos = 'vimeo',
  Spreadsheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Presentation = 'application/vnd.ms-powerpoint',
  HTML5 = 'text/html',
  PhotoAlbum = 'application/vnd.ezpix-album',
  KeyNote = 'application/vnd.apple.keynote',
  Slides = 'application/vnd.google-apps.presentation',
  PptVar = 'application/vnd.ms-powerpoint',
  PdfSinglePage = 'application/pdf',
  JSON = 'application/json',
  CSV = 'application/vnd.ms-excel',
  App = 'application/x-msdownload',
  Powerpoint = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Zip = 'application/zip'
}

export enum ResourceName {
  Product = 'Product',
  KeyNote = 'Keynote',
  PdfDocument = 'PDF',
  WordDocument = 'Word',
  Video = 'Video',
  Audio = 'MP3',
  Images = 'Image',
  WebPage = 'Link',
  HTML5 = 'HTML',
  YoutubeVideos = 'Youtube',
  VimeoVideos = 'Vimeo',
  Spreadsheet = 'XLS',
  Presentation = 'PPT',
  Powerpoint = 'PPTX',
  Slides = 'PPT',
  PhotoAlbum = 'Photo'
}

export enum RESOURCE_TYPE {
  NONE = 'none',
  ALL_RESOURCES = 'all_resources',
  USER_RESOURCES = 'user_resources',
  FAVORITE_RESOURCES = 'favorite_resources',
  REQURIED_READING = 'required_reading'
}

export enum RESOURCE_CONTENT {
  webLink = 'webLink',
  snippet = 'snippet',
  favorites = 'favorites',
  resources = 'resources',
  uploadFile = 'uploadFile',
  myResources = 'myResources',
  reviewLinks = 'reviewLinks',
  presentation = 'presentation'
}

export enum CATEGORY_TYPE {
  COMPANY,
  USER
}

export enum RESOURCE_PAGINATION {
  LIMIT = 20
}

export enum RESOURCE_UPLOAD_STATUS {
  ERROR = -1,
  UPLOADING = 0,
  UNPROCESSED = 1,
  PROCESSING = 2,
  PROCESSED = 3,
  BULK_UNPACKED = 4,
  BULK_PROCESSED = 5,
  REPLACE_UPLOADING = 6,
  REPLACE_UNPROCESSED = 7,
  REPLACING = 8,
  FILESTACK_UPLOADING = 9
}
