import { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '@setvi/shared/components/sdrawer/base';
import { Form } from '@setvi/shared/components/sdrawer/review-links/Form';
import { Header } from '../selected/Header';

interface SelectedActionButtonProps {
  setIsExpanded: (value: boolean) => void;
  length: number;
  onAddLinkName?: (name: string) => void;
  linkName: string;
  hideEditLink?: boolean;
  setLinkName?: (name: string) => void;
  onCancel?: () => void;
  submitText?: string;
}

const SelectedActionWrapper = withStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.common.white,
    zIndex: 10,
    width: DRAWER_WIDTH
  }
}))(Box);

export const SelectedActionButton = ({
  setIsExpanded,
  length,
  onAddLinkName,
  linkName,
  setLinkName,
  onCancel,
  hideEditLink,
  submitText = 'Add link'
}: SelectedActionButtonProps) => {
  const handleOnBlur = useCallback(
    (value: string) => {
      setLinkName(value);
    },
    [setLinkName]
  );

  return (
    <SelectedActionWrapper>
      <Header setIsExpanded={setIsExpanded} length={length} />
      <Form
        handleSubmit={onAddLinkName}
        handleClose={onCancel}
        submitText={submitText}
        initialValue={linkName}
        handleOnBlur={handleOnBlur}
        disableDivider
        hideEditLink={hideEditLink}
      />
    </SelectedActionWrapper>
  );
};
