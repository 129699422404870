import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { RESOURCE_PAGINATION, RESOURCE_TYPE } from '../../../enums';
import {
  getCompanyResourcesQuery,
  getFavoritesResourcesQuery,
  getUserResourcesQuery
} from '../../../services';

interface UseResourceListProps {
  onEmpty?: () => void;
  categoryId?: number;
  resourceType: RESOURCE_TYPE;
  search: string;
  useAlgoliaSearch: boolean;
}

export const useResourceList = ({
  onEmpty,
  categoryId,
  resourceType,
  search = '',
  useAlgoliaSearch
}: UseResourceListProps) => {
  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: RESOURCE_PAGINATION.LIMIT,
      search
    }),
    [search]
  );

  const queryInput = useMemo(() => {
    if (resourceType === RESOURCE_TYPE.FAVORITE_RESOURCES) {
      return getFavoritesResourcesQuery(queryVariables);
    }
    if (resourceType === RESOURCE_TYPE.USER_RESOURCES) {
      return getUserResourcesQuery(categoryId, queryVariables);
    }

    return getCompanyResourcesQuery(categoryId, queryVariables);
  }, [categoryId, queryVariables, resourceType]);

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      ...queryInput,
      enabled: !useAlgoliaSearch
    });

  const loading = isLoading || isFetching;

  const fetchMore = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  const resources = useMemo(
    () => data?.pages.map(page => page.Data.Result).flat() || [],
    [data?.pages]
  );

  useEffect(() => {
    if (!resources.length && !loading) {
      onEmpty();
    }
  }, [resources, hasNextPage, loading, onEmpty]);

  return {
    resources,
    fetchMore,
    isLoading,
    isFetching
  };
};
