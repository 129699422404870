import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  withStyles,
  OutlinedInputProps
} from '@material-ui/core';
import { debounce } from 'lodash';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

import { SearchIcon as Search } from '../../sicons/regular-icons';
import { SearchField, useStyles } from './style';
import { TextColor } from '../../../enums';

enum IconPosition {
  start = 'start',
  end = 'end'
}

const Loader = withStyles(({ spacing }) => ({
  root: {
    color: TextColor.Grey,
    marginRight: spacing(1)
  }
}))(CircularProgress);

export interface SSearchInputProps
  extends Omit<OutlinedInputProps, 'onChange'> {
  placeholder?: string;
  loading?: boolean;
  width?: number | string;
  searchedValue?: string;
  defaultValue?: string;
  className?: string;
  style?: CSSProperties;
  onChange(val: string): void;
  iconPosition?: keyof typeof IconPosition;
}

export const SSearchInput = ({
  placeholder = 'Search...',
  width = 320,
  loading = false,
  searchedValue,
  defaultValue,
  className,
  style,
  iconPosition = IconPosition.end,
  onChange,
  ...rest
}: SSearchInputProps) => {
  const [value, setValue] = useState(defaultValue || '');

  const classes = useStyles({ width });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((val: string) => onChange(val), 500),
    []
  );

  const handleInputClear = useCallback(() => {
    setValue('');
    onChange('');
    handleChange.cancel();
  }, [handleChange, onChange]);

  const endIcon = useMemo(() => {
    if (loading) return <Loader size={18} />;
    if (iconPosition === IconPosition.end && !value)
      return <Search fontSize="small" />;
    if (value)
      return (
        <IconButton size="small" onClick={handleInputClear}>
          <Close fontSize="small" />
        </IconButton>
      );
    return null;
  }, [handleInputClear, iconPosition, loading, value]);

  useEffect(
    () => {
      if (searchedValue !== undefined && value !== searchedValue) {
        // ! This line gives bug when use searchedValue and onChange in the same time
        // handleChange(searchedValue);
        setValue(searchedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedValue]
  );

  return (
    <SearchField
      placeholder={placeholder}
      className={clsx(classes.wrapper, className)}
      style={style}
      onChange={e => {
        handleChange(e.target.value);
        setValue(e.target.value);
      }}
      value={value}
      fullWidth
      startAdornment={
        iconPosition === IconPosition.start && (
          <InputAdornment position="start">
            <Search fontSize="small" />
          </InputAdornment>
        )
      }
      endAdornment={<InputAdornment position="end">{endIcon}</InputAdornment>}
      {...rest}
    />
  );
};
