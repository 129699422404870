import { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Box, Radio as MuiRadio } from '@material-ui/core';
import { Presentation, Template } from '@setvi/shared/interfaces';

interface RadioProps {
  presentation: Presentation | Template;
  onChange: () => void;
}

const CustomRadio = withStyles(({ palette }) => ({
  root: {
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: palette.success.light
    }
  }
}))(MuiRadio);

export const Radio = ({ presentation, onChange }: RadioProps) => {
  const { disabled, message } = useMemo(() => {
    const items =
      (presentation as Presentation).PresentationItems ||
      (presentation as Template).Items;
    if (items.length < 1) {
      return {
        disabled: true,
        message: 'This presentation has no resources and cannot be shared.'
      };
    }
    if (
      !items
        .map(item => ({ IsShareable: item.IsShareable }))
        .every(item => item.IsShareable)
    ) {
      return {
        disabled: true,
        message:
          'This presentation has at least one resource that is not shareable.'
      };
    }
    return {
      disabled: false,
      message: ''
    };
  }, [presentation]);

  if (disabled)
    return (
      <Tooltip title={message} arrow placement="left">
        <Box>
          <CustomRadio
            name="presentation"
            value={presentation.ID.toString()}
            disabled
          />
        </Box>
      </Tooltip>
    );
  return (
    <CustomRadio
      name="presentation"
      value={presentation.ID.toString()}
      onChange={onChange}
      color="primary"
    />
  );
};
