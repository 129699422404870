import { AxiosMethods, UsersApi } from '../../../enums';
import { axiosHelper } from '../../axios-instance';

export enum UserMutationsKey {
  USER_ACTIVITY = 'user_activity'
}

export const userActivityMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: UsersApi.UserActivity,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export * from './resources';
