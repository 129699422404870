import { FC, useCallback } from 'react';

import { Form } from '../../../review-links/Form';

interface PopupActionButtonProps {
  hideEditLink?: boolean;
  buttonText?: string;
  linkName: string;
  onAddLinkName?: (name: string) => void;
  setLinkName?: (name: string) => void;
  onCancel?: () => void;
  submitText?: string;
}

export const PopupActionButton: FC<PopupActionButtonProps> = ({
  onAddLinkName,
  buttonText: _buttonText,
  hideEditLink,
  linkName,
  setLinkName,
  onCancel,
  submitText
}) => {
  const handleOnBlur = useCallback(
    (value: string) => {
      setLinkName(value);
    },
    [setLinkName]
  );

  return (
    <Form
      submitText={submitText}
      initialValue={linkName}
      hideEditLink={hideEditLink}
      handleSubmit={onAddLinkName}
      handleOnBlur={handleOnBlur}
      handleClose={onCancel}
    />
  );
};
